const buyFrames = {
  priceEvolution: '価格動向',
  buyAtPrice: '現在の価格',
  makeOffer: '出品する',
  browseOffers: '購入可能なフレームを探す',
  numberFrames: 'フレーム数',
  chooseArea: 'フレームエリアの選択',
  chooseAreaMsg: '10フレーム以上購入すれば利用できます。',
  frames: 'フレーム',
  frameId: 'フレームＩｄ',
  frameValue: 'フレーム価格',
  frameTax: 'フレーム税額',
  total: '合計',
  totalTaxInc: '合計 (税込)',
  notAvailable: 'Not yet available',
  subtotal: '小計',
  tax: '消費税 (10%)',
  taxTiny: '税額',
  taxInc: '税込',
};

module.exports = buyFrames;

const summary = {
  invitations: 'Invitations',
  invitationsLeft: 'invitations left',
  invitationMessage: 'Send an invitation via email',
  invitationSent: 'Your invitation was sent successfully',
  invitationError: 'An error occurred while sending your invitation',
  myAccount: 'My Account',
  verify: 'Verificar',
  usernameTaken: "Username is not available",
  identityVerificationNotice: '<p>The NOA registration process is now finished! You can now buy frames</p><p>The following steps are not mandatory, however you will need to verify your account to access the "Frame Market" (available soon)</p>',
  certificate: 'Certificate',
  verified: 'Verified',
  notVerified: 'Not Verified',
  notVerifiedNotice: 'You must finish the identity verification process to access all our services.',
  verificationPendingNotice: 'Your identity is being verified. You can now start buying, but to have access to our full services the verification must finish.',
  identityVerification: 'Identity Verification',
  identity: 'Identity',
  personal: 'Personal',
  address: 'Address',
  verification: 'Verification',
  subscribeMailingList: 'Notify me of new work releases, NOA events and news',
  eventsNotAvailable: 'Events not yet available',
  profile: 'Profile',
  collection: 'Collection',
  transactions: 'Transactions',
  editInfo: 'Edit info',
  myInfo: 'My info',
  firstName: 'First Name',
  lastName: 'Last Name',
  nameKana: 'First Name (furigana)',
  surnameKana: 'Last Name (furigana)',
  phoneNumber: 'Phone',
  birthdate: 'Birthdate',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  city: 'City',
  region: 'State/Province/Region',
  postcode: 'Postcode',
  country: 'Country',
  sameAddressBilling: 'Use same address for billing',
  proofOfIdentity: 'Proof of Identity',
  profilePhoto: 'Profile Photo',
  myPM: 'My Payment Methods',
  addPM: 'Add Payment Method',
  addCard: 'Add Card',
  noPM: "You don't have any payment methods yet.",
  choosePM: 'Choose a payment method',
  myShippingAddress: 'My Shipping Address',
  noAddresses: "You don't have any address yet",
  addNew: 'Add New',
  cancel: 'Cancel ',
  submit: 'Submit',
  myCollection: 'My Collection',
  marketPrice: 'Market Price',
  totalWork: 'Total Work',
  change: 'Change',
  ownership: 'Ownership',
  balance: 'Balance',
  evolutionPrice: 'Price Trend',
  download: 'Download',
  seeFrames: 'See frames',
  sell: 'Sell',
  paid: 'Paid',
  noSellNotice1: 'The sale will be activated once',
  noSellNotice2: 'the entire work has been sold',
  notFound404: 'The requested URL was not found on this server',
  anError: "404. That's an error",
  ownedFrames:'Owned frames',
  ownedPct:'Owned percentage',
  workFrames:'Work frames',
};

module.exports = summary;

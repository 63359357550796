import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import store from "./store";
import { router } from "@/plugins/vue-router";
import { fbapp, auth, rtdb, analytics, rtdbC } from "@/helpers/firebase";
import Fragment from "vue-fragment";
import StaticTemplate from "@/components/Templates/StaticTemplate";
import VueTippy, { TippyComponent } from "vue-tippy";
import VueSocialSharing from "vue-social-sharing";
import VueMeta from "vue-meta";

import checkView from "vue-check-view";
Vue.use(checkView);

import { i18n } from "@/plugins/i18n";

import VueTelInputVuetify from "vue-tel-input-vuetify";

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(Fragment.Plugin);
Vue.component("StaticTemplate", StaticTemplate);

Vue.config.productionTip = false;

Vue.use(VueTippy);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
Vue.use(VueSocialSharing);
Vue.component("tippy", TippyComponent);

let app;
var userRefreshCallback = null;
var userMetadata = null;
var userVerified = null;
var underMaintenanceListener = null;
var maintenanceCallback = null;

const languages = ["en", "es", "ja"];

const updateConnectionStatus = function () {
  const isOnline = navigator.onLine;
  if (isOnline) {
    rtdb.goOnline();
    rtdbC.goOnline();
  } else {
    rtdb.goOffline();
    rtdbC.goOffline();
  }
};

const getValidLanguage = function () {
  let lang = "ja";
  try {
    const storage = localStorage.getItem("noa-lang");
    const nav = navigator.language.split("-")[0];
    lang = storage || nav;
    lang = lang in languages ? lang : nav;
    if (!lang) lang = "ja";
    return lang;
  } catch {
    return lang;
  }
};

const createApp = function () {
  if (!app) {
    app = new Vue({
      i18n,
      fbapp,
      store,
      router,
      vuetify,
      data: {
        onLine: navigator.onLine, // initial status
      },
      created() {
        store.commit("loggedOut", { redirect: false });
      },
      render: (h) => h(App),
    }).$mount("#app");
  }
};

router.onError((error) => {
  if (/loading chunk .* failed./i.test(error.message) || /Refused to execute script from .* because its MIME type .* is not executable/i.test(error.message)) {
    const versionSnapshot = rtdb.ref("/admin/version").once("value");
    versionSnapshot.then((version) => {
      const latestVersion = version.val();
      if (latestVersion > process.env.VUE_APP_VERSION) {
        window.location.reload();
      }
    });
  }
});

router.beforeEach(async (to, from, next) => {
  // const lang = store.state.lang || localStorage.getItem('noa-lang')
  if (!to.params.lang) {
    // console.log('TO ', to.path)
    next("/" + getValidLanguage());
  }

  if (to.params.lang !== store.state.lang) {
    let lang = to.params.lang;
    if (!languages.includes(to.params.lang)) {
      lang = getValidLanguage();
      next(`/${lang}${to.path}`);
      // next("/" + lang + to.path)
    }

    store.commit("CHANGE_LANG", lang);
    // console.log('IN ', lang, to.params.lang, store.state.lang)
    // console.log('IN TO ', to.path)
  }

  if (to.name === 'register')
  {
    if (to.params.invitationId){
      const invitationIdSnapshot = await rtdb.ref('invitationIds').child(to.params.invitationId).get();
      if(invitationIdSnapshot.exists()) {
        if (invitationIdSnapshot.val() > 0) next();
        else next(`/${to.params.lang}/invite`)
      } else next(`/${to.params.lang}/invite`) 
    } else {
      next()
    }
    
  }

  // console.log(to.params.lang, store.state.lang)

  if (to.matched.some((record) => record.meta.auth)) {
    if (to.meta.maintenance && store.state.underMaintenance) {
      next(`/${store.state.lang}/maintenance`);
    } else if (auth.currentUser) {
      // USER VERIFIED EMAIL
      if (to.meta.verified) {
        if (store.state.isVerified) {
          next();
        } else {
          next(`/${store.state.lang}/summary/profile`);
        }
      } else {
        next();
      }
    } else {
      // console.log(to.path)
      store.commit('setToRoute', to.path);
      next(`/${store.state.lang}/login`);
    }
  } else if (to.matched.some((record) => record.meta.nouser)) {
    if (auth.currentUser) {
      next(`/${store.state.lang}/summary/profile`);
    } else {
      next();
    }
  } else {
    next();
  }
});

// console.log(process.env);
auth.onAuthStateChanged(async (user) => {
  try {
    await auth.currentUser;
  } catch {
    console.log("catch error");
    createApp();
  }

  createApp();
  if (user) {
    try {
      if (userRefreshCallback) {
        userMetadata.off("value", userRefreshCallback);
      }
      if (maintenanceCallback)
        underMaintenanceListener.off("value", maintenanceCallback);
      analytics.setUserId(user.uid);
      analytics.logEvent("login", { method: "refresh" });
      userMetadata = rtdb.ref("/users/" + user.uid + "/status");
      userRefreshCallback = async (verified) => {
        try {
          if (verified.exists()) {
            userVerified = verified.val();

            auth.currentUser.reload().then(async () => {
              const currentUser = await auth.currentUser;
              store.commit("updateUser", currentUser);
              store.commit("setVerified", userVerified);
              store.commit("loggedIn", { redirect: false });
            });
          } else {
            store.commit("setVerified", { verified: false, pending: false });
          }
        } catch {
          console.log("There is a problem with your user profile. ");
          store.commit("loggedOut");
          router.push({
            name: "home-how",
            params: { lang: store.state.lang },
          });
        }
      };
      // Subscribe new listener to changes on that node.
      userMetadata.on("value", userRefreshCallback);

      underMaintenanceListener = rtdb.ref("/admin/maintenance");
      maintenanceCallback = (maintenance) => {
        try {
          if (maintenance.exists())
            store.commit("setMaintenance", maintenance.val());
        } catch {
          console.log("maintenance listener error");
        }
      };

      underMaintenanceListener.on(
        "value",
        maintenanceCallback,
        (errorObject) => {
          console.log("The read failed: " + errorObject.name);
        }
      );
    } catch (e) {
      console.log(e);
      console.log("There is a problem with your user.");
      router.push(`/${store.state.lang}`);
      console.log("unable to subscribe to database");
      store.commit("loggedOut");
      router.push({ name: "home-how", params: { lang: store.state.lang } });
    }
  }
});

window.addEventListener("online", updateConnectionStatus);
window.addEventListener("offline", updateConnectionStatus);

const login = {
  currentPassword: 'Contraseña Actual',
  newPassword: 'Contraseña Nueva',
  changePassword: 'Cambio de Contraseña',
  register: 'Registrate',
  registerNow: '¡Registrate!',
  password: 'Contraseña',
  confirmPassword: 'Confirmar Contraseña',
  forgotPassword: '¿Olvidaste tu Contraseña?',
  chooseOption: 'O elige una de estas opciones',
  continueGoogle: 'Continuar con Google',
  continueTwitter: 'Continuar con Twitter',
  continueFacebook: 'Continuar con Facebook',
  noAccount: '¿No tienes cuenta?',
  username: 'Usuario',
  alreadyAccount: '¿Tienes cuenta?',
  resetEmail: 'Mandar un correo para resetear la contraseña',
  emailVerified: 'Su dirección de correo electrónico está verificada',
  promptEmail: 'Introduzca su dirección de correo electrónico para recibir un correo de verificación',
  noEmailReceived: '¿No ha recibido el correo? Compruebe su filtro para correo no deseado, o pruebe una dirección de correo electrónico diferente',
  verifyPhoneTitle: "Verifique su número de teléfono",
  phoneVerified: "Su número de teléfono está verificado",
  promptPhone: "Introduzca su número de teléfono para recibir un código de verificación",
  promptCode: 'Introduzca el código enviado al ',
  wrongPhoneCode: "El código que ha introducido no es correcto. Inténtelo de nuevo",
  requestNewCode: "Haga clic en \"Reenviar código\" para recibir un código nuevo",
  noSmsReceived: '¿No ha recibido el código? Pruebe un número de teléfono diferente',
  invalidPhone: 'Número de teléfono incorrecto',
  code: 'Código',
  sendCode: "Enviar código de verificación",
  resendCode: "Reenviar código",
  phoneExists: 'Otro usuario ya utiliza el teléfono proporcionado. Cada usuario debe tener un teléfono único.',
  blockedDevice: 'Hemos bloqueado todas las solicitudes de este dispositivo porque se ha detectado una actividad inusual. Inténtalo de nuevo más tarde.',
  codeExpired: "Este código ha caducado.",
};
  
 module.exports = login; 
const contactUs = {
  // Contact form
  contactUs: 'Contacto',
  contactTitle:
    'Si tienes alguna duda o quieres saber más sobre nosotros, envíanos un mensaje a través de este formulario.',
  name: 'Nombre',
  email: 'Correo electrónico',
  phoneNumber: 'Teléfono',
  message: 'Mensaje',
  send: 'Enviar',
 };
  
 module.exports = contactUs; 
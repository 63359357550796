const howWeWork = {
  howWeWork: 'Metodología',
  motto: 'Una plataforma que acerca el arte y coleccionismo a todos',
  democratize: 'DEMOCRATIZACIÓN',
  hwwSummary1:
    'NEO ONE ART es una plataforma que permite a sus usuarios ser copropietarios de bienes de alto valor histórico, cultural y patrimonial pertenecientes a diferentes colecciones privadas de todo el mundo.',
  hwwSummary2:
    'Cuando un usuario adquiere una participación de copropiedad en NOA, la cual denominamos “frame”, se está convirtiendo al instante en un coleccionista, un promotor cultural y también en un inversor. Adicionalmente, estará formando parte de una gran comunidad recibiendo los beneficios que ello comprende.',
  hwwSummary3:
    'Los bienes de alta connotación histórica y cultural, como las obras de arte, tienden a aumentar su valor con el paso de los años, dado que la sociedad acepta de manera consensuada su importancia en el desarrollo y conocimiento de la historia humana. Adicionalmente, según datos de Art Basel y USB report, el mercado del arte y antigüedades fue aproximadamente de 50,1 mil millones de USD en 2020, una cifra considerable a pesar los problemas provocados por la pandemia.',
  howWeOperate: 'Como operamos',
  hwwTitle1: 'Contactamos a coleccionistas alrededor del mundo',
  hwwText1:
    'Nuestro equipo de investigación con apoyo de nuestros asesores expertos, contacta y concerta reuniones con coleccionistas privados donde se les plantea nuestro concepto.',
  hwwTitle2: 'Establecemos contratos de administración',
  hwwText2:
    'Nuestro equipo legal genera las bases para los contratos con los coleccionistas, donde se asegura que la administración de la propiedad corresponderá a NEO ONE ART 株式会社 y que el lugar físico donde permanecerá resguardado el bien, sea este una pintura, escultura, libro, etc., será en territorio japonés.',
  hwwTitle3: 'Aseguramos y registramos la propiedad recibida',
  hwwText3:
    'Una vez que se cierra el contrato con el coleccionista y el bien es recibido en Japón, se procede a su revisión y digitalización por parte de nuestro equipo de innovación para posteriormente llevarlo a un depósito de seguridad.',
  hwwTitle4: 'Aplicamos nuevas tecnologías para generar innovación',
  hwwText4:
    'Luego de que el bien es digitalizado, nuestro equipo de innovación se preocupa de generar valor para nuestros grupos de interés, tales como usuarios, coleccionistas privados, inversionistas, comunidad, entre otros, por medio de la vinculación de los objetos físicos con tecnologías como el blockchain, la inteligencia artificial y la realidad aumentada. Esperamos informar prontamente de los nuevos desarrollos que nuestros equipo está preparando en estos ámbitos.',
  hwwTitle5: 'Exhibimos las obras de arte y otros bienes en eventos exclusivos',
  hwwText5:
    'Las obras de arte y otros bienes que forman parte de la colección administrada por NEO ONE ART株式会社 se expondrán en eventos organizados únicamente por NOA o en coproducciones con otras instituciones culturales o de entretenimiento. Los usuarios de NOA siempre tendrán la prioridad para asistir a todos nuestros más exclusivos eventos, ya sea, de forma gratuita o con descuentos preferenciales.',
 };
  
 module.exports = howWeWork;
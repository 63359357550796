const certificate = {
    certificateOfOwnership: '所有証明書',
    author: '作者',
    title: '作品',
    certifyThat: '本証明書はフレームコレクターであることを',
    certifyThat2: '証明するものです',
    proudOwner: 'のオーナーです',
    wa: ' 様 は',
    equating: 'に相当する',
    ofTotalWork: '本作品全体の',
    tokyo: '東京',
    japan: '日本',
    date: '日付',
    close: '閉じる',
}

module.exports = certificate;
import Vue from "vue";
import Vuex from "vuex";
import { router } from "@/plugins/vue-router";

import firebase from "firebase/compat/app";

import { db, rtdb } from "@/helpers/firebase";

import vuetify from "@/plugins/vuetify";
import { i18n } from "@/plugins/i18n";

Vue.use(Vuex);

const formatTime = function (ms) {
  let minutes = Math.floor(ms / 60000);
  let seconds = Math.round(Math.max(0, ms - minutes * 60000) / 1000);
  if (seconds === 60) {
    seconds = 59;
    minutes -= 1;
  }
  return `${minutes}:${leftFillNum(seconds, 2)}`;
};

const leftFillNum = function (num, targetLength) {
  return num.toString().padStart(targetLength, 0);
};

export default new Vuex.Store({
  state: {
    cartItems: [],
    storeFeatured: {},
    storeProducts: {},
    // stripeSecret: "",
    bodyBlocked: false,
    isLoggedIn: false,
    emailVerified: true,
    user: "",
    lang: "",
    unsubscribeProducts: undefined,
    enableInventory: false,
    cartTotal: 0,
    countdown: "0:00",
    intervalId: undefined,
    selectedFrames: [],
    totalValuePaid: 0,
    alertMessage: "",
    alertActionMsg: undefined,
    alertAction: undefined,
    alertColor: undefined,
    userProfile: {},
    isVerified: true,
    kycPending: true,
    kycVerified: false,
    underMaintenance: false,
    toRoute: undefined,
  },
  getters: {
    getCurrentDate: () => () => {
      const d = new Date();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      const timestamp =
        d.getFullYear() +
        (month < 10 ? "0" : "") +
        month +
        (day < 10 ? "0" : "") +
        day;

      return timestamp;
    },
  },
  mutations: {
    setToRoute(state, from) {
      state.toRoute = from;

    },
    setUserData(state, data) {
      state.userProfile = data;

    },
    updateDefaultPM(state, pm_id) {
      state.userProfile.default_pm = pm_id;
    },
    sendPasswordReset(state, email) {
      state.alertActionMsg = undefined;
      state.alertAction = undefined;
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          state.alertMessage = "Password reset email sent";
          state.alertColor = "success";
        })
        .catch((error) => {
          state.alertMessage = error.message;
          state.alertColor = "error";
          // ..
        });
    },
    setCheckoutFrames(state, value) {
      state.selectedFrames = value;
    },
    startCountdown(state, endTime) {
      if (state.intervalId) clearInterval(state.intervalId);
      state.intervalId = setInterval(() => {
        const remain = endTime - Date.now();
        if (remain < 100) {
          state.countdown = "0:00";
          clearInterval(state.intervalId);
          state.intervalId = undefined;
          state.selectedFrames = [];
          state.totalValuePaid = 0;
          return;
        }
        state.countdown = formatTime(remain);
      }, 1000);
    },
    emptyCheckoutFrames(state) {
      state.selectedFrames = [];
    },
    setTotalValuePaid(state, value) {
      state.totalValuePaid = value;
    },
    endCountdown(state) {
      clearInterval(state.intervalId);
      state.intervalId === undefined;
      state.countdown = "0:00";
    },
    BLOCK_BODY(state) {
      state.bodyBlocked = true;
    },
    RELEASE_BODY(state) {
      state.bodyBlocked = false;
    },
    CHANGE_LANG(state, value) {
      state.lang = value;
      document.cookie = `firebase-country-override=${value}`;
      localStorage.setItem("noa-lang", value);
      vuetify.framework.lang.current = value;
      i18n.locale = value;
    },
    ALERT_MESSAGE(state, value) {
      if (typeof value === "object") {
        state.alertMessage = value.msg;
        state.alertActionMsg = value.actionMsg;
        state.alertAction = value.action;
        state.alertColor = value.color;
      } else {
        state.alertActionMsg = undefined;
        state.alertAction = undefined;
        state.alertMessage = value;
        state.alertColor = undefined;
      }
    },
    loggedOut(state) {
      state.isLoggedIn = false;
      state.user = "";
      state.nickName = "";
    },
    refreshWebsiteVersion(state) {
      const versionSnapshot = rtdb.ref("/admin/version").once("value");
      versionSnapshot.then((version) => {
        state.version = version.val();
        if (state.version > process.env.VUE_APP_VERSION) {
          // console.log(state.version, process.env.VUE_APP_VERSION);
          if (process.env.NODE_ENV === 'production') window.location.reload();
        }
      });
    },
    setVerified(state, status) {
      const { pending, verified } = status;
      state.kycVerified = verified;
      state.kycPending = pending;
      // state.isVerified =
      //   !!state.user.phoneNumber &&
      //   state.user.emailVerified &&
      //   state.kycPending;
      state.isVerified =
        !!state.user.phoneNumber &&
        state.user.emailVerified &&
        !!state.user.displayName;
    },
    setMaintenance(state, value) {
      state.underMaintenance = value;
    },
    updateUser(state, value) {
      state.user = value;
      state.isVerified =
        !!state.user.phoneNumber &&
        state.user.emailVerified &&
        !!state.user.displayName;
    },
    async loggedIn(state, value = { redirect: false }) {
      state.isLoggedIn = true;
      state.user = firebase.auth().currentUser;
      
      setTimeout(async () => {
        // Refresh profile
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          await currentUser.reload();
          state.user = firebase.auth().currentUser;
        } else {
          state.user = undefined;
          state.isLoggedIn = false;
        }
      }, 3000);

      // state.isVerified = firebase.auth().currentUser.emailVerified && status.verified;

      db.collection("Users")
        .doc(state.user.uid)
        .onSnapshot((snapshot) => {
          try {
            if (snapshot.data()) {
              state.userProfile = snapshot.data();
            }
          } catch {
            router.push(`/${state.lang}`);
          }
        });
      if (value.redirect) {
        if (state.toRoute) {
          // console.log('to route ', state.toRoute)
          const goto = state.toRoute;
          state.toRoute = null;
          router.push(goto);
        } else {
          router.push(`/${state.lang}/summary/profile`);
        }
      }
      // router.push({
      //   name: "summary",
      //   params: { tabId: "profile" },
      // });
    },
    // emailVerified(state, value) {
    //   state.emailVerified = value;
    //   state.alertActionMsg = undefined;
    //   state.alertAction = undefined;
    //   state.alertMessage = "";
    //   state.alertColor = undefined;
    // },
    sendEmailVerification(state) {
      state.user.sendEmailVerification;
      state.alertActionMsg = undefined;
      state.alertAction = undefined;
      state.alertMessage = "Please check your email";
    },
    updateCart(state, value) {
      state.cartItems = value;
      sessionStorage.setItem("noa-cart", JSON.stringify(state.cartItems));
    },
    addToCart(state, item) {
      // state.cartItems[item._id] = item;
      // sessionStorage.setItem('noa-cart', JSON.stringify(state.cartItems));
      // console.log(state.cartItems);
      // state.cartItemCount += 1;
      state.cartItems.push(item);
      sessionStorage.setItem("noa-cart", JSON.stringify(state.cartItems));
    },
    deleteFromCart(state, id) {
      // console.log(item);
      // delete state.cartItems[item];
      // sessionStorage.setItem('noa-cart', JSON.stringify(state.cartItems));
      // console.log(state.cartItems);
      // state.cartItemCount -= 1;
      state.cartItems = state.cartItems.filter((el) => {
        return el._id !== id;
      });
      let total = 0;
      state.cartItems.forEach((item) => {
        total += item.value;
      });
      state.cartTotal = total;
      sessionStorage.setItem("noa-cart", JSON.stringify(state.cartItems));
    },
    deleteCart(state) {
      state.cartItems = [];
      state.cartTotal = 0;
      sessionStorage.setItem("noa-cart", JSON.stringify(state.cartItems));
    },
    setFeatured(state, value) {
      state.storeFeatured = value;
    },
    setProducts(state, value) {
      state.storeProducts = value;
    },
    unsubscribe(state) {
      if (state.unsubscribeProducts) {
        state.unsubscribeProducts();
      }
    },
    updateCartTotal(state, value) {
      state.cartTotal = value;
    },
  },
  actions: {
    CHANGE_LANG({ commit }, value) {
      commit("CHANGE_LANG", value);
    },
    showAlertMessage({ commit }) {
      commit("ALERT_MESSAGE");
    },
    unsubscribe({ commit }) {
      commit("unsubscribe");
    },
    blockBody({ commit }) {
      commit("BLOCK_BODY");
    },
    releaseBody({ commit }) {
      commit("RELEASE_BODY");
    },
  },
  modules: {},
  // plugins: [createPersistedState()],
});

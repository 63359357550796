const workDetails = {
  description: 'Description',
  year: '制作年',
  material: '素材',
  size: 'サイズ',
  provenance: '来歴',
  collectors: 'コレクター',
  newsfeed: 'News',
  currentPrice: '現在の価格',
  originalPrice: '元の価格',
  availableUnits: '購入可能な枠数',
  unitPrice: '各枠価格',
  buy: '購入する',
  share: 'SNSでシェア',
  frameValue: 'フレームバリュー',
  total: '合計',
  japan: '日本',
  location: '場所'
};

module.exports = workDetails;

const login = {
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  changePassword: 'Change Password',
  register: "Register",
  registerNow: "Register now!",
  password: "Password",
  confirmPassword: "Confirm Password",
  forgotPassword: "Forgot password?",
  chooseOption: "or choose one of these options",
  continueGoogle: "Continue with Google",
  continueTwitter: "Continue with Twitter",
  continueFacebook: "Continue with Facebook",
  noAccount: "No account?",
  username: "Username",
  alreadyAccount: "Do you already have an account?",
  resetEmail: "Send me an email to reset my password",
  emailVerified: "Your email address is verified",
  promptEmail: "Enter your email address to receive a verification email",
  noEmailReceived:
    "Didn’t receive an email? Check your spam folder, or try a different email address",
  verifyPhoneTitle: "Please verify your phone number",
  phoneVerified: "Your phone number is verified",
  promptPhone: "Enter your phone number to receive a verification code",
  promptCode: "Enter the verification code we sent to ",
  wrongPhoneCode: "The code you entered is incorrect. Please try again.",
  requestNewCode: 'Click  "Resend code" to receive a new code',
  noSmsReceived: "Didn’t receive a code? Try a different phone number",
  invalidPhone: "Wrong phone number",
  code: "Code",
  sendCode: "Send verification code",
  resendCode: "Resend code",
  phoneExists:
    "The provided phone number is already in use by an existing user. Each user must have a unique phone number",
  blockedDevice:
    "We have blocked all requests from this device due to unusual activity. Try again later",
  codeExpired: "This code has expired.",
};

module.exports = login;

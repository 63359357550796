const header = {
  registerNow: '¡Regístrate!',
  register: 'Regístrate',
  workOfArt: 'Obras de Arte',
  aboutUs: 'Sobre Nosotros ',
  contactUs: 'Contacto',
  featuredWorks: 'Obras Destacadas',
  seeMore: 'Ver más',
  howItWorks: 'Cómo Funciona',
  summary: 'Mi Cuenta',
  events: 'Eventos',
  logIn: 'Acceder',
  logOut: 'Cerrar Sesión',
  firstRound: 'Primera Ronda',
  openingSoon: 'Envíanos tus datos y te notificaremos.',
  requestAccess0: 'NEO ONE ART ahora solo está disponible por invitación.',
  requestAccess1: '¿Te gustaría formar parte de NEO ONE ART?',
  requestAccess2: 'Muestra tu interés y mándanos tus detalles.',
  nextRelease: 'Próximas Obras',
  responseInvitationMessage: 'Tus datos se han enviado correctamente',
  store: 'Tienda',
 };
  
 module.exports = header;
<template>
  <center-box>
    <div ref="recaptcha-container" id="recaptcha-container"></div>
    <div class="neo-card">
      <v-form ref="registerForm" v-model="valid" lazy-validation>
        <h2 class="heading-size-4 mb-4">{{ $t("register") }}</h2>
        <div class="neo-text-field">
          <div>{{ $t("email") }}</div>
          <v-text-field
            tabindex="2"
            autocomplete="off"
            v-model="email"
            flat
            solo
            outlined
            dense
            :rules="[rules.email, rules.required, rules.emailAvailable]"
            :messages="emailMsg"
            :label="$t('email')"
            @keyup="resetEmailError()"
            required
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t("password") }}</div>
          <v-text-field
            autocomplete="off"
            tabindex="4"
            v-model="password"
            flat
            solo
            outlined
            dense
            :append-icon="showPwd ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPwd ? 'text' : 'password'"
            :rules="[
              rules.required,
              rules.min,
              rules.oneUpperCase,
              rules.oneLowerCase,
              rules.oneNumber,
            ]"
            name="input-10-1"
            :label="$t('password')"
            :counter="password.length > 0"
            :messages="passwordMsg"
            @click:append="toggleEye"
            @keydown.enter="handleEnter"
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t("confirmPassword") }}</div>
          <v-text-field
            tabindex="5"
            v-model="verify"
            autocomplete="off"
            flat
            solo
            outlined
            dense
            :append-icon="showRepeatPwd ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showRepeatPwd ? 'text' : 'password'"
            :rules="[rules.required, passwordMatch]"
            name="input-10-1"
            :label="$t('confirmPassword')"
            :counter="verify.length > 0"
            @click:append="toggleEye2"
            @keydown.enter="handleEnter"
          ></v-text-field>
        </div>

        <v-btn
          tabindex="6"
          ref="loginBtn"
          class="mt-4"
          block
          :disabled="waiting || !valid"
          color="primary"
          @click="validate"
          id="sign-in-button"
        >
          {{ $t("register") }}
          <v-icon size="32" v-if="waiting" class="ml-1 waiting">
            mdi-tire
          </v-icon>
        </v-btn>
        <div class="recaptcha-badge">
          <VueRecaptcha
            style="display: inline-block"
            :sitekey="recaptchaKey"
            @verify="onVerify"
            @expired="onExpired"
            ref="recaptcha"
            size="invisible"
            badge="inline"
            type="contact"
            :loadRecaptchaScript="false"
            theme="light"
          ></VueRecaptcha>
        </div>
        
      </v-form>
      <!-- <p class="reCAPTCHA-disclaimer text-center">
        This site is protected by reCAPTCHA and the Google
        <a target="_blank" href="https://policies.google.com/privacy"
          >Privacy Policy</a
        >
        and
        <a target="_blank" href="https://policies.google.com/terms"
          >Terms of Service</a
        >
        apply.
      </p> -->
      <login-thirds
        @loginThirdClick="loginThirdClick($event)"
        :login-thirds="loginThirds"
        :inter-title="$t('alreadyAccount')"
        path="/login"
        :link-title="$t('logIn')"
      ></login-thirds>
    </div>
    
  </center-box>
</template>

<script>
// import firebase from 'firebase/compat/app';
import CenterBox from "@/components/Templates/CenterBox";
import LoginThirds from "@/components/Organisms/LoginThirds";
import UsersDS from "@/services/UsersDS";
import { firebase, analytics } from "@/helpers/firebase";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Register",
  components: {
    CenterBox,
    LoginThirds,
    VueRecaptcha,
  },
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE,
      initialStatus: { valid: false, verified: false },
      waiting: false,
      recaptchaOk: false,
      valid: true,
      username: "",
      lastName: "",
      telephone: {
        number: "",
        valid: false,
        country: undefined,
      },
      email: "",
      password: "",
      verify: "",
      loginPassword: "",
      loginEmail: "",
      userValid: true,
      showPwd: false,
      showRepeatPwd: false,
      usernameTakenMsg: "",
      passwordMsg: "",
      emailMsg: "",
      rules: {
        emailAvailable: () => true || "",
        available: () => true || "",
        required: (value) => !!value || "Required.",
        oneUpperCase: (v) =>
          /[A-Z]/.test(v) || "Must contain at least one upper case",
        oneLowerCase: (v) =>
          /[a-z]/.test(v) || "Must contain at least one lower case",
        oneNumber: (v) => /[0-9]/.test(v) || "Must contain at least one number",
        strong: (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(v) ||
          "Password must contain at least one upper-case, one lower-case and one number",
        min: (v) => (v && v.length >= 9) || "Min 9 characters",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        number: (v) =>
          Number.isInteger(Number(v)) || "Invalid telephone number",
      },
      loginThirds: [
        { icon: "mdi-google", color: "#4285f4", id: "google" },
        { icon: "mdi-twitter", color: "#1d9bf0", id: "Twitter" },
        { icon: "mdi-facebook", color: "#3b5998", id: "Facebook" },
      ],
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  methods: {
    onVerify(response) {
      if (response !== "") {
        this.register();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    onInput(formattedNumber, { number, valid, country }) {
      this.telephone.number = number.e164;
      this.telephone.valid = valid;
      this.telephone.country = country && country.name;
    },
    resetEmailError() {
      if (this.email !== this.badEmail) {
        this.emailMsg = "";
        this.rules.emailAvailable = true;
      } else {
        this.emailMsg = "This email is already registered";
        this.rules.emailAvailable = false;
      }
    },
    toggleEye() {
      this.showPwd = !this.showPwd;
    },
    toggleEye2() {
      this.showRepeatPwd = !this.showRepeatPwd;
    },
    updateStatSharing() {
      //   this.$store.commit('updateStatSharing', this.shareStats ? 1 : 0)
      this.shareStats = this.shareStatsModel ? "1" : "0";
    },
    cancel() {
      this.dialog = false;
      this.$router.go(-1);
    },
    handleEnter() {
      this.$refs.loginBtn.$el.click();
    },
    validate() {
      // First check if the username exists in the database
      if (this.$refs.registerForm.validate()) this.$refs.recaptcha.execute();
      // else
      // // this.$store.commit(
      // //               "ALERT_MESSAGE",
      // //               "Please correct the error in the registration form."
      // //             );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    register() {
      this.waiting = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          async (res) => {
            res.user.sendEmailVerification();
            analytics.logEvent("sign_up", {
              method: "email",
            });

            const response = UsersDS.create(res.user.uid, {
              email: this.email,
            });
            response.then(() => {
              this.$store.commit("loggedIn", { redirect: true });
              // this.$router.push(`/${this.$store.state.lang}/summary/profile`);
            });
          },
          function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            // var errorMessage = error.message;
            // [START_EXCLUDE]
            console.log(error);
            if (errorCode == "auth/weak-password") {
              this.rules.strong = false;
              this.passwordMsg = "Password is too weak";
            } else if (errorCode == "auth/email-already-in-use") {
              this.rules.emailAvailable = false;
              this.emailMsg = "This email is already registered";
              this.badEmail = this.email;
            } else {
              console.error(error);
              this.$store.dispatch("showAlertMessage");
              this.$store.commit("ALERT_MESSAGE", error.message);
            }

            this.userValid = false;
            this.waiting = false;
            // [END_EXCLUDE]
          }.bind(this)
        );
    },
    loginProvider(value) {
      let provider;
      if (value === "google") {
        provider = new firebase.auth.GoogleAuthProvider();
      } else if (value === "twitter") {
        provider = new firebase.auth.TwitterAuthProvider();
      } else if (value === "facebook") {
        provider = new firebase.auth.FacebookAuthProvider();
      }

      firebase.auth().useDeviceLanguage();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          /** @type {firebase.auth.OAuthCredential} */

          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = credential.accessToken;
          // The signed-in user info.
          const {
            additionalUserInfo: { isNewUser, profile },
          } = result;

          const user = result.user;
          if (isNewUser) {
            user.sendEmailVerification();
            analytics.logEvent("sign_up", {
              method: value,
            });
            // Delete the username to force prompt later
            user.updateProfile({
              displayName: "",
            });
            const response = UsersDS.create(user.uid, { email: profile.email });
            response.then(() => {
              this.$store.commit("loggedIn", { redirect: true });
            });
          } else {
            analytics.logEvent("login", { method: value });

            this.$store.commit("loggedIn", { redirect: true });
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          if (errorCode === "auth/account-exists-with-different-credential") {
            this.$store.dispatch("showAlertMessage");
            this.$store.commit(
              "ALERT_MESSAGE",
              "The email linked to this account is already registered"
            );
          } else {
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;
            console.log(errorCode, errorMessage, email, credential);
          }
        });
    },
    loginThirdClick(value) {
      this.loginProvider(value.id.toLowerCase());
    },
  },

  beforeMount() {
    if (this.$store.state.user) {
      this.$router.push(`/${this.$store.state.lang}/summary/profile`);
      // this.$router.push({
      //   name: "artwork",
      //   params: { lang: this.$store.state.lang },
      // });
      // this.$store.commit("loggedIn", { redirect: true });
    }
  },
  mounted() {
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "sign-in-button",
    //   {
    //     size: "invisible",
    //     callback: () => {
    //       this.recaptchaOk = true;
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       // ...
    //     },
    //     "expired-callback": () => {
    //       // Response expired. Ask user to solve reCAPTCHA again.
    //       console.log("bad recaptcha");
    //       // ...
    //     },
    //   }
    // );
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   'recaptcha-container',
    //   {
    //     size: 'normal',
    //     callback: () => {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       this.recaptchaOk = true;
    //     },
    //     'expired-callback': () => {
    //       // Response expired. Ask user to solve reCAPTCHA again.
    //       // ...
    //     },
    //   }
    // );
    // window.recaptchaVerifier.render();
    // window.recaptchaVerifier.verify();
  },
};
</script>
<style>
select::-ms-expand {
  display: block;
}
.recaptcha-badge {
  margin-top: 20px;
  text-align: center;
}

/* .grecaptcha-badge {
  visibility: hidden;
} */
.reCAPTCHA-disclaimer {
  font-size: var(--font-size-super-small);
}
</style>

const alert = {
  areYouSure: 'Are you sure?',
  ok: 'Ok',
  cancel: 'キャンセル',
  serverBusy: 'Our system is busy, please try again', 
  max: 'Max',
  characters: 'chars',
  fieldRequired: 'Required',
  verifyEmailMsg: 'Eメールアドレスを再度確認してください',
  resendEmail: 'Eメールを再送する',
  valueGT0: '０以上の数字を入力してください',
  resetPwdEmail: 'パスワードリセットメールが送信されました。',
  successfulRegister: '登録完了。',
  verificationEmailSent: 'メールを送信しました。受信したEメールのリンクをクリックし、登録情報を認証してください。',
  contactThanks:'お問合せありがとうございます。',
  contactConfirmation:'お問合せ内容を受信しました。回答までしばらくお待ちください。',
  contactPrerelease:'オンラインアクセス時にEメールにてご連絡いたします。',
  maintenanceHeader: 'We are under maintenance',
  underMaintenance: 'We are improving a few things in our platform, please try again later'
};

module.exports = alert;

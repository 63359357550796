<template>
  <div
    class="alert-message"
    :class="{
      'alert-message--success': $store.state.alertColor === 'success',
      'alert-message--error': $store.state.alertColor === 'error',
      'alert-message--disappear': disappear,
    }"
  >
    <div class="d-flex flex-column">
      <div class="flex-grow-1">{{ text }}</div>
      <div class="mt-2" v-if="$store.state.alertAction">
        <v-btn color="primary" @click="$store.commit($store.state.alertAction)">
          {{ $store.state.alertActionMsg }}
        </v-btn>
      </div>
    </div>
    <button @click="close" class="alert-message__close" type="button">
      <v-icon color="base" size="24">mdi-close</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    text: {
      type: String,
      required: true,
    },
    success: Boolean,
    error: Boolean,
    action: String,
    actionMsg: String,
  },
  data: function() {
    return {
      disappear: false,
    };
  },
  methods: {
    close() {
      this.disappear = true;
      setTimeout(() => {
        this.disappear = false;
        this.$emit('close');
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-message {
  position: fixed;
  top: var(--main-header-height);
  z-index: 1;
  min-width: 100vw;
  border: 2px solid var(--color-blue);
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  background-color: var(--color-blue-light);
  padding: 16px 32px;

  animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &--disappear {
    animation: slide-out-top 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  &--success {
    border-color: var(--color-success);
    background-color: var(--color-success-light);
  }
  &--error {
    border-color: var(--color-error);
    background-color: var(--color-error-light);
  }

  & > div {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    @media only screen and (min-width: 1200px) {
      right: 48px;
    }
  }

  @keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-top {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px);
      opacity: 0;
    }
  }
}
</style>

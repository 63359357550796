const company = {
  cTitle: "How we Operate",
  cMainHeader: "NEO ONE ART Service Description",
  cIntro:
    "A platform that brings art pieces and collections to the general public. NEO ONE ART is a platform that allows you to own [buy] and trade “frames” or physical sections of an art piece and collections. Owning a frame makes you co-owner of a good with high historical and cultural value. Our mission is to democratize art by transforming the exclusionary art market into an inclusionary community for collectors and art lovers alike.",
  cHeader1: "The art pieces and collections",
  cParagraph1:
    "We hold art pieces from world-renowned artists and collections with historical and cultural relevance. From Francisco de Goya to Pablo Picasso and Joan Miró, we focus on attractive works for collectors, art enthusiasts and investors.",
  cHeader2: 'The "frame" system',
  cParagraph2:
    'Our "frame" system reflects on the co-ownership idea. Our algorithm can fragment 2D and 3D objects, giving unique identifiers to each “frame”. Frames can be traded or transferred, as you are the owner! And they represent a specific quadrant of the object, a fraction that is collectible!',
  cHeader3: "Democratization and community",
  cParagraph3:
    "By dividing an art piece or collection into small and unique fragments, a high market valued good becomes accessible to consumers, democratizing the art market. As a result, you can co-own fine artworks without the hassle of buying the whole piece, sharing the ownership experience with others. Also, we believe in creating communities around this system, as owners can participate in events organized by ONE NEO ART.",
  cHeader4: "About NOA",
  cParagraph41:
    "NEO ONE ART is the first company in the world that is in charge of the preservation, administration and exhibition of private collections of objects with high historical, cultural and patrimonial value, democratizing the ownership of these goods in thousands of collectors with common interests around the world.",
  cParagraph42:
    "Being a private collector, a cultural manager and even an investor in the art market is within your reach and you can start to trade with just 1,000 yen.",
  cHeader5: "Leadership",
  cParagraph51:
    "Our team shares more than 60 years of experience in the field of art and the collection of historical objects, in addition, has led various types of projects in the main capitals of the world such as London, Madrid and New York.",
  cParagraph52: "Currently, our headquarters is in Minato-ku, Tokyo.",
  cTeam: "Team",
  team1:
    "Simón Montero Bachur, CEO and Director. MBA, with Japanese Language and Culture studies at Waseda University.",
  team2:
    "Jose María Salas, COO and Director. Master's degree in artificial intelligence and robotics from the University of Rome.",
  cAdvisors: "Advisors",
  cAdvisor1:
    "Jorge Federico Gras, Advisor, 5th Generation Collector, Dedicated to Research and Art Collecting for more than 45 years.",
  cAdvisor2:
    "Ana María Gallardo Montero, former United Nations official, director of philanthropy at the Gras Dinten Foundation, and expert advisor on international affairs.",
  cAdvisor3:
    "Sebastián Baeza González, Doctor in Human Geography from the University of Manchester, Advisor and Senior Researcher at NEO ONE ART.",
  team: {
    team1: {
      name: "Simón Montero Bachur",
      position: "CEO and Director",
      imageUrl: "Simon-Montero-Bachur",
      desc: " MBA, with Japanese Language and Culture studies at Waseda University.",
      founder: true,
      social: {
        linkedin: "https://www.linkedin.com/in/simón-i-m-11455325/",
      },
    },
    team3: {
      name: "Jorge Federico Gras",
      position: "Advisor",
      imageUrl: "Jorge-Federico-Gras",
      desc: "5th Generation Collector, Dedicated to Research and Art Collecting for more than 45 years.",
    },
    team4: {
      name: "Ana María Gallardo Montero",
      position: "Advisor",
      imageUrl: "Ana-Maria-Gallardo-Montero",
      desc: "Former United Nations official, director of philanthropy at the Gras Dinten Foundation, and expert advisor on international affairs.",
      female: true,
    },
    team5: {
      name: "Sebastián Baeza González",
      position: "Advisor",
      imageUrl: 'Sebastian-Baeza-Gonzalez',
      desc: "Doctor in Human Geography from the University of Manchester, Advisor and Senior Researcher at NEO ONE ART.",
    }
  },
};

module.exports = company;

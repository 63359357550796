const home = require('@/translations/es/home');
const contactUs = require('@/translations/es/contactUs');
const footer = require('@/translations/es/footer');
const header = require('@/translations/es/header');
const summary = require('@/translations/es/summary');
const workDetails = require('@/translations/es/workDetails');
const howWeWork = require('@/translations/es/howWeWork');
const buyFrames = require('@/translations/es/buyFrames');
const checkout = require('@/translations/es/checkout');
const login = require('@/translations/es/login');
const frameBrowser = require('@/translations/es/frameBrowser');
const company = require('@/translations/es/company');
const alert = require('@/translations/es/alert');
const employment = require('@/translations/es/employment');
const privacypolicy = require('@/translations/es/privacypolicy');
const faq = require('@/translations/es/faq');
const customerservice = require('@/translations/es/customerservice');
const tnc = require('@/translations/es/tnc');
const tncg = require("@/translations/es/tncg");
const notation = require("@/translations/es/notation");
const certificate = require("@/translations/es/certificate");
const emerging = require("@/translations/es/emerging");
const events = require("@/translations/es/events");

const es = {
  ...home,
  ...contactUs,
  ...footer,
  ...header,
  ...summary,
  ...workDetails,
  ...howWeWork,
  ...buyFrames,
  ...checkout,
  ...login,
  ...frameBrowser,
  ...company,
  ...alert,
  ...employment,
  ...privacypolicy,
  ...faq,
  ...customerservice,
  ...tnc,
  ...tncg,
  ...notation,
  ...certificate,
  ...emerging,
  ...events
};

module.exports = es;

const privacypolicy = {
  ppMainHeader: "Privacy Policy",
  ppIntro1:
    'NEO ONE ART Co., Ltd. (hereinafter referred to as "our company") provides exclusive and diverse services through the website operated by our company (hereinafter referred to as "our website") and other channels operated by our company. increase.',
  ppIntro2:
    'We always put the user at the center and recognize the importance of user security. Therefore, as a user community that complies with the "Personal Information Protection Law" (Law No. 57 of 2003, including subsequent revisions) and can use it with confidence on our website, the following privacy policy (hereinafter "this policy") ".) Is stipulated.',
  ppIntro3: "The official link to access our website is as follows.",
  ppIntro4: "https: //neoone.art and https://neooneart.jp",
  ppHeader1: "1. Our address, representative name",
  ppP11: "2-2-15 Minami Aoyama, Minato-ku, Tokyo",
  ppP12: "NEO ONE ART Co., Ltd.",
  ppP13: "CEO Monte Roberta Simon Ignacio",
  ppHeader2: "2. Appropriate acquisition of personal information",
  ppP21:
    "We will properly acquire personal information without any deception or any other improper means.",
  ppHeader3: "3. Purpose of use",
  ppP31:
    "The acquired user information will be used for the following purposes. In addition, when using the user's information for purposes other than the following purposes, obtain the consent of the user in advance.",
  ppP32:
    "To provide, protect, maintain and operate various services provided by us through our website",
  ppP33:
    "For accepting registrations related to the service, identity verification, user authentication, payment of usage fees, making backup copies, etc.",
  ppP34: "For the sale and shipping of products handled by our company",
  ppP35:
    "To check the status of remittances and payments made by users through financial institutions",
  ppP36:
    "For maintenance and support, including suggestions from users and answers to frequently asked questions",
  ppP37: "For all actions that enable us to improve our services",
  ppP38:
    "For changes in terms of use, explanation of risks and precautions, notification of actions that may lead to rule violations, etc.",
  ppP39:
    "For the convenience of our website, such as research, analysis of access and actual use, marketing activities, advertising effectiveness, etc., for the analysis of trends and user purchase history regarding our services.",
  ppP310:
    "To respond to disclosures and other requests from government agencies, courts and other government agencies",
  ppP311: "For appraisal of works managed by our company",
  ppP312:
    "To check financial institution account information, transfer status, etc. for proper management such as user credit at the time of new / continuous transactions",
  ppP313: "To carry out other tasks entrusted by users",
  ppP314: "For recruitment activities",
  ppP315: "To respond to audits",
  ppHeader4: "4. Media content",
  ppP41:
    "When uploading an image to a website using a user profile, etc., it is necessary to avoid uploading an image with embedded position data (EXIF GPS). Users of our website can download and extract location data from images on the website.",
  ppHeader5: "5. Safety management measures",
  ppP51:
    'We will take necessary and appropriate measures to prevent leakage, loss and damage of the personal information we handle, and will take all possible security measures to optimally manage personal information. For more information on the safety management measures we take, please contact us using the contact information provided in "Inquiries".',
  ppHeader6: "6. Outsourced supervision",
  ppP61:
    "When entrusting all or part of the handling of personal information to a third party, we will strictly select the third party, conclude an entrustment contract, and supervise the entrusted party so that the entrusted personal information will be handled safely. We will ensure necessary and appropriate supervision of third parties.",
  ppHeader7: "7. Shared use",
  ppP71:
    "Under certain circumstances, we may share user's personal information as follows in order to improve the service.",
  ppP72: "(1) Items of personal information to be shared",
  ppP73: "Name, email address",
  ppP74: "(2) Purpose of use of shared personal information",
  ppP75:
    "To share the purpose of use of users in the services operated by our company",
  ppP76: "To share the target audience of users in the services we operate",
  ppP77: "To share user preferences regarding the services we operate",
  ppP78: "(3) Scope of shared use",
  ppP79:
    "Artist of the art work purchased by the user, artist handling gallery, and other art work handler",
  ppP710: "(4) Shared use manager",
  ppP711: "2-2-15 Minami Aoyama, Minato-ku, Tokyo",
  ppP712: "NEO ONE ART Co., Ltd.",
  ppP713: "CEO Monte Roberta Simon Ignacio",
  ppHeader8: "8. Disclosure / correction of personal information",
  ppP81:
    "If a user requests disclosure of personal information based on the provisions of the Personal Information Protection Law, we will disclose it within a reasonable period after confirming the identity. If we cannot verify the identity of the user, we will not respond to the disclosure.",
  ppP82:
    "If there is an error in the content of personal information and there is a request for correction, addition, or deletion from the person, we will promptly investigate and respond within the range where the person can be confirmed. We will not respond to requests in other cases.",
  ppP83:
    "As a fee for disclosure, correction, etc. of personal information, we can charge the user 1000 yen per case as a fee.",
  ppHeader9: "9. Changes to this policy",
  ppP91:
    "The contents of this policy are subject to change or partial or total change. The revised Policy will be effective from the time it is posted on the Service.",
  ppHeader10: "10. If you do not agree to this policy",
  ppP101:
    'If the user does not partially or completely agree to this policy and thereby cannot provide all the information necessary to use our website, we will not be able to provide an excellent level of service. Therefore, we may not be able to do business with this user, contrary to our philosophy of "always putting the user at the center".',
  ppHeader11: "11. Use of COOKIE",
  ppP111:
    "We use cookies to obtain specific information related to navigation, pages visited, and the number of visits to our website. This information collected is used to improve the user experience and community.",
  ppP112:
    "Personal information cannot be identified from the cookie information. Users can also disable cookies at any time by setting their browser. In that case, some of the services may not be available.",
  ppP113:
    "If you have an account and log in to our website, a cookie will be applied temporarily to determine if your browser enables cookies, but this cookie does not contain any personal information. , It will be destroyed when you close the browser.",
  ppP114:
    "When you log in, cookies for storing login information and screen display options will also be applied.",
  ppHeader12: "12. Embedded content from other websites",
  ppP121:
    "The contents of these sites may contain embedded materials such as videos, images, articles and news. Embedded content on other websites behaves as if the user visited the other website.",
  ppP122:
    "These websites use cookies to embed additional third-party tracking and monitor their interaction with the content, so if you register on that website, your personal information will be collected. May be done.",
  ppHeader13: "13. Handling of registered user data",
  ppP131:
    "We will save the personal information provided in the user profile for the users who register on our website. All users can view their personal information at any time. You can also edit or delete your personal information under certain conditions through our support team. The username or nickname can only be changed by the user for good reason and will be analyzed on a case-by-case basis.",
  ppHeader14: "14. Contact us",
  ppP141:
    "If you have any requests regarding the handling of personal information, disclosures, questions, or opinions regarding topics covered by this policy, please contact us at the following e-mail address.",
  ppP142: "contact [at] neoone.art",
  ppP143: "NEO ONE ART Co., Ltd.",
  ppDate: "Published on: 25th July 2022",
};

module.exports = privacypolicy;

const alert = {
  areYouSure: '¿Estás seguro?',
  ok: 'Ok',
  cancel: 'Cancelar',
  serverBusy: 'Our system is busy, please try again', 
  max: 'Máximo',
  characters: 'caracteres',
  fieldRequired: 'Campo obligatorio',
  verifyEmailMsg: 'Por favor verifique su correo electrónico',
  resendEmail: 'Reenviar correo',
  valueGT0: 'Introduzca un número mayor de 0',
  resetPwdEmail: 'Correo para cambio de contraseña enviado',
  successfulRegister: 'Se ha registrado con éxito.',
  verificationEmailSent: 'Le hemos enviado un correo. Por favor verifique su registro pinchando en el enlace de confirmacion',
  contactThanks:'¡Gracias por tu mensaje!',
  contactConfirmation:'Hemos recibido tu mensaje. Nos pondremos en contacto lo antes posible.',
  contactPrerelease:'Te notificaremos cuando empecemos nuestros servicios.',
  maintenanceHeader: 'Página en mantenimiento',
  underMaintenance: 'Estamos mejorando algunas cosas en nuestra plataforma, por favor prueba a comprar más tarde.'
 };
  
 module.exports = alert;
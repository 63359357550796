const footer = {
  aboutNOA: 'NOAについて',
  operatingCompany: '運営会社',
  howWeWork: '使い方',
  employment: '採用情報',
  ourService: '当社のサービス',
  privacyPolicy: 'プライバシーポリシー',
  termsAndConditions: '利用規約',
  termsAndConditionsGallery: 'ギャラリーの利用規約',
  transactionLaw: '特定商取引法に基づく表記',
  antiqueLaw: '古物営業法に基づく表記',
  support: 'サポート',
  faq: 'よくあるご質問',
  footerText1: '© 2022 NEO ONE ART Inc. All rights reserved。',
  footerText2: '登録商標、番号6639715。',
  footerText3: 'フレーム販売処理システムは「特許出願中」、特願2022-83057。',

};

module.exports = footer;

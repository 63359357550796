const home = {
  homeTitle1: 'Transformando la manera de mirar las',
  everyone: 'todos',
  fineArt: 'bellas artes',
  requestInvite: 'Pedir Invitación',
  benefitsTitle: 'Beneficios',
  benefitsIntro: 'Nuestro objectivo es promover a una comunidad que apoye las artes y la cultura.',
  benefit1Title: 'Colleciona',
  benefit1Text: 'Sé propietario y colecciona partes concretas de obras creadas por grandes artistas como Goya, Picasso o Miró. Al mismo tiempo ayudarás a preservar el patrimonio cultural.',
  benefit2Title: 'Socializa',
  frameBrowserImage: '../assets/home/frame-browser-foujita-es.jpg',
  benefit2Text: 'Ser coleccionista de frames, te da acceso a eventos privados organizados por NOA. Observa tus obras expuestas mientras disfrutas de música y tomas algo con otros coleccionistas.',
  benefit3Title: 'Experimenta',
  benefit3Text: 'Al juntar diferentes artes en un mismo sitio se crea una sinergia de sentidos. Aprovecha la oportunidad de vivir el arte colaborativo en primera persona.',
  investTitle: 'Invertir',
  investIntro: 'A pesar de que nuestro objetivo principal es promover las artes y la cultura, es bien sabido que las obras de arte de grandes artistas aumentan su valor con el paso de los años. Un buen ejemplo es “Retrato de un hombre joven sosteniendo un redondel” (Portrait of a young man holding a roundel) de Sandro Botticelli subastado por Sotheby’s en más de 92 millones de dólares americanos a principios de 2021. Esta misma obra de arte fue vendida por los herederos de un coleccionista privado en 1982 por 810,000 libras esterlinas, unos 975,000 dólares americanos al cambio actual (fecha del cambio julio de 2022). En menos de 40 años, la revalorización de “Retrato de un hombre joven sosteniendo un redondel” es impresionante (1). No hay una garantía de que esto pueda ocurrir con todas las obras de arte, sin embargo es un aspecto a tener en cuenta.',
  investIntro2: 'Te presentamos tres beneficios económicos relacionados a la compra de frames:',
  invest1Title:  'Intercambio',
  invest1Text: 'Como coleccionista de frames, tendrás la posibilidad de vender o comprar frames a otros coleccionistas, pudiendo así obtener los beneficios de la valorización en el tiempo de las obras de arte que forman parte de nuestra colección.',
  invest2Title: 'Singularidad',
  invest2Text: 'Dado que el frame es un objeto único, los coleccionistas de frames podrán beneficiarse de su rareza. Aquellos frames que sean más apetecidos por otros coleccionistas, se podrían transar a precios superiores en el mercado de frames.',
  invest3Title: 'Acceso',
  invest3Text: 'Además de ser una representación digital de un activo físico, el frame cumplirá diferentes funciones en nuestra comunidad. Por ejemplo: ser un pasaporte para los diferentes eventos que organizaremos a lo largo del año y obtener descuentos en productos o servicios de NOA y sus socios comerciales. Estamos trabajando para que poseer frames se transforme en una experiencia única.',
  investRef1: '[1] Para más información sobre la historia de esta obra, puedes visitar',
  investCertificate: '../assets/home/certificate-es.png',
  and: 'y',
  investRefLink2: 'https://www.theguardian.com/artanddesign/2020/sep/24/true-beauty-for-the-ages-80m-botticelli-to-appear-at-auction',
  investRefLink3: 'https://www.sothebys.com/en/buy/auction/2021/master-paintings-sculpture-part-i/portrait-of-a-young-man-holding-a-roundel',
  investRef2: 'The Guardian',
  investRef3: 'Sotheby\'s',
  from1000: 'desde 1.000¥',
  homeTitle2: '',
  homeStep1Title: 'Regístrate y elige una obra de arte',
  homeStep1Text:
    'Nuestras obras de arte están disponibles exclusivamente para los usuarios de NEO ONE ART. En nuestra colección tenemos piezas maestras de algunos de los mejor artistas en la historia del arte.',
  homeStep2Title: 'Selecciona cuantos frames te gustaría comprar',
  homeStep2Text:
    'Los frames se asignan de forma aleatoria. Si decides comprar 10 o más, dependiendo del tamaño de la obra, podrás elegir un área específica.',
  homeStep3Title: 'Paga con tarjeta de crédito o débito, Apple o Google Pay',
  homeStep3Text:
    'Paga de manera segura y con confianza usando la pasarela de pago Stripe.',
  homeStep4Title: 'Forma parte de una comunidad',
  homeStep4Text:
    'Tener frames en propiedad te da acceso a eventos exclusivos organizados con la finalidad de promocionar el arte, desde conciertos hasta museos.',
  whatIsNext: 'Próximamente',
  homeNextTitle:
    'Una vez todos los frames de una obra se hayan vendido, podrás intercambiar frames con otros coleccionistas.',
  homeNextStep1Title: 'Haz ofertas a frames de otros coleccionistas',
  homeNextStep1Text:
    'Compra otros frames que te parezcan interesantes, colecciona frames adyacentes y se propietario de secciones enteras.',
  homeNextStep2Title: 'Pon tus frames a la venta',
  homeNextStep2Text:
    'Vende algunos de tus frames o intercambialos por frames de otros coleccionistas.',
  homeBlockchainTitle:
    'Prueba de propiedad irrefutable con libro mayor distribuido',
  weUse: 'Usamos',
  blockchain: 'blockchain',
  homeBlockchainText:
    'para documentar de manera segura todas nuestras obras y transacciones entre coleccionistas.',
  blockchainCard1Title: 'Registro inequívoco de cada obra de arte',
  blockchainCard1Text:
    'Cada obra de arte se registra de manera individual en la red de blockchain.',
  blockchainCard2Title: 'Registro de propiedad seguro para cada frame',
  blockchainCard2Text:
    'Cada fracción de una obra queda caracterizado por un contrato inteligente en el blockchain que especifica de qué área proviene y de quien es.',
  blockchainCard3Title: 'Provenance como propiedad intrínseca en el blockchain',
  blockchainCard3Text:
    'Cada una de las obras de arte en nuestra colección tiene una provenance. La provenance de cada frame empieza contigo y continua en nuestra comunidad de coleccionistas.',
 };
  
 module.exports = home; 
const header = {
  workOfArt: 'Work of Art',
  registerNow: 'Register now!',
  register: 'Register',
  aboutUs: 'About Us',
  featuredWorks: 'Featured Artwork',
  seeMore: 'See more',
  howItWorks: 'How it Works',
  summary: 'My Account',
  events: 'Events',
  logIn: 'Log In',
  logOut: 'Log Out',
  firstRound: 'First Round',
  openingSoon: 'Send us your details to get notified',
  requestAccess0: 'NEO ONE ART is now by invitation only.',
  requestAccess1: 'Would you like to be part of this project?',
  requestAccess2: 'Send us your details and show your interest.',
  responseInvitationMessage: 'Your details have been sent successfully',
  nextRelease: 'Next Release',
  store: 'Store',
};

module.exports = header;

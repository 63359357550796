const company = {
  cTitle: 'Cómo Operamos',
  cMainHeader: 'Descripción del servicio de NEO ONE ART',
  cIntro:
    'Una plataforma que trae obras de arte y el coleccionismo al alcance de todos. NEO ONE ART es una plataforma que te permite ser propietario [comprar] e intercambiar “frames” o fragmentos físicos de una obra o colección. El hecho de ser propietario de un frame te hace copropietario de un objeto de gran valor histórico, patrimonial y cultural.  Nuestro objetivo es democratizar el arte transformando este excluyente mercado en una comunidad inclusiva para coleccionistas y amantes del arte.',
  cHeader1: 'Las obras de arte y colecciones',
  cParagraph1:
    'Tenemos en nuestras manos obras de arte realizadas por artistas reconocidos a nivel mundial y colecciones de relevancia histórica y cultural. Desde Francisco de Goya a Pablo Picasso y Joan Miró, nos concentramos en objetos atractivos para los coleccionistas, amantes del arte e inversores.',
  cHeader2: 'Nuestro sistema de "frames"',
  cParagraph2:
    'Nuestro sistema de frames marca la diferencia en la idea de copropiedad. Nuestro algoritmo puede fragmentar objetos en 2D/3D y dar un identificador único a cada “frame”. Dado que tu eres el propietario, ¡un frame se puede intercambiar o transferir!. Además representan un área específica del objeto, ¡una fracción que es coleccionable!',
  cHeader3: 'Democratización y comunidad',
  cParagraph3:
    'Al dividir una obra de arte o colección en pequeños fragmentos únicos, un objeto de alto valor se convierte en algo al alcance de todos, democratizando el mercado del arte. Como resultado, puedes ser copropietario de obras de arte de gran valor sin el inconveniente de tener que comprar una obra en su totalidad, y compartir así esa misma experiencia con otros coleccionistas. Creemos en la creación de una comunidad basada en este sistema, dando acceso a todos los copropietarios a eventos exclusivos organizados por NEO ONE ART.',
  cHeader4: 'Sobre NOA',
  cParagraph41:
    'NEO ONE ART es la primera compañía en el mundo que se encarga de la preservación, administración y exhibición de colecciones privadas de objetos con alto valor histórico, cultural y patrimonial, democratizando la propiedad de estos bienes en miles de coleccionistas con intereses comunes alrededor del mundo.',
  cParagraph42:
    'Ser un coleccionista privado, un gestor cultural e incluso un inversor del mercado del arte está al alcance de tu mano y puedes comenzar a operar con solo 1,000 yenes.',
  cHeader5: 'Leadership',
  cParagraph51:
    'Nuestro equipo cuenta con una experiencia compartida de más de 60 años en el campo del coleccionismo de arte y objetos históricos, además, ha liderado proyectos de diferente índole en las principales capitales del mundo como Londres, Madrid y New York.',
  cParagraph52: 'Actualmente, nuestra sede está en Minato-ku, Tokio.',
  cTeam: 'Equipo',
  cTeamName1:
    'Simon Montero Bachur, CEO y Director. MBA, con estudios Lengua y Cultura japonesa en la Universidad de Waseda.',
  cTeamName2:
    'Jose María Salas, COO y Director. Master en inteligencia artificial y robótica de la Universidad de Roma.',
  cAdvisors: 'Asesores',
  cAdvisor1:
    'Jorge Federico Gras, Asesor, Coleccionista de 5ta Generación, Dedicado a la Investigación y al Coleccionismo de Arte por más de 45 años.',
  cAdvisor2:
    'Ana María Gallardo Montero, ex funcionaria de las  Naciones Unidas, directora de filantropía de la Fundación Grass Dinten y asesora experta en asuntos internacionales.',
  cAdvisor3:
    'Sebastián Baeza González, Doctor en Geografía Humana de la Universidad de Manchester, Asesor e Investigador Sénior de NEO ONE ART.',
  team: {
    team1: {
      name: 'Simón Montero Bachur',
      position: 'CEO y Director',
      imageUrl: "Simon-Montero-Bachur",
      desc: 'MBA, con estudios Lengua y Cultura japonesa en la Universidad de Waseda.',
      founder: true,
      social: {
        linkedin: 'https://www.linkedin.com/in/simón-i-m-11455325/'
      },
    },
    team3: {
      name: 'Jorge Federico Gras',
      position: 'Asesor',
      imageUrl: "Jorge-Federico-Gras",
      desc: 'Coleccionista de 5ta Generación, Dedicado a la Investigación y al Coleccionismo de Arte por más de 45 años.',
    },
    team4: {
      name: 'Ana María Gallardo Montero',
      position: 'Asesor',
      imageUrl: "Ana-Maria-Gallardo-Montero",
      desc: 'Ex funcionaria de las  Naciones Unidas, directora de filantropía de la Fundación Grass Dinten y asesora experta en asuntos internacionales.',
      female: true
    },
    team5: {
      name: 'Sebastián Baeza González',
      position: 'Asesor',
      imageUrl: 'Sebastian-Baeza-Gonzalez',
      desc: 'Doctor en Geografía Humana de la Universidad de Manchester, Asesor e Investigador Sénior de NEO ONE ART.',
    }
  }
};

module.exports = company;
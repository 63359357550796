const howWeWork = {
  motto: 'すべての人へ芸術作品と収集をもっと身近に',
  democratize: 'アートデモクラタイゼーション',
  hwwSummary1:
    ' NEO ONE ARTは、ユーザーが歴史的・文化的・遺産的価値の高い世界中から集められた、プライベートコレクションの共同所有者となることを可能にするプラットフォームです。',
  hwwSummary2:
    ' ユーザーは、「フレーム」と呼ばれる共同所有権をNOAで取得すると同時に、コレクター、文化プロモーター、そして投資家となります。さらに、ユーザーには大きなコミュニティの一員としての様々な特典が付与されます。',
  hwwSummary3:
    ' 芸術作品のような歴史的・文化的価値の高い作品は、人類史における発展と知的財産として社会的に重要視され、その価値は年々高まる傾向にあります。 クレア・マクアンドリュー博士（Dr. Clare McAndrew）のアートバーゼルレポート「The Art Market 2021」によると、2020年の美術品と骨董品の市場は、約501米億ドルと報告されています。コロナウィルスのパンデミックによる影響を加味しても、その市場規模の大きさは明らかです。',
  howWeOperate: 'How We Operate',
  hwwTitle1: '世界中のコレクターとの繋がり',
  hwwText1:
    ' NEO ONE ARTリサーチチームは専門家のアドバイスのもと、プライベートコレクターと連絡をとり、私達のコンセプトを発信していきます。',
  hwwTitle2: '管理契約の締結',
  hwwText2:
    ' NEO ONE ARTリーガルチームは、コレクターとの契約基盤を担います。この契約では、絵画・彫刻・書籍といったコレクターの資産の物理的な保護に於いて、NEO ONE ARTが管理業務責任を負うことを示しています。',
  hwwTitle3: '資産の登録と保証',
  hwwText3:
    ' 日本でのコレクターとの契約締結後、登録情報はNEO ONE ART I Tチームにより速やかに確認された上で、データ化され安全に保管されます。',
  hwwTitle4: 'イノベーションを生み出す最新テクノロジー',
  hwwText4:
    ' 資産の所有情報がデータ化されると、物理的なコレクションは、NEO ONE ART I Tチームによってブロックチェーン・人工知能・拡張現実といった最新のテクノロジーとリンクされ、ユーザーやプライベートコレクター、 投資家といった様々な利害関係者の集うコミュニティでの取引が可能になり、 資産価値の再形成を生み出します。 またI Tチームでは、この分野における新たなプロジェクトを開発中です。皆様にご報告できる日を心待ちにしております。',
  hwwTitle5: 'アート作品展示イベントの開催',
  hwwText5:
    ' NEO ONE ART株式会社の管理するアート作品を含む品々は、N O Aの主催するイベントや、他の文化機関・娯楽機関との共同開催イベントで展示されます。N O Aユーザーは、これらのエクスクルーシブなイベントへの参加が無料、もしくは割引待遇により優先的に出席できます。',
};

module.exports = howWeWork;

const tnc = {
  tncgP1: 'The following Terms & Conditions and the entire agreement of sellers with buyers (hereinafter "this document" or "these Terms and Conditions") with respect to the sale and purchase of works of art and other items (hereinafter, "Lots") through NOA Gallery of NEO ONE ART Inc. (hereinafter "NOA Gallery") either through this website or any other platform in which NOA Gallery participates (hereinafter "Digital Platforms") in particular, but not only, Art.sy Inc (hereinafter "Artsy") contains important information about your rights and obligations, as well as conditions and exclusions that apply to you. Please read it carefully.',
  tncgP2: 'By purchasing a lot from NOA Gallery through the digital platforms where it participates, you agree to be bound by and accept these Terms of Conditions, the Important Notices and, ultimately, all the content of this document including the arbitration agreement and the class action waiver set forth herein that you agree that disputes between you and us will be resolved by binding individual arbitration (except as otherwise provided herein). You also waive your right to participate in a class action or class arbitration.',
  tncgP3: 'We may change these Terms & Conditions from time to time in our sole discretion, at any time without notice to you, by posting such changes on the NEO ONE ART Website. It is your responsibility to periodically check the Terms of Sale for changes. You will know if these Terms of Conditions have been revised since your last visit to the Digital Platform by referring to the “Last Modified” date at the bottom of this page. IF YOU DO NOT AGREE TO THE CURRENT TERMS OF SALE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO REFRAIN FROM PLACING ORDERS FOR LOTS THROUGH THE DIGITAL PLATFORMS WHERE NOA GALLERY PARTICIPATES.',
  tncgl1: '1. Our Role.', 
  tncgl1p1: 'You acknowledge that we customarily offer products for sale on behalf of third-party sellers (hereinafter “collectors”), which may be unnamed, on a consignment basis. As a result, in offering certain products on the Digital Platform and accepting and fulfilling orders we are acting as the applicable seller’s agent and we do not take or transfer title to the product offered for sale on the Digital Platforms. In other instances, the products we offer for sale through the Digital Platforms are owned by NEO ONE ART.',
  tncgl1p2: 'We do not produce, endorse, or recommend any of the products made available through the Digital Platforms. You acknowledge that the products offered on the Digital Platforms are often antique or vintage in nature or have been previously owned and therefore may show signs of age and wear.',
  tncgl1p3: 'We offer a limited Authenticity Guarantee with respect to products offered for sale through the Digital Platforms.',
  tncgl1p4: 'Notwithstanding the disclaimers of representations and warranties in Section 13 of this document, unless otherwise agreed to in writing with NOA Gallery, if the products offered on the Digital Platforms are described using CAPITALIZED TYPE (the "CAPITAL HEADING"), then NOA Gallery guarantees for a period of 12 month from the date of the sale of the lot and only to the original purchaser of record that the lot is as described in the CAPITAL HEADING. In the event NOA Gallery determines, in its sole discretion, that the product description in the CAPITAL HEADING is incorrect, then your sole remedy, to the exclusion of any other remedy available to you by law or otherwise, shall be the refund of the purchase price paid in exchange for the lot which must be returned to NOA Gallery in the same condition as when sold to you. The benefits of this guarantee are not assignable and shall be applicable only to the original purchaser of record and shall apply unless otherwise agreed to in writing with NOA Gallery.',
  tncgl1p5: 'EXCEPT AS SET FORTH IN THE AUTHENTICITY GUARANTEE, WE ASSUME NO OBLIGATION TO INDEPENDENTLY VERIFY OR INVESTIGATE ANY LOT, THE RIGHTS OF ANY SELLER TO SELL ANY PRODUCT, OR THE QUALITY OR CONDITION OF ANY PRODUCT OFFERED FOR SALE THROUGH THE DIGITAL PLATFORMS.',
  tncgl2: '2. Orders with reserve deposit.',
  tncgl2p1: 'You agree that your order with reserve deposit is a commitment to purchase, in accordance with these Terms & Conditions, all lots listed in your order. In principle, an order cannot be canceled after its reservation deposit. All orders are subject to our acceptance and the amount of the reservation will be agreed jointly with the buyer. We will not be obligated to fulfill any request unless we accept payment for the reservation. Orders are subject to lot availability and we may, in our sole discretion, choose to limit, cancel, withhold or prohibit an order for any reason. This can happen, for example, if the ordered lot is out of stock (including if another buyer orders a particular lot before you do) or is incorrectly priced, if we suspect the order is fraudulent, or in other circumstances we deem appropriate. at our sole discretion. We also reserve the right to take steps to verify your identity before accepting or processing your reserve deposit order. In the event that the order with a reservation deposit is canceled at our convenience, we will refund the entire amount of the deposit in full.',
  tncgl2p2: 'If we accept your order, we will send you a confirmation email with your order number, a summary of the lots ordered, and details of shipping, pricing, and reserve deposit amount. Our acceptance of your order and the formation of a contract of sale between you and us in respect of the relevant lots will not take place unless and until you have received your order confirmation email and the reservation deposit has been made. If your order cannot be fulfilled for any reason, we will notify you using the information you provided when placing your order.',
  tncgl2p3: 'When you place an order with a reservation deposit, you could, for reasons of force majeure, cancel the order, however, you assume and accept from now on the following penalties in the amount of your reservation deposit:',
  tncgl2p3p1: '- From the moment the reservation deposit is made until 72 hours later, the penalty will be 25% of the total amount of the reservation deposit.',
  tncgl2p3p2: '- From 72 hours of making the reservation deposit up to 120 hours in total since the reservation deposit was made, the penalty will be 50% of the total amount of the reservation deposit.',
  tncgl2p3p3: '- From 120 hours after making the reservation deposit until the agreed deadline for payment of the total amount of the lot price, the penalty will be 100% of the total amount of the reservation deposit.',
  tncgl2p4: 'Certain lots you purchase through the Digital Platforms may be subject to additional terms and conditions presented to you at the time of such purchase. We reserve the right to modify, suspend or discontinue, temporarily or permanently, the offer of any product through the Digital Platforms at any time, at our sole discretion.',
  tncgl2p5: 'A REFERENCE TO A PRODUCT ON THE DIGITAL PLATFORMS DOES NOT IMPLY OR WARRANT THAT IT IS OR WILL BE AVAILABLE IN YOUR LOCATION OR AT THE TIME OF YOUR ORDER.',
  tncgl3: '3. Product Descriptions.',
  tncgl3p1: 'We attempt to provide accurate descriptions and images of products offered through the Digital Platforms. It is your responsibility to review all of the information provided about a product before placing an order. However, we do not warrant that the product descriptions, condition reports, images or other information provided are accurate, complete, precise, reliable, current or error-free. You acknowledge that the products are sold on an “as-is” basis.',
  tncgl4: '4. Prices.',
  tncgl4p1: 'All prices posted on the Digital Platforms are subject to change without notice.',
  tncgl4p2: 'We attempt to provide accurate pricing information regarding the products available on the Digital Platform, but cannot offer guarantees against pricing errors and are not responsible for typographical, or other pricing errors. If an error occurs, we will inform you using the information you provide when placing your order and you may either confirm your order (at the corrected product pricing) or cancel the order. We reserve the right to cancel any order arising from a pricing error. Prices on the Digital Platforms other than "neoone.art" are shown in US Dollars unless otherwise indicated. For international orders, duties and taxes may be included in the price depending on the destination country.',
  tncgl5: '5. Payment.',
  tncgl5p1: 'You agree to pay in full the prices for any purchases you make by payment by Visa, MasterCard, American Express or other means of payment accepted by Digital platforms, except in cases where another payment method such as direct bank transfer is previously agreed. We may process payments through third-party service providers, which may post operating rules related to payment on their respective websites and change such rules from time to time.',
  tncgl5p2: 'By placing an order, you authorize us, and our third-party service providers, to charge your selected payment method for all amounts presented to you in the checkout and purchase process. You represent and warrant that: (a) the payment information (including any credit or debit card information) you supply to us is true, correct, and complete; (b) you are duly authorized to use such payment method for the purchase; (c) charges incurred by you will be honored by your credit or debit card company (if applicable); and (d) you will pay charges incurred by you at the posted prices, including all applicable taxes. If payment is not received by us from your credit or debit card issuer or its agents, you agree to pay all amounts due promptly upon our first demand.',
  tncgl6: '6. Shipping & Tax.',
  tncgl6p1: 'Products ordered through the Digital Platform will be shipped to the destination specified in the Order Confirmation. Please note that NOA Gallery is not able to ship products to certain destinations. Please contact NOA Gallery to confirm whether we ship to your intended destination. Once an Order Confirmation is issued, NOA Gallery will arrange for shipment of the ordered products to you through a third party delivery service provider. When available, NOA Gallery will provide a shipment tracking number upon shipment of all or any part of your order.',
  tncgl6p2: 'Shipping and delivery dates and times are estimates only and cannot be guaranteed, and we are not liable for any loss or damage caused by a delay in shipment. Title and risk of loss pass to you upon our, or the applicable seller’s, transfer of products to the delivery service provider.',
  tncgl6p3: 'Posted prices do not include taxes and, unless otherwise specified on the relevant product page, do not include charges for shipping and handling. Applicable taxes and, if applicable, shipping and handling charges will be added to your merchandise total and will be itemized in your shopping cart and in your Order Confirmation. International orders may be subject to additional local taxes, import duties or other local requirements.',
  tncgl7: '7. Acceptance and Returns.',
  tncgl7p1: 'Any individual at the delivery address who accepts a delivery from the delivery service provider is presumed to be authorized to receive such delivery. In cases in which you have designated an alternative receiver, such person shall accept the delivery under all of the same terms and conditions that would apply had you accepted the delivery yourself.',
  tncgl8: '8. Your Privacy.',
  tncgl8p1: 'NOA Gallery will hold and process your personal information and may share it with another NOA Gallery affiliate or subsidiary company for use as described in, and in line with, NEO ONE ART\'s Privacy Policy published on NEO ONE ART’s website at neoone.art',
  tncgl9: '9. Your Obligations.',
  tncgl9p1: 'You agree to comply with these Terms and Conditions and all applicable laws and regulations subject to Japanese Law, which shall apply to their construction as well as to their effect. We, the Seller and the Buyer submit to the exclusive jurisdiction to Tokyo District Court.',
  tncgl10: '10. Customer Service.',
  tncgl10p1: 'You may contact our Client Service Department via email at support@neoone.art.',
  tncgl11: '11. Indemnification.',
  tncgl11p1: 'You agree to defend, indemnify on demand and keep NOA Galle and our affiliates, parents, subsidiaries and each of our and their respective officers, employees, agents, partners, content providers, service providers, suppliers and licensors (collectively, the “Released Parties”) indemnified, and hold the Released Parties harmless from any and all claims, liabilities, costs, losses (including without limitation consequential and indirect losses) and expenses, including reasonable attorneys\' fees, arising in any way from (a) your purchase or use of any lots made available through the Digital Platforms, or any related services or information, or (b) your fraudulent or deceptive acts or omissions, or breach or violation of the law (including infringement of any intellectual property or other right of any person or entity) or of the Terms of Sale. We will provide notice to you promptly of any such claim, suit, or proceeding. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to assist and cooperate with our defense of such claim. In any event, you shall not settle any such claim without our prior written approval.',
  tncgl12: '12. DISCLAIMER OF WARRANTIES.',
  tncgl12p1: 'YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR, AND ASSUME ALL RISKS RELATED TO, THE PURCHASE, HANDLING, STORAGE AND USE OF ANY LOTS MADE AVAILABLE TO YOU THROUGH THE DIGITAL PLATFORMS.',
  tncgl12p2: 'ALL LOTS, SERVICES AND INFORMATION MADE AVAILABLE THROUGH THE DIGITAL PLATFORMS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, THE RELEASED PARTIES (A) MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING ANY PRODUCTS, SERVICES OR INFORMATION MADE AVAILABLE OR PROVIDED THROUGH THE DIGITAL PLATFORMS, AND (B) HEREBY DISCLAIM, AND YOU HEREBY WAIVE, ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH RESPECT TO SUCH PRODUCTS, SERVICES AND INFORMATION, INCLUDING WITHOUT LIMITATION (1) THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, (2) ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, (3) ANY AND ALL WARRANTIES AND REPRESENTATIONS MADE IN PRODUCT OR SERVICES LITERATURE, OR OTHERWISE ON THE DIGITAL PLATFORM OR IN CORRESPONDENCE WITH SOTHEBY\'S OR ITS AGENTS, AND (4) ANY REPRESENTATION OR WARRANTY AS TO authenticity, attribution, the physical condition, size, quality, rarity, importance, provenance, restoration, exhibitions, literature, or historical relevance of the Property and no statement anywhere, whether oral or in writing, shall be deemed such a representation or warranty.',
  tncgl12p3: 'THE RELEASED PARTIES FURTHER DO NOT WARRANT OR GUARANTEE THAT (X) THE PRODUCTS OR RELATED SERVICES MADE AVAILABLE THROUGH THE DIGITAL PLATFORM WILL MEET YOUR REQUIREMENTS OR THAT THE QUALITY OF ANY PRODUCTS OR RELATED SERVICES AVAILABLE ON THE DIGITAL PLATFORM WILL MEET YOUR EXPECTATIONS OR (Y) ANY INFORMATION PROVIDED WITH RESPECT TO ANY PRODUCT OR RELATED SERVICE MADE AVAILABLE THROUGH THE DIGITAL PLATFORMS WILL BE TIMELY, ACCURATE, RELIABLE, PRECISE, THOROUGH, CORRECT OR COMPLETE.',
  tncgl13: '13. LIMITATION OF LIABILITY.',
  tncgl13p1: 'IN NO EVENT SHALL ANY RELEASED PARTY BE LIABLE, WHETHER IN AN ACTION BASED ON CONTRACT INDEMNIFICATION, OBLIGATION, OR TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE), COLLATERALLY OR ARISING FROM ANY STATUTORY DUTY, PRE-CONTRACT OR OTHER REPRESENTATIONS, OR OTHERWISE, HOWEVER ARISING, FOR ANY ECONOMIC LOSSES (INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUES, PROFITS, CONTRACTS, BUSINESS, GOODWILL OR ANTICIPATED SAVINGS) OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED TO (A) ANY BREACH OF THE TERMS OF SALE, (B) THE PURCHASE OR USE OF, OR THE INABILITY TO USE, PRODUCTS, SERVICES OR INFORMATION MADE AVAILABLE THROUGH THE DIGITAL PLATFORMS, (C) ANY INACCURACY OR OMISSION CONCERNING ANY OF THE INFORMATION PROVIDED ON THE DIGITAL PLATFORM WITH RESPECT TO THE PRODUCTS OR RELATED SERVICES MADE AVAILABLE THEREON; OR (D) LOST BUSINESS OR LOST SALES, IN EACH CASE EVEN IF SUCH RELEASED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
  tncgl13p2: 'SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR DIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS.',
  tncgl13p3: 'IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF THE RELEASED PARTIES FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THE TERMS & CONDITIONS OR YOUR PURCHASE OR USE OF THE DIGITAL PLATFORMS, PRODUCTS, SERVICES OR INFORMATION MADE AVAILABLE THROUGH THE DIGITAL PLATFORM EXCEED, IN THE AGGREGATE, FIFTY DOLLARS ($50.00).',
  tncgl14: '14. Miscellaneous.',
  tncgl14p1: 'We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms & Conditions, for any failure or delay in our performance under these Terms & Conditions when and to the extent such failure or delay is caused by or results from acts or circumstances beyond our reasonable control, including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor disputes (whether or not relating to our workforce), restraints or delays affecting carriers, inability or delay in obtaining supplies of adequate or suitable materials, or telecommunication breakdown or power outage.',
  tncgl14p2: 'The Purchase Terms, the Order Confirmation, and any other terms incorporated herein or therein by reference constitute the sole and entire agreement between you and NOA Gallery with respect to your purchase of products through the Digital Platforms, and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect thereto.',
  tncgl14p3: 'The Terms & Conditions and the relationship between you and us shall be governed by the laws of the Tokyo Distric Court, without regard to its conflict of law provisions. You agree that any cause of action that may arise under this document the Tokyo District Court shall be the exclusive jurisdictional court of first instance for disputes arising between the parties. Our failure to exercise or enforce any right or provision of the Purchase Terms shall not constitute a waiver of such right or provision.',
  tncgl14p4: 'Any waiver of any provision of these Terms & Conditions by NOA Gallery must be made in writing and signed by an authorized representative of NOA Gallery and the provision to be waived. Headings used in the Terms & Conditions are for convenience only and are not to be relied upon. If any provision of the Terms & Conditions is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties\' intentions as reflected in the provision, and the other provisions of the Terms & Conditions remain in full force and effect. The Terms & Conditions will inure to the benefit of, and are intended to be enforceable by, NOA Gallery successors, assigns and licensees. There are no third party beneficiaries to the Terms & Conditions.',
  tncgl15: '15. Export and Permits.',
  tncgl15p1: 'It is the purchaser’s sole responsibility to identify and obtain any necessary export, import, firearm, endangered species or other permit for the lot. Any notices in the Digital Platforms reflect NOA Gallery reasonable opinion at the time of cataloguing and are for general guidance only; NOA Gallery and the consignor make no representations or warranties as to whether any lot is or is not subject to export or import restrictions or any embargoes.',
  tncgl16: '16. Packing and Shipping.',
  tncgl16p1: 'We are not responsible for the acts or omissions in our packing or shipping of purchased items or of other carriers or packers of purchased items, whether or not recommended by us. Packing and handling of purchased items is at the entire risk of the purchaser.',
  tncgl17: '17. Governing Law.',
  tncgl17p1: 'This Agreement shall be governed by and construed in accordance with the laws of Japan, regardless of conflict of laws principles.',
  tncgl17p2: 'The provisions of the United Nations Convention on Contracts for the International Sale of Goods shall not apply to these Terms.',
  tncgl18: '18. Possibility of separation.',
  tncgl18p1: 'Even if any provision or part of these Terms & Conditions is determined to be invalid or unenforceable under the Consumer Contract Act or other laws and regulations, the remaining provisions and part of these Terms will be determined to be invalid or unenforceable. The remainder of the provisions will continue in force and effect.',
  tncgl19: '19. Further Inquiries.',
  tncgl19p1: 'You may direct questions about the Terms & Conditions, or other issues, to:',
  tncgad1: 'NOA Gallery',
  tncgad2: 'MinamiAoyama 2-2-15 Of. 323',
  tncgad3: 'Minato ku, Tokyo 107-0062',
  tncgad4: 'Attn: Digital Platforms Management',
  tncgad5: 'Last Modified: Dic 10, 2022',
};

module.exports = tnc;

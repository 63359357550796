const login = {
  currentPassword: '現在のパスワード',
  newPassword: '新しいパスワード',
  changePassword: 'パスワードを変更する',
  registerNow: "今すぐ登録",
  register: "登録する",
  password: "パスワード",
  confirmPassword: "パスワード認証",
  forgotPassword: "パスワードを忘れた方",
  chooseOption: "またはその他の選択肢から選ぶ",
  continueGoogle: "Googleで続ける",
  continueTwitter: "Twitterで続ける",
  continueFacebook: "Facebookで続ける",
  noAccount: "アカウントを持っていない",
  username: "ユーザー名",
  alreadyAccount: "アカウントを既にお持ちですか？",
  resetEmail: "メールでパスワードを変更する",
  emailVerified: "メールアドレスが確認されました",
  promptEmail: "確認メールを受信するメールアドレスを入力してください",
  noEmailReceived:
    "メールが届きませんか？迷惑メールフィルターをチェックするか別のメールアドレスをお試しください",
  verifyPhoneTitle: "あなたの電話番号を確認してください",
  phoneVerified: "電話番号が確認されました",
  promptPhone: "確認コードを受信する電話番号を入力してください",
  promptCode: "送信した確認コードを入力してください",
  wrongPhoneCode:
    "入力したコードは正しくありません。もう一度やり直してください。",
  requestNewCode:
    "新しいコードを受信するには [コードを再送] をクリックしてください",
  noSmsReceived: "コードが届きませんか？ 別の電話番号をお試しください",
  invalidPhone: "電話番号が間違っています",
  code: "コード",
  sendCode: "確認コードを送信",
  resendCode: "コードを再送",
  phoneExists: '提供された電話番号はすでに既存のユーザーによって使用されています。各ユーザーに固有の 電話番号 が必要です',
  blockedDevice: '不審なアクティビティが検出されたため、このデバイスからのリクエストはすべてブロックされました。しばらくしてからもう一度実行してください',
  codeExpired: "このコードは有効期限が切れています。"
};

module.exports = login;

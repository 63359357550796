<template>
  <div class="center-box">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CenterBox',
};
</script>

<style lang="scss" scoped>
.center-box {
  height: 100%;
  width: 100%;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>

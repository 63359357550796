const footer = {
  aboutNOA: 'About NOA',
  operatingCompany: 'Operating Company',
  howWeWork: 'How we Work',
  employment: 'Careers',
  ourService: 'Our Service',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsGallery: 'Terms and Conditions - Gallery',
  transactionLaw: 'Commercial Transactions Law',
  antiqueLaw: 'Antique Business Law',
  support: 'Support',
  faq: 'FAQ',
  footerText1: '© 2022 NEO ONE ART Inc. All rights reserved.',
  footerText2: 'NEO ONE ART® is a registered trademark. Registration number 6639715.',
  footerText3: 'The Frame Sales Processing System is “patent pending”, application number 2022-83057.',

};

module.exports = footer;

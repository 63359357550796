const buyFrames = {
  priceEvolution: 'Evolución del precio',
  buyAtPrice: 'Precio Actual',
  makeOffer: 'Hacer Oferta',
  browseOffers: 'A la Venta',
  numberFrames: 'Numero de Frames',
  chooseArea: 'Seleccionar Zona',
  chooseAreaMsg: 'Disponible si compras al menos 10 frames.',
  frames: 'Frames',
  frameId: 'Frame Id',
  frameValue: 'Valor del Frame',
  frameTax: 'Impuesto por Frame',
  total: 'Total',
  totalTaxInc: 'Total (IVA Inc.)',
  notAvailable: 'No disponible',
  subtotal: 'Subtotal',
  tax: 'IVA (10%)',
  taxTiny: 'IVA',
  taxInc: '10% IVA Inc.'
};

module.exports = buyFrames;

import { db, storage, auth, rtdbC, rtdb } from "@/helpers/firebase";
import { deleteObject } from "firebase/storage";
// import "firebase/firestore";

// import { collection } from 'firebase/firestore';

const COLLECTION = "Users";
const collectionRef = db.collection(COLLECTION);
const stripeCollectionRef = db.collection("stripe_customers");
const collectorsRef = db.collection("Collectors");

class UsersDS {
  async create(_id, info) {
    try {
      // return addDoc(collectionRef, info);
      if (info.username) {
        let username = info.username;
        let exists = false;
        // If user exists, add a number - give it 3 tries.
        for (let i = 0; i < 3; i++) {
          const query = await this.checkUsername(username);
          if (query.size > 0) {
            // Rename username
            username = `info.username${i}`;
            exists = true;
          } else {
            exists = false;
            break;
          }
        }
        if (exists) {
          info.username = "";
        }
      }

      return this.update(_id, info);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  read(_id) {
    return collectionRef.doc(_id).get();
  }

  getTransactions(_id) {
    return collectionRef
      .doc(_id)
      .collection("transactions")
      .orderBy("timestamp", "desc")
      .limit(10)
      .get();
  }

  checkUsername(username) {
    //OK
    try {
      return collectorsRef
        .where("usernamelc", "==", username.toLowerCase())
        .get();
    } catch (e) {
      console.log("Error checking usernames");
    }
  }

  getUserInvitations() {
    return rtdb
      .ref("/users/")
      .child(auth.currentUser.uid)
      .child("/invitations/")
      .once("value");
  }

  getUserRole() {
    try {
      return collectionRef.doc(auth.currentUser.uid).get("role");
    } catch (e) {
      console.log("Error checking usernames");
    }
  }
  isVerified() {
    return db.collection("kyc").doc(auth.currentUser.uid);
  }

  getUsername(uid) {
    return collectorsRef.doc(uid).get();
  }

  setPublicInfo(uid, info) {
    collectorsRef.doc(uid).set(info);
  }

  update(_id, info) {
    collectionRef
      .doc(_id)
      .set(info, { merge: true })
      // .then(() => {
      //   if ("username" in info) {
      //     collectorsRef.doc(_id).set({
      //       username: info.username,
      //       usernamelc: info.username.toLowerCase(),
      //     });
      //   }
      // })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }

  getShippingAddresses(_id) {
    return collectionRef.doc(_id).collection("shipping_addresses").get();
  }

  addShippingAddress(_id, address) {
    return collectionRef.doc(_id).collection("shipping_addresses").add(address);
  }

  getDefaultShippingAddress(_id) {
    return collectionRef.doc(_id).get();
  }

  deleteShippingAddress(userId, addressId) {
    collectionRef
      .doc(userId)
      .collection("shipping_addresses")
      .doc(addressId)
      .delete();
  }

  updateShippingAddress(_id, info) {
    collectionRef
      .doc(_id)
      .collection("shipping_addresses")
      .doc(info.id)
      .update(info)
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }

  updateStripeAddress(_id, info) {
    stripeCollectionRef
      .doc(_id)
      .collection("address")
      .add(info)
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }

  getCollectorsByWorkId(id) {
    return rtdbC
      .ref("/artwork/")
      .child(id)
      .child("/collectors/")
      .orderByChild("quantity")
      .limitToLast(10)
      .once("value");
  }
  getCollectorsArtwork(uid) {
    return rtdbC
      .ref("/collector/")
      .child(uid)
      .child("collection")
      .once("value");
  }

  getCollectorsCollection() {
    return collectorsRef
      .doc(auth.currentUser.uid)
      .collection("collection")
      .once("value"); //.get(workId);
  }
  getCollectorsArtworkInfo(workId) {
    return collectorsRef
      .doc(auth.currentUser.uid)
      .collection("collection")
      .doc(workId)
      .once("value"); //.get(workId);
  }

  getCollectorsArtworkInfoRT(workID) {
    return rtdbC
      .ref("/artwork/")
      .child(workID)
      .child("collectors")
      .child(auth.currentUser.uid)
      .once("value");
  }

  updateAvatar(filename, imageData) {
    // const storageRef = storage
    //   .ref(`${COLLECTION}/${_id}/Images/${filename}`)
    //   .put(imageData);
    // const extension = filename.split('.').pop();
    const metadata = {
      // cacheControl: 'public,max-age=60',
      cacheControl: 'no-store',
      contentType: 'image/jpeg'
    };

    const storageRef = storage
      .ref(`avatars/${auth.currentUser.uid}.jpg`)
      .putString(imageData, 'data_url', metadata);

    storageRef.then((snapshot) => {
      snapshot.ref.getDownloadURL().then(async (url) => {
        // await collectorsRef
        //   .doc(auth.currentUser.uid)
        //   .doc('info')
        //   .update({ avatar: url });
        auth.currentUser.updateProfile({
          photoURL: url,
        });
      });
    });
  }

  deleteImage(_id, path) {
    const imageRef = storage.ref(path);
    // Delete the file
    deleteObject(imageRef);
    this.update(_id, {
      imageURL: "",
      storageLocation: "",
    });
  }

  delete(_id) {
    // First get the path to the images and delete them
    this.read(_id)
      .then((doc) => {
        const data = doc.data();
        if (data.storageLocation) {
          const imageRef = storage.ref(data.storageLocation);
          // Delete the file
          deleteObject(imageRef);
        }
      })
      .then(() => {
        // Once all the photos are deleted, delete the document
        collectionRef.doc(_id).delete();
      });
  }
}

export default new UsersDS();

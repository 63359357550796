const howWeWork = {
  howWeWork: 'How we Work',
  motto: 'A platform that brings art and collecting closer to everyone',
  democratize: 'DEMOCRATIZATION',
  hwwSummary1:
    'NEO ONE ART is a platform that allows its users to be co-owners of assets of high historical, cultural and heritage value belonging to different private collections around the world.',
  hwwSummary2:
    'When a user acquires a co-ownership stake in NOA, which we call a “frame”, they are instantly becoming a collector, a promoter of culture and an investor. Additionally, the users will be part of a large community receiving the benefits that this includes.',
  hwwSummary3:
    'Properties with a high historical and cultural value, such as works of art, tend to increase in value over the years, given that society accepts their importance in the development and knowledge of human history. Additionally, according to data from Art Basel and USB report, the art and antiques market was approximately USD 50.1 billion in 2020, a considerable figure despite the problems caused by the pandemic.',
  howWeOperate: 'How we Operate',
  hwwTitle1: 'We contact collectors around the world',
  hwwText1:
    'Our research team, with the support of our expert advisors, contact and arrange meetings with private collectors where our concept is presented to them.',
  hwwTitle2: 'We establish administration contracts',
  hwwText2:
    'Our legal team generates the bases for the contracts with the collectors, where it is ensured that the administration of the property will correspond to NEO ONE ART株式会社 and that the physical place where the property will remain protected, be it a painting, sculpture, book, etc. , it will be in Japanese territory.',
  hwwTitle3: 'We insure and register the received property',
  hwwText3:
    'Once the contract with the collector is closed and the property is received in Japan, it is reviewed and digitized by our innovation team to later take it to a safe deposit.',
  hwwTitle4: 'We apply new technologies to generate innovation',
  hwwText4:
    'After the property is digitized, our innovation team will be in charge of generating value for our stakeholders, such as users, private collectors, investors, the community, among others, by linking physical objects to technologies such as blockchain, artificial intelligence and augmented reality. We look forward  to informing you soon of the new developments our team is preparing in these areas.',
  hwwTitle5: 'We exhibit the works of art and other goods in exclusive events',
  hwwText5:
    'The works of art and other goods that are part of the collection managed by NEO ONE ART株式会社 will be exhibited at events organized either solely by NOA or in co-productions with other cultural or entertainment institutions. NOA users will always have priority to attend all our most exclusive events, either for free or with preferential discounts.',
};

module.exports = howWeWork;

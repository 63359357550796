const workDetails = {
  description: 'Descripción',
  year: 'Año',
  material: 'Material',
  size: 'Tamaño',
  provenance: 'Provenance',
  collectors: 'Coleccionistas',
  newsfeed: 'Noticias ',
  currentPrice: 'Precio Actual',
  originalPrice: 'Precio Original',
  availableUnits: 'Unidades Disponibles',
  unitPrice: 'Precio Unitario',
  buy: 'Comprar',
  share: 'Compartir',
  frameValue: 'Valor del Frame',
  total: 'Total',
  japan: 'Japón',
  location: 'Situado en',
};

module.exports = workDetails;

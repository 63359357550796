const header = {
  featuredWorks: '注目作品',
  seeMore: 'See More',
  howItWorks: 'How it Works',
  workOfArt: '作品一覧',
  aboutUs: 'NOAについて',
  contactUs: 'お問い合わせ',
  summary: 'アカウント',
  events: 'イベント',
  logIn: 'ログイン',
  logOut: 'ログアウト',
  firstRound: 'First Round',
  openingSoon: '通知を受け取るためにあなたの詳細を送ってください',
  nextRelease: 'Next Release',
  requestAccess0: 'NEO ONE ARTは現在招待を受けた方のみご利用いただけます。',
  requestAccess1: 'このプロジェクトのメンバーになりたいですか？',
  requestAccess2: 'もし興味があれば、詳細を送ってください。',
  responseInvitationMessage: 'お客様の情報は正しく送信されました。',
  store: 'お店',
};

module.exports = header;

const home = {
  featuredWorks: '注目作品',
  homeTitle1: 'の見方に変化を',
  from1000: '¥1,000から',
  everyone: 'すべての人へ',
  fineArt: '美術',
  requestInvite: '招待をリクエスト',
  frameBrowserImage: '../assets/home/frame-browser-foujita-ja.jpg',
  benefitsTitle: 'ベネフィット',
  benefitsIntro: '私たちの目的は芸術と文化を支えるコミュニティを促進することです',
  benefit1Title: 'コレクション',
  benefit1Text: 'ゴヤ、ピカソ、ミロといった偉大な芸術家作品の決められた枠(フレーム)の所有者になることができます。また同時に文化遺産保護の活動に貢献することもできます。',
  benefit2Title: '交流',
  benefit2Text: 'フレームのコレクターになることでNOAが主催するプライベートイベントにご参加いただけます。ほかのコレクターの方々と音楽やお飲み物をお楽しみいただきながら、ご自身が所有する作品を鑑賞していただけます。',
  benefit3Title: '体験',
  benefit3Text: 'DJとアートやダンスとアートの融合など様々な芸術を一箇所に集めることで、空間にインスピレーションの相乗効果が生み出されます。所有者様には、今までにない新感覚の企画をお楽しみいただけます。このコラボレーション アートを初めて体験する機会を得てください。',
  investTitle: '投資',
  investIntro: '私たちの主たる目的は芸術や文化を促進する活動にありますが、偉大な芸術家の作品は年数が経過することによって作品価値が上がることはよく知られています。一つの良い例が、2021年はじめにサザビーズのオークションで9200万ドル以上で落札されたサンドロ・ボッティチェッリ(1) 作「ラウンデルを持つ青年の肖像」です。同作品は、1982年にある個人収集家の相続人が81万ポンド（2022年7月時点の為替レートで約97.5万ドル）で落札しています。40年も経たずに「ラウンデルを持つ青年の肖像」がこのように再評価されたことは感慨深いものがあります。全ての芸術作品に起こりうることではないかもしれませんが、注目に値する出来事であるでしょう。',
  investIntro2: 'フレームのご購入に関連する、3つの経済的なベネフィットをご紹介いたします：',
  invest1Title:  'トレード',
  invest1Text: 'フレームコレクターになると、他のコレクター様とフレームの売買が可能になります。そして、私たちのコレクションを構成する美術作品の年数の経過による価値の上昇によって利益を得ることができます。',
  invest2Title: '希少性',
  invest2Text: '各フレームは一点ものなので、フレームのコレクター様方はその希少性から利益を得ていただけます。一部のコレクター様にとって魅力的なフレームは、通常のフレーム市場より高値で取引される可能性があります。',
  invest3Title: 'フレームの特典',
  invest3Text: 'さらに、フレームは単に物理的資産のデジタル代替物であるだけでなく、私たちのコミュニティー内で様々な機能を果たします。例えば、年間を通して催されるイベントへのパスポートとしてお使いいただけますし、またNOAや協賛パートナーの製品もしくはサービスの割引を受けられることなどが挙げられます。私たちはフレームを所有することがユニークな体験につながるよう取り組んでまいります。',
  investCertificate: '../assets/home/certificate-ja.png',
  investRef1: '[1] もしこの作品の経緯をお知りになりたい場合は、次のページをご覧いただけます：',
  and: ', ',
  investRefLink2: 'https://www.theguardian.com/artanddesign/2020/sep/24/true-beauty-for-the-ages-80m-botticelli-to-appear-at-auction',
  investRefLink3: 'https://www.sothebys.com/en/buy/auction/2021/master-paintings-sculpture-part-i/portrait-of-a-young-man-holding-a-roundel',
  investRef2: 'The Guardian',
  investRef3: 'Sotheby\'s',
  homeTitle2: '世界的芸術家の作品を収集できるプラットフォーム',
  homeStep1Title: '登録して作品を選びます',
  homeStep1Text:
    '全ての作品はNEO ONE ARTユーザー専用です。 私たちのコレクションには、歴史上最高の芸術家の傑作があります。',
  homeStep2Title: '購入したい　フレーム数を　選択しましょう',
  homeStep2Text:
    'フレームはランダムに割り当てられます。 10個以上ご購入の場合は、フレーム数に応じてエリアをお選びいただけます。',
  homeStep3Title: 'クレジットカード、　Apple、　または　GooglePayで　お支払いください',
  homeStep3Text: 'Stripe決済による安全な支払いが可能です。',
  homeStep4Title: 'コミュニティの　一員に　なりましょう',
  homeStep4Text:
    'フレームを所有すると、コンサートから美術館まで、アートを宣伝することを目的とした特別なイベントにアクセスできます。',
  whatIsNext: 'What is Next',
  homeNextTitle:
    '作品のすべてのフレームが販売されると、他のコレクターとフレームを交換することができます。',
  homeNextStep1Title: '他の人のフレームにオファーをします',
  homeNextStep1Text:
    '面白いと思うかもしれない他のフレームを購入し、近くのフレームを集めて、全体を所有してください。',
  homeNextStep2Title: 'フレームを販売用に出品します',
  homeNextStep2Text:
    'フレームを売るか、仲間のコレクターの所有するフレームと交換してください。',
  homeBlockchainTitle: '分散型台帳による所有権の安全な証明',
  weUse: 'NEO ONE ARTでは',
  blockchain: 'ブロックチェーンを',
  homeBlockchainText:
    '使用して、すべての芸術作品とコレクターやユーザー間の取引を安全に記録しています。',
  blockchainCard1Title: '各作品の不朽の独自性',
  blockchainCard1Text:
    '各芸術作品は、ブロックチェーンネットワークの一意のエントリとして記録されます。',
  blockchainCard2Title: '全フレームの明確な所有権',
  blockchainCard2Text:
    '各フラクションは、どのエリアが誰によって所有されているかを示すブロックチェーンのスマートコントラクトで特徴付けられます。',
  blockchainCard3Title: 'ブロックチェーンの固有のプロパティとしての来歴',
  blockchainCard3Text:
    'コレクションにある私たちの芸術作品のそれぞれには、来歴があります。 各フレームの出所はあなたから始まり、私たちのコレクターコミュニティーへと続いていきます。',
};

module.exports = home;

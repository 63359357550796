const contactUs = {
  // Contact form
  contactUs: 'Contact Us',
  contactTitle:
    'If you have any questions or want more information, you can contact us through this form.',
  name: 'Name',
  email: 'email',
  phoneNumber: 'Telephone Number',
  message: 'Message',
  send: 'Send',
};

module.exports = contactUs;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-footer__wrap"},[_c('div',{staticClass:"main-footer"},[_vm._m(0),_c('div',[_c('div',{staticClass:"main-footer__title"},[_vm._v(_vm._s(_vm.$t("aboutNOA")))]),_c('ul',{staticClass:"main-footer__links"},[_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'company' }}},[_vm._v(_vm._s(_vm.$t("operatingCompany")))])],1),_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'how' }}},[_vm._v(_vm._s(_vm.$t("howWeWork")))])],1),_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'employment' }}},[_vm._v(_vm._s(_vm.$t("employment")))])],1)])]),_c('div',[_c('div',{staticClass:"main-footer__title"},[_vm._v(_vm._s(_vm.$t("ourService")))]),_c('ul',{staticClass:"main-footer__links"},[_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":{
              params: { lang: _vm.$store.state.lang },
              name: 'privacy-policy',
            }}},[_vm._v(_vm._s(_vm.$t("privacyPolicy")))])],1),_c('li',[_c('router-link',{attrs:{"to":{
              params: { lang: _vm.$store.state.lang },
              name: 'terms-and-conditions',
            }}},[_vm._v(_vm._s(_vm.$t("termsAndConditions")))])],1),_c('li',[_c('router-link',{attrs:{"to":{
              params: { lang: _vm.$store.state.lang },
              name: 'terms-and-conditions-gallery',
            }}},[_vm._v(_vm._s(_vm.$t("termsAndConditionsGallery")))])],1),_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'notation' }}},[_vm._v(_vm._s(_vm.$t("transactionLaw")))])],1),_c('li',[_c('router-link',{attrs:{"to":{
              params: { lang: _vm.$store.state.lang },
              name: 'notation-old',
            }}},[_vm._v(_vm._s(_vm.$t("antiqueLaw")))])],1)])]),_c('div',[_c('div',{staticClass:"main-footer__title"},[_vm._v(_vm._s(_vm.$t("support")))]),_c('ul',{staticClass:"main-footer__links"},[_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'faqs' }}},[_vm._v(_vm._s(_vm.$t("faq")))])],1),_c('li',[_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: 'contact' }}},[_vm._v(_vm._s(_vm.$t("contactUs")))])],1)])]),_c('div',{staticClass:"main-footer__social"},[_vm._l((_vm.items),function(item){return _c('v-btn',{key:item.icon,attrs:{"icon":"","href":item.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"base","size":"24px"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)}),_c('v-btn',{attrs:{"icon":"","href":"https://www.artsy.net/partner/noa-gallery?utm_campaign=artsy-embedded-widget&utm_source=volt&utm_medium=embedded-widget","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/sns/artsy-follow-us-32x32-a.png"),"width":"20","height":"20","alt":"Follow NOA Gallery on Artsy"}})])],2)]),_c('div',{staticClass:"footer-text-div"},[_c('p',{staticClass:"footer-text"},[_vm._v(_vm._s(_vm.$t("footerText1")))]),_c('p',{staticClass:"footer-text"},[_vm._v(_vm._s(_vm.$t("footerText2")))]),_c('p',{staticClass:"footer-text"},[_vm._v(_vm._s(_vm.$t("footerText3")))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-footer__logo"},[_c('img',{staticStyle:{"border":"1px solid","border-color":"#eeeeee","padding":"0px 2px"},attrs:{"width":"85","src":require("@/assets/NOA-logo-square-alpha.png"),"alt":"Neo Art"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"https://www.artsy.net/partner/noa-gallery","target":"_blank"}},[_vm._v("NOA Gallery ")])])}]

export { render, staticRenderFns }
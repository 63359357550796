const buyFrames = {
  priceEvolution: 'Price Trend',
  buyAtPrice: 'Buy at Price',
  makeOffer: 'Make an Offer',
  browseOffers: 'Browse Offers',
  numberFrames: 'Number of Frames',
  chooseArea: 'Choose frame area',
  chooseAreaMsg: 'Available if buying at least 10 frames',
  frames: 'Frames',
  frameId: 'Frame Id',
  frameValue: 'Frame Value',
  frameTax: 'Tax per Frame',
  total: 'Total',
  totalTaxInc: 'Total (VAT Inc.)',
  notAvailable: 'Not yet available',
  subtotal: 'Subtotal',
  tax: 'VAT (10%)',
  taxTiny: 'VAT',
  taxInc: '10% VAT Inc.',
};

module.exports = buyFrames;

const privacypolicy = {
  ppMainHeader: "プライバシーポリシー",
  ppIntro1:
    "　NEO ONE ART株式会社（以下「当社」という。）は、当社の運営するウェブサイト（以下「当社ウェブサイト」という。）、及び当社が運営するその他のチャネルを通じて、エクスクルーシブかつ多様なサービスを提供します。",
  ppIntro2:
    "　当社では、常にユーザーを中心に据え、ユーザーのセキュリティの重要性を認識しております。そのため、「個人情報保護法」（平成１５年法律第５７号。その後の改正を含む。）を遵守し、当社ウェブサイトで安心してご利用いただけるユーザーコミュニティとして、次のプライバシーポリシー（以下「本ポリシー」という。）を定めています。",
  ppIntro3:
    "　当社ウェブサイトにアクセスするための公式リンクは次のとおりです。",
  ppIntro4: "https://neoone.artとhttps://neooneart.jp",
  ppHeader1: "1. 当社の住所、代表者氏名",
  ppP11: "東京都港区南青山2丁目2番15号",
  ppP12: "NEO ONE ART株式会社",
  ppP13: "代表取締役　モンテロバーチャーサイモンイグナシオ",
  ppHeader2: "2. 個人情報の適正な取得",
  ppP21:
    "　当社では偽りその他いかなる不正な手段によらず、個人情報を適正に取得します。",
  ppHeader3: "3. 利用目的",
  ppP31:
    "取得したユーザー情報は、次の目的で使用します。また、ユーザーの情報を次の目的以外の目的で利用する場合は、事前にユーザーの同意を得ます。",
  ppP32:
    "当社ウェブサイトを通じて当社が提供する各種サービスの提供、保護、維持、運用のため",
  ppP33:
    "当該サービスに係る登録の受付、本人確認、利用者認証、使用料の決済、バックアップコピーの作成等のため",
  ppP34: "当社が取り扱う商品の販売及び発送のため",
  ppP35: "金融機関を通じてユーザーが行った送金と支払いの状況の確認のため",
  ppP36:
    "ユーザーからの提案やよくある質問への対応を含む、メンテナンス及びサポートのため",
  ppP37: "当社のサービス改善を可能にする全てのアクションのため",
  ppP38:
    "利用規約の変更、リスクとその注意事項に関する説明、ルール違反につながる可能性のある行動の通知等のため",
  ppP39:
    "調査、アクセスと実際の使用の分析、マーケティング活動、広告効果など、当社ウェブサイトの利便性のために、当社のサービスに関する傾向とユーザーの購入履歴の分析のため",
  ppP310:
    "行政機関、裁判所及びその他の政府機関からの開示及びその他の要求への対応のため",
  ppP311: "当社が管理する作品の鑑定のため",
  ppP312:
    "新規・継続取引時のユーザーの与信など、適切な管理のため、金融機関の口座情報、振込状況などを確認するため",
  ppP313: "その他ユーザーから委託された業務を遂行するため",
  ppP314: "採用活動のため",
  ppP315: "監査への対応のため",
  ppHeader4: "4. メディアコンテンツ",
  ppP41:
    "　ユーザープロファイルなどで画像をウェブサイトにアップロードする場合は、位置データ（EXIF GPS）が埋め込まれた画像のアップロードを避ける必要があります。 当社ウェブサイトのユーザーは、ウェブサイト上の画像から位置データをダウンロードして抽出できます。",
  ppHeader5: "5. 安全管理措置",
  ppP51:
    "　当社は、取り扱う個人情報の漏えい、紛失、破損を防止するために必要かつ適切な措置を講じるとともに、個人情報を最適に管理するために可能な限りのセキュリティ対策を講じます。当社が講じる安全管理措置の詳細は、「お問い合わせ」に記載の連絡先までご連絡ください。",
  ppHeader6: "6. 委託先の監督",
  ppP61:
    "　個人情報の取り扱いの全部又は一部を第三者に委託する場合は、第三者を厳密に選定し、委託契約を締結し、委託した個人情報を安全に取り扱うよう委託先を監督します。当社は、第三者へ必要かつ適切な監督を徹底します。",
  ppHeader7: "7. 共同利用",
  ppP71:
    "　特定の状況下では、サービスを改善するために、次のようにユーザーの個人情報を共同利用することがあります。",
  ppP72: "(1) 共同利用する個人情報の項目",
  ppP73: "　氏名、Eメールアドレス",
  ppP74: "(2) 共同利用する個人情報の利用目的",
  ppP75: "当社の運営するサービスにおけるユーザーの利用目的の共有のため",
  ppP76: "当社の運営するサービスにおけるユーザーのターゲット層の共有のため",
  ppP77: "当社の運営するサービスに関するユーザーの趣向の共有のため",
  ppP78: "(3) 共同利用の範囲",
  ppP79:
    "　ユーザーがご購入されたアート作品のアーティスト、アーティスト取り扱いギャラリー、その他アート作品の取り扱い者",
  ppP710: "(4) 共同利用の管理責任者",
  ppP711: "　東京都港区南青山2丁目2番15号",
  ppP712: "　NEO ONE ART株式会社",
  ppP713: "　代表取締役　モンテロバーチャーサイモンイグナシオ",
  ppHeader8: "8. 個人情報の開示・訂正等",
  ppP81:
    "　個人情報保護法の規定に基づき、ユーザーから個人情報の開示を求められた場合は、本人確認の上、合理的な期間内に開示いたします。ユーザーの本人確認ができない場合は、開示に応じません。",
  ppP82:
    "　個人情報の内容に誤りがあり、本人からの訂正、追加、削除の要請があった場合は、本人確認ができる範囲で迅速に調査・対応いたします。その他の場合におけるご要望には応じません。",
  ppP83:
    "　個人情報の開示、訂正等にかかる手数料として、当社は、ユーザーに対し、手数料として1件あたり1000円を請求することができます。",
  ppHeader9: "9. 本ポリシーの変更",
  ppP91:
    "　本ポリシーの内容は、変更又は部分的又は全体的な変更の対象となります。改訂された本ポリシーは、本サービスに掲載された時点から有効になります。",
  ppHeader10: "10. 本ポリシーに同意できない場合",
  ppP101:
    "　ユーザーが本ポリシーに部分的又は完全に同意せず、これにより当社ウェブサイトをご利用いただくために必要なすべての情報を提供できない場合、当社は優れたサービスレベルを提供できません。そのため、「ユーザーを常に中心に据える」という当社の理念に反して、このユーザーとの取引ができないことがあります。",
  ppHeader11: "11. COOKIEの利用",
  ppP111:
    "　当社はCookieを使用して、ナビゲーション、アクセスされたページ、及び当社ウェブサイトへのアクセス数に関連する特定の情報を取得します。これらの収集される情報は、ユーザー体験とコミュニティを改善する目的で使用されます。",
  ppP112:
    "　Cookieの情報から個人情報を特定することはできません。また、ユーザーはブラウザの設定により、いつでもCookieを無効にすることができます。その場合、サービスの一部がご利用いただけない可能性があります。",
  ppP113:
    "　アカウントをお持ちで当社ウェブサイトにログインした場合、ユーザーのブラウザがCookieを有効にするかを判断するため、一時的にCookieが適用されますが、このCookieには個人情報は含まれておらず、ブラウザを閉じると破棄されます。",
  ppP114:
    "　ログインすると、ログイン情報と画面表示オプションを保存するためのCookieも適用されます。",
  ppHeader12: "12. 他のウェブサイトからの埋め込みコンテンツ",
  ppP121:
    "　これらのサイトのコンテンツには、ビデオ、画像、記事、ニュースなどの埋め込み素材が含まれていることがあります。他のウェブサイトの埋め込みコンテンツは、ユーザーが他のウェブサイトにアクセスした場合と同じように動作します。",
  ppP122:
    "　これらのウェブサイトでは、Cookieを使用し、追加の第三者のトラッキングが埋め込まれ、そのコンテンツとの相互作用をモニタリングされるため、そのウェブサイトでご登録がある場合、ユーザーの個人情報が収集されることがあります。",
  ppHeader13: "13. 登録利用者データの取り扱い",
  ppP131:
    "　当社ウェブサイトに登録するユーザーのために、ユーザープロフィールで提供された個人情報を保存します。すべてのユーザーは、いつでも個人情報を閲覧できます。また、サポートチームを介して特定の条件下で個人情報の編集や削除が可能です。ユーザー名又はニックネームは、ユーザーによって適切な理由がある場合のみ変更でき、状況に応じて分析されます。",
  ppHeader14: "14. お問い合わせ",
  ppP141:
    "　個人情報の取り扱いに関するご要望、本ポリシーの対象となるトピックに関する開示、ご質問、ご意見は、次のメールアドレスまでご連絡ください。",
  ppP142: "contact [at] neoone.art",
  ppP143: "NEO ONE ART株式会社",
  ppDate: "制定日：2022年7月25日",
};

module.exports = privacypolicy;

const tnc = {
  tncTitle: "NEO ONE ART会員規約",
  tncIntro: "はじめに",
  tncIntro1:
    "このNEO ONE ART会員規約（以下「本規約」という。）は、NEO ONE ART株式会社（以下「当社」という。）が運営するアートプラットフォーム（以下「本サービス」という。）の会員の諸条件を定めるものです。",
  tncIntro2:
    "本サービスの会員の皆様（以下「会員」という。）が本サービスを利用するためには、本規約の全てに同意していただく必要があり、会員が本サービスを利用した場合、会員は本規約に同意したとみなされます。",
  tncA1: "第1条 （定義）",
  tncA1p1: "本規約上、用語の意味は、次のとおりです。",
  tncA1l1p1:
    "「フレーム」とは、本サービス上に出品された絵画その他の文化財（以下「本作品」という。)の所有権を小口化した上で、本規約に従って利用したり、取引したりできるようにしたものをいいます。",
  tncA1l1p2:
    "フレームは、平面の作品の場合はピクセル、立体の作品の場合は立方ミリメートルで測定される本作品の面積又は体積のデジタル表示をいいます。",
  tncA1l1p3:
    "フレームは座標で識別された独立した画像であり、物理的な作品の小片を表現しています。これにより、フレームはコレクターズアイテムに生まれ変わります。",
  tncA1l1p4:
    "当社は、出品された本作品ごとに、これを均等に分割し、フレームとします。",
  tncA1l2:
    "「会員登録希望者」とは、本サービスの会員登録を希望する者（法人を含む。なお、法人が会員登録を希望する場合、当社に対して直接連絡して承認を得るものとする。）をいいます。",
  tncA1l3:
    "「会員」とは、定める手続に従って本サービスの会員登録の承認を受けた者（退会し又は会員登録を取り消された者を除く。）をいいます。",
  tncA1l4:
    "「出品」とは、本作品のフレームの当初売却を行うために、本作品を本サービス上に掲載することをいいます。",
  tncA1l5:
    "「出品希望コレクター」とは、本サービス上で本作品の出品を希望する会員をいいます。",
  tncA1l6:
    "「出品コレクター」とは、本作品を本サービス上に出品した会員をいいます。",
  tncA1l7: "「購入希望者」とは、フレームの購入を希望する会員をいいます。",
  tncA1l8:
    "「売却希望者」とは、フレームの売却を希望するフレームコレクターをいいます。",
  tncA1l9: "「フレームコレクター」とは、フレームを保有する会員をいいます。",
  tncA1l10:
    "「優待」とは、フレームコレクターが享受することができるイベント参加、その他の優待をいいます。なお、優待の内容は当社の裁量により将来的に変更されることがあります。詳細は第１４条をご参照ください。",
  tncA2: "第2条 （本サービスの概要）",
  tncA2l1:
    "本サービスは、芸術の民主化、文化の振興、新しい世代によるコレクションの拡大を志向し、本作品を対象とするフレームの販売と会員間におけるフレームの売買を可能とする本サービスの提供、運営を行います。",
  tncA2l2:
    "会員は、本規約等に従って、本サービス上で出品された各本作品について発行されるフレームを購入できます。また、会員は、本規約等に従って、本サービス上に限り、自ら購入し、保有するフレームの全部又は一部を、他の会員に対して売却できます。当社は、会員間のフレームの取引に関するトラブルについて一切の責任を負いません。また、一度成立したフレームの取引は、理由にかかわらず、キャンセルすることができません。",
  tncA2l3:
    "本サービスは、原則として日本国外ではご利用できません。会員登録希望者が日本国外の方である場合、その都度審査させていただきます。当社が会員登録を承認した場合、会員は当社又はその代理人に対して、いかなる種類の法的措置も放棄するものとします。また、本サービスは、本規約に定める要件を満たす日本国民と日本在住者を対象とし、かつ、それに適したサービスであることを理解するものとします。",
  tncA2l4:
    "米国居住者へのフレーム提供は行っておりません。お客様がアメリカ合衆国（州、準州、コロンビア特別区を含む。）の市民、居住者、又はアメリカ合衆国法に基づき設立又は組織された法人又はパートナーシップなどを含む法人その他の主体である場合、フレームを購入したり、購入しようとしたりしないものとします。",
  tncA2l5:
    "万が一、会員が本サービス及び当社が認めるプラットフォーム以外でフレームを販売する場合、自己の責任と費用で行うものとします。本サービス以外で販売されたフレームについては、本作品に関する所有権を失います。",
  tncA2l6:
    "当社は、フレームの買取り又は払戻しの義務を負いません。各会員が各自の責任で所有するものとします。",
  tncA3: "第3条 （会員登録）",
  tncA3l1:
    "利用登録希望者は、当社の指定するフォーマットに必要事項を入力することにより、利用登録の申込みを行うものとします。",
  tncA3l2:
    "利用登録の申込みをするには、会員登録希望者本人の①Googleアカウント、Facebookアカウント、Twitterアカウント、又は有効なメールアドレス、②パスワード、③有効な電話番号、④本人確認、⑤フレーム又は本作品売却時の売買代金振込み用の金融機関口座（本人名義に限る。）に関する情報の登録を行うものとします。",
  tncA3l3:
    "会員登録希望者が未成年者の場合、事前に親権者など法定代理人の同意を得た上で本サービスを利用するものとします。なお、当社は、必要と判断した場合、会員に対して、さらなる法定代理人の同意、書類、情報等の提供を求めることがあります。",
  tncA3l4:
    "当社は、当社の裁量により、公的な身分証明書等の他の登録情報の提出を求め、又はKYC（Know Your Customer）ポリシーに基づき、当社又はこの目的のために契約した第三者を通じて本人確認手続をすることができます。詳しくは、第22条（第三者の提供するサービス）をご覧ください。",
  tncA3l5:
    "会員登録希望者は、必ず会員登録希望者本人が行い、正確な情報を入力してください。また、1人で複数の会員登録の申込み、会員登録を行うことはできません。",
  tncA3l6:
    "当社が会員登録の申込みを承認した時点で、会員登録希望者は、会員として本サービスの利用を開始することができます。",
  tncA3l7:
    "当社の判断により、本人確認に不備がある場合でも、会員登録を承認することがあります。その場合、会員が利用できる本サービスは限定されます。セカンダリーマーケットでの取引、フレームの販売、出金を行うには、会員のプロフィールが認証されること、すなわち、本人確認が完了していることが必須であり、そうでない場合、会員は前述の限定された本サービスの利用をすることができます。",
  tncA3l8:
    "当社は、当社の裁量により、例えばMetaMask等のサービスを利用した新しいアカウント登録方法を追加することができます。この場合、当社は、新たなアカウント登録方法の実施について、事前に会員にお知らせします。",
  tncA4: "第4条 （会員登録申込みの審査）",
  tncA4p1:
    "当社は、当社の自由な裁量に基づき（本規約上で当社の裁量を定める場合であっても、その判断は合理的根拠に基づく合理的判断をいう。以下同じ。）、会員登録の申込みの承認、不承認を決定します。次の各号のいずれかに該当する又は該当するおそれがあると当社が判断する場合、会員登録の申込みは承認されなかったり、取り消されたりすることがあります。また、当社は、会員登録の承認後も、会員登録を取消すことがあります。",
  tncA4l1: "(1)本規約に定める会員登録の条件を満たしていないとき",
  tncA4l2:
    "(2)同一の人物が複数のアカウントを取得しようとしている又は取得したとき",
  tncA4l3: "(3)他人になりすまして登録しようとしている又は登録したとき",
  tncA4l4: "(4)登録されたSNSアカウントが、本人の個人アカウントではないとき",
  tncA4l5: "(5)登録されたSNS上での活動が当社にとって不利益があるとき",
  tncA4l6: "(6)過去に本規約の違反等により、利用停止等の処分を受けていたとき",
  tncA4l7:
    "(7)会員登録の申込みの際に当社に提供された登録情報の全部又は一部（氏名、クレジットカード名義、番号等を含む。）が不正確であるとき、又は虚偽、誤記、若しくは記載漏れが含まれるとき",
  tncA4l8:
    "(8)当社の運営・サービス提供又はその他これらに支障をきたす行為をしたとき",
  tncA4l9:
    "(9)未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていないとき",
  tncA4l10:
    "(10)反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団その他これらに準ずる者をいう。）であるとき、又は資金提供その他を通じて反社会的勢力との何らかの交流、関与を行っているとき",
  tncA4l11: "(11)情報商材又はそれに関するビジネスに関わっているとき",
  tncA4l12: "(12)その他当社が独自の判断で不適当であると判断したとき",
  tncA5: "第5条（登録情報の変更）",
  tncA5p1:
    "会員登録希望者及び会員は、申告した情報に変更があった場合、当社の定める方法により、変更を直ちに当社に通知するものとします。",
  tncA6: "第6条（ユーザーIDとパスワードの管理）",
  tncA6l1:
    "会員は、会員登録に当たって設定されたユーザーID、パスワード等を責任を持って管理するものとします。",
  tncA6l2:
    "会員によるユーザーID、パスワード等の流出による損害、不利益等に関する責任は、会員が負います。",
  tncA6l3:
    "当社は、会員登録に当たって設定されたユーザーIDとパスワードが使用された場合、本人がその行為を行ったとみなすことができます。",
  tncA6l4:
    "会員は、ユーザーID、パスワード等が第三者に漏えいした場合、ユーザーID、パスワード等が第三者に使用されている場合、これらの疑いがある場合等には、直ちに当社にその旨を連絡した上、当社の指示があるときは、これに従うものとします。この場合、当社は、ユーザーID、パスワード等を不正アカウントとして一時的又は完全に停止できます。",
  tncA7: "第7条（会員登録の取消し、利用停止等）",
  tncA7l1:
    "当社は、会員が次の各号のいずれかに該当し又は該当するおそれがあると判断した場合、事前のお知らせなく、直ちに、会員資格の停止、取消し、本サービスの利用停止及び当社が発行したフレームの無効化等を行うことができます。なお、資格を取消された場合、会員が保有していたフレームは無効となり、当社に帰属します。",
  "tncA7l1-1":
    "(1)法令、本規約又は当社が別途定めるガイドライン等に違反したとき",
  "tncA7l1-2":
    "(2)本サービスのユーザーID、パスワード等を不正に使用し又は使用させたとき",
  "tncA7l1-3": "(3)第4条のいずれかに該当することが判明したとき",
  "tncA7l1-4": "(4)当社や他の会員に対する嫌がらせ、迷惑行為等があったとき",
  "tncA7l1-5":
    "(5)登録されたメールアドレスに連絡しても、３週間以上返答がないとき",
  "tncA7l1-6":
    "(6)登録された支払情報、振込先情報等について、不備等その他の理由により、当社が会員に対して行う支払い、振込み等に支障が生じたとき",
  "tncA7l1-7": "(7)本サービスが不正な目的で利用されたとき",
  "tncA7l1-8": "(8)その他当社が不適切であると判断したとき",
  tncA7l2:
    "当社は、本条による会員資格の停止又は取消し、本サービスの利用停止、フレームの無効化その他の措置の時点で、会員に送金されるべき出金があった場合、当社の判断により、支払いを留保できます。",
  tncA7l3:
    "当社は、会員の違法行為への関与が疑われる場合等も、当社の判断により、支払いを留保できます。",
  tncA8: "第8条（会員資格に関連する諸権利）",
  tncA8l1:
    "ユーザーIDとこれに紐づく本サービスの利用権は、会員に一身専属的に帰属します。",
  tncA8l2:
    "会員は、当該アカウントと本サービスの会員権を、当該会員以外の者（相続人を含む。）に対して、使用許可、譲渡、貸与、担保設定その他の処分をし、又は相続させることはできません。",
  tncA9: "第9条（会員の死亡時等の取り扱い）",
  tncA9l1:
    "フレームコレクターが死亡した場合又は失踪宣告を受けた場合、当社は、会員が保有していたアカウントを停止することができます。",
  tncA9l2:
    "死亡した会員の相続人は、当社所定の条件を満たし、かつ、当社所定の手続をすることにより、死亡した会員のフレームを承継できます。なお、フレームを承継した相続人は、本サービスの会員として本規約、その他の取引ルールを遵守するものとします。",
  tncA10: "第10条（出品）",
  tncA10l1:
    "出品希望コレクターは、当社に対して出品の申請を行い、かつ、当社の審査に通過することにより、本サービス上に本作品を出品できます。なお、審査の結果、承認された時点で当社から連絡の上、個別の出品条件などを決定して出品へ進みます。審査の結果、承認されなかった場合、手数料の支払等は発生しません。",
  tncA10l2:
    "当社は、審査結果について何ら保証しません。また、審査内容と結果に関する理由の開示はしません。",
  tncA10l3:
    "審査に通過し、会員の同意に基づき出品された本作品は、本サービス上に掲載され、フレームが設定されます。なお、この掲載の時点で、本作品の占有は、当社が指定する方法により、当社に移転し、出品コレクターは、それ以降、出品中の本作品の占有、譲渡、貸与、担保権の設定その他の処分をしないものとします。",
  tncA10l4:
    "本条に定める他、当社は、出品希望コレクターとの間で契約を成立させることができます。契約の条件は、当事者間で過去に合意した条件に基づき、その都度検討するものとします。",
  tncA11: "第11条（フレームの購入と売却）",
  tncA11l1:
    "会員は、本サービス上で、フレームを売買できます。本サービス上で定められた単価と数量により、本作品に関するフレームの購入申込みをすることができます。",
  tncA11l2:
    "本作品のフレームがすべて販売された場合、当社が別途指定する期間経過後に、本サービスにおけるセカンダリーマーケット取引ルールに従い、会員間でフレームを二次販売したり、交換したりすることがあります。 セカンダリーマーケットにおける取引には当社に対する手数料が発生します。詳細は特定商取引法に基づく表記をご参照ください。",
  tncA11l3:
    "会員は、自己の責任と判断で、フレームの売買等を行うものとします。当社は、会員間のフレームの売買等に関するトラブルについて一切の責任を負いません。また、一度成立したフレームの売買は、理由を問わず、キャンセルできません。",
  tncA11l4:
    "販売されたフレームは、本サービスの取引ルールに従い、時価で取引されます。そのため、経済的な理由、アーティスト及び本作品等の人気の変動、フレーム自体の希少性、その他の理由により、会員が保有するフレームについて、値上りや値下りの可能性があります。",
  tncA11l5:
    "各フレームの特性上、これらの価格は独立して値上がりしたり、値下がりしたりする可能性があります。つまり、同じ作品に属するフレームでも、価格が異なる可能性があります。",
  tncA11l6:
    "購入希望者と売却希望者は、フレームの売買を希望する場合、本人が、正確な情報を入力し本サービス上で、購入申込み、他の会員への売却をすることができます。他の会員からフレームの購入を希望する購入希望者は、当社所定の手続に従ってフレームの購入を申し込むものとします。",
  tncA11l7:
    "購入希望者は、本作品について設定されたフレームの上限、取引条件等の範囲で、その裁量により購入数を指定します。その上で購入数に相当する金額を、当社が指定する決済事業者に提供するものとします。また、売却希望者は、自己が保有するフレーム、取引条件等の範囲で、その裁量により売却数を指定するものとします。",
  tncA11l8:
    "当社は、会員保護の観点から、会員によるフレームの売買に関する数量、回数、値幅、売却益の引出等に関するルールを定め、また、これを適宜変更することがあります。会員は、当該ルールを遵守するとともに、当該ルールが適宜変更されることを理解の上で、本サービスを利用するものとします。また、当社は、会員によるフレームの売買に関して、本規約の違反その他の不適切な行為があると判断した場合、本規約に定める措置を取ることができます。",
  tncA11l9:
    "当社は、重複、その他のあらゆる種類の技術的又はコンピューター上のエラーにより、取引を取り消すことができます。複数の会員間で重複して取引が行われた場合、先に決済を登録した会員を有効な取引とみなします。",
  tncA11l10:
    "フレームについての購入申込みは、受付期間中に購入申込みがあったフレームの数が当該フレームの規定の数量に満たなかった場合、事後的に取り消されることがあります。当社はこのような取消処理等により会員に生じた損失の補償は一切しません。",
  tncA12: "第12条（フレームの売買の決済）",
  tncA12l1:
    "フレームの売買契約が成立した場合、売却希望者は、当社が指定する決済事業者に対して、フレームの購入代金を代理受領する権限を付与します。また、購入希望者は、決算事業者に対して、事前にクレジットカード又は銀行振込によりフレームの購入代金を提供し、かつ、代理送金する権限を付与します。フレームの売買契約が成立後、当社所定の時点で、購入代金は、当社が所定の手数料（振込手数料を含む。以下同じ。）を差し引いた上で、代金受領者から売却希望者に送金されます。この送金により購入代金の支払いは完了します。",
  tncA12l2:
    "代金受領者は、前項の送金後、購入希望者に対して、購入希望者からの受領額と売却希望者に対する送金額（当社所定の手数料を含む。）との差額（あれば）を当社所定の期間内に送金します。",
  tncA12l3:
    "売却希望者と購入希望者は、フレームの売買契約の決済完了後（売買契約が成立しなかった場合、直近の購入、売却の申込後又は入出金後）180日以内に、決済事業者に対して、規定する各送金額の引出申請を行うものとします。なお、当社は、売却希望者又は購入希望者から引出申請を受けた場合、当社所定の本人確認を求めることがあり、確認が終了するまで、引出を留保することがあります。",
  tncA12l4:
    "当社は、売却希望者又は購入希望者に対して、各送金額の引出申請を行うよう要求したにもかかわらず、売却希望者又は購入希望者が前項の期間内に引出申請を行わなかった場合、速やかに、売却希望者又は購入希望者が当社に登録した金融機関の出金先口座に、振込手数料を控除の上、各送金額を振込むことができます。",
  tncA12l5:
    "当社は、前各項に基づき振込手続をしたにもかかわらず、当社の帰責事由なく振込みが正常に完了しない場合又は売却希望者若しくは購入希望者が当社に出金先口座を登録しない場合、売却希望者又は購入希望者が各送金額の支払請求権を放棄したとみなすことができます。",
  tncA13: "第13条（保証の対象とならない事項）",
  tncA13l1:
    "当社は、出品された本作品の真贋を保証しません。会員は、仮に本サービス上に真正証明書が表示された場合も、自らの責任と判断で、本作品のフレームの売買を行うものとします。",
  tncA13l2:
    "当社は、本サービスの特定の目的に対する適合性、又は本サービスの利用による知的財産権その他の権利の侵害等に対して何ら保証しません。",
  tncA13l3:
    "当社は、本サービスに関連して、合法性、信頼性、正確性、特定の目的に対する適合性、知的財産権その他の権利の非侵害その他いかなる保証もしません。",
  tncA13l4:
    "当社は、本サービスの内容又は会員が本サービスを通じて知得する情報（本サービスに掲載される、本作品の価値、アート市場に関するデータ、有識者のコメント等を含む。）について、完全性、正確性、確実性、有用性その他いかなる保証もしません。",
  tncA14: "第14条（優待）",
  tncA14l1:
    "当社は、文化の振興と歴史的遺産の保護を願い、「優待」と称して、コレクション、芸術、文化に関するコミュニティ形成のために、フレームコレクターに対して、当社が主催したり、共催したりする様々なイベントに参加する機会を提供することを目的としています。",
  tncA14l2:
    "当社は、当社の裁量により、優待の一環として、フレームコレクターに付与するポイントシステムを創設することができます。ポイントの取得方法、利用条件、その他優待に関する事項については、別途当社ウェブサイトに掲載し、本規約の一部となります。",
  tncA14l3:
    "本規約に基づく優待を受ける権利は、フレームコレクターにのみ帰属しますが、当社の判断により、ポイント制度が創設された場合、フレームコレクター間でポイントを贈与したり、フレームコレクター以外の第三者に譲渡したりすることができます。なお、フレームコレクターのみが本サービスでポイントを利用することができます。会員は、優待で付与された特典を本規約に定める場合を除き、譲渡しないものとします。",
  tncA14l4:
    "優待の内容は、変更されることがあります。当社は、内容を変更した場合、フレームコレクターにその旨お知らせするものとします。",
  tncA14l5:
    "会員は、フレームの購入後に優待の内容が変更される可能性があることを了承した上で、本サービスを利用してください。優待の内容が変更されたことによりフレームコレクターが損害を被った場合でも、当社に故意又は重過失がある場合を除き、当社は一切の責任を負いません。",
  tncA15: "第15条（ブロックチェーンの性質上の制約）",
  tncA15l1:
    "当社は、本サービス上で売買されるフレームの取引に関する情報をブロックチェーンに記録することができます（現在は開発中の状況です。）。",
  tncA15l2:
    "ブロックチェーンの性質上、本サービス上の取引に関してブロックチェーン上に記録された情報を事後的に消去することはできません。",
  tncA15l3:
    "本サービス上で使用するブロックチェーンのアップグレード、ハードフォーク、その他ブロックチェーンにシステム上の不具合等が発生した場合、本サービスをご利用いただけません。",
  tncA15l4:
    "ブロックチェーン技術、暗号通貨、トークンを管理する規制体制は確立されていないため、新たな規制や政策が本サービスに重大な悪影響を及ぼすことがあります。",
  tncA15l5:
    "当社は、当社の裁量により、契約条件における特定の義務の履行を自動的に実行できる機能であるスマートコントラクトの使用を実装することができます。当社がそのような機能を実装した場合、本規約は、スマートコントラクトの対象となる義務の履行に追加されます。",
  tncA16: "第16条（第三者からの所有権に関する主張等）",
  tncA16l1:
    "当社は、出品された本作品について、盗品、遺失、相続その他の事由により、本作品の真正な所有者であると主張する者から返還請求その他の請求があった場合、又は本作品が法禁物である又はそのおそれがあることが判明した場合、当社は、これらの事由に関連して会員に生じた損害その他一切の不利益について、当社に故意又は重過失がある場合を除き、一切の責任を負いません。",
  tncA16l2:
    "当社は、古物営業法（昭和24年法律第108号。その後の改正を含む。）第21条の規定により、警察本部長等から保管を命じられた場合、保管期間の終了まで、フレームの販売を中止又は一時停止し、その間に当社に生じた損失（保管料等）を出品コレクターである会員に請求できます。",
  tncA17: "第17条（フレームに関する注意事項）",
  tncA17l1:
    "フレームは、本作品の所有権を小口化した共有持分権であって、本サービス上で、時価で取引されます。会員が保有しているフレームは、アーティスト、本作品等の人気の変動、フレーム自体の希少性、その他の理由により、値上りや値下りの可能性があります。会員は、このような価格変動に伴う損失のリスクを十分に理解した上で、フレームの売買をするものとします。なお、フレームは、株式や証券などの権利を表象するものではありません。",
  tncA17l2:
    "フレームは、座標による画期的な分割システムにより、それ自体がユニークな特徴を有するコレクターズアイテムです。将来、フレームが非代替性トークンになった場合など、当社の判断により、会員はフレームを所有権から切り離してコレクションすることができます。これらの使用は、当社の裁量で、公開（会員に公開）又は非公開（当社内での独占使用）とすることができます。 この意味で、当社はフレームに新たな機能を付与する権利を留保します。このような場合、当社は、会員に対して、保有するフレームに新しい機能を提供する可能性のある変更について、事前にお知らせします。",
  tncA17l3:
    "当社は、当社の裁量により、本サービスの会員が保有するフレームの所有権に関する証明書又はこれに準じる文書を発行することができます。1つ又は複数のフレームの所有権を示す証明書又はこれに準じる文書を有するだけで、その文書の保有者は、1つ又は複数のフレームに対する権利又はこれらのフレームが示す財産に対する権利は付与されません。これは、単なる情報提供のための文書です。",
  tncA17l4:
    "会員は、いかなる場合も、フレームについて本サービス外での使用、処分、貸与、担保の提供等を行うことはできません。また、フレームから付与された財産権を本サービスでの売買以外に使用することはできません。",
  tncA17l5:
    "本作品の盗難、滅失、本サービスの終了その他の理由により、フレームの消滅、無価値化等の可能性があります。会員は、このようなフレームの消滅、無価値化等に伴う損失発生のリスクを十分に理解した上で、フレームの売買をするものとします。",
  tncA17l6:
    "フレームの購入は、オンライン決済システム又は当社が定めるその他の方法により、クレジットカードで行うものとします。フレームは、当社が別途定める時期以降、本サービスの取引ルールに従い、各フレームコレクターが他の会員に販売することができます。会員間の取引には、特定商取引法に基づく表記など本規約とは別に定める取引手数料（売買手数料）がかかることを会員は本規約において同意します。",
  tncA17l7:
    "会員が、フレームを保有している場合も、本作品の鑑賞、貸出その他一切の利用はできません。ただし、フレームコレクターは、当社の裁量により、当社所定の条件に従って、イベント又は個人での鑑賞、貸出等の本作品の所有者としての利用その他の本作品の所有者としての優待を受けることがあります。",
  tncA18: "第18条（本作品の買取り）",
  tncA18l1:
    "本作品について、75%以上のフレームコレクター（以下「主要保有者」という。）は、本作品（以下「買取対象作品」という。）の他のフレームコレクター（以下「少数保有者」という。）の全員に対して、買取対象作品のフレームを自己に売渡すことを請求できます。",
  tncA18l2:
    "主要保有者は、前項の請求を希望する場合、当社に対して、その旨を通知するものとします。",
  tncA18l3:
    "当社は、前項の通知を受けた場合、次の計算式に基づき、買取対象作品の時価相当額に25％のプレミアムを付けた金額（以下「買取価額」という。）を設定し、主要保有者にお知らせします。",
  "tncA18l3-1":
    "(1)主要保有者が買取対象作品のフレームの75%を取得するまでの平均購入代金",
  "tncA18l3-2":
    "(2)主要保有者が買取対象作品のフレームの75%を取得した時点の購入代金",
  "tncA18l3-3":
    "(3)上記①又は②の高い方×１.２５×（100% － 主要保有者によるフレームの保有割合）",
  tncA18l4:
    "主要保有者は、当社から前項の通知を受領後３営業日以内に、代金受領者に対して、当社の指定する方法で買取価額を提供するとともに、当該提供をもって、買取価額を代理送金する権限を付与します。主要保有者は、上記の期間内に上記の買取価額の提供（代理送金の権限の付与を含む。）を行わない場合、少数保有者に対して、買取対象作品の売渡しを請求しないものとします。",
  tncA18l5:
    "当社は、代金受領者が主要保有者から買取価額の提供（代理送金の権限の付与を含む。）を受けた場合、受領後３営業日以内に、少数保有者に対して、①買取対象作品について、フレームの売渡請求がなされた旨、②買取対象作品の買取価額、③買取対象作品の売渡日をお知らせします。",
  tncA18l6:
    "前項の売渡日において、少数保有者は、主要保有者に対して、少数保有者が保有している買取対象作品の全てのフレームを売渡すとともに、主要保有者は、これを買取ります。",
  tncA18l7:
    "少数保有者は、第5項の通知をもって、代金受領者に対して、フレームの売渡代金を代理受領する権限を付与し、以降、少数保有者に対する売渡代金の送金、支払その他の取扱いは、第12条の売却希望者に準じます。",
  tncA18l8:
    "第6項に基づく売渡し及び前項に基づく売渡代金の送金後、主要保有者は、当社に対して、買取対象作品の引渡しを請求できます。当社は、買取対象作品の引渡しに関する具体的な日時、方法、費用負担等について、主要保有者と別途協議の上で決定します。",
  tncA18l9:
    "第6項に基づく売渡し及び第7項に基づく売渡代金の送金後、少数保有者は、買取対象作品のフレームを失います。少数保有者その他の会員は、本条の手続（フレームの失効を含む。）について同意します。",
  tncA19: "第19条（本作品の売却とフレームの買戻し）",
  tncA19l1:
    "当社は、第三者からの買取りの申出、本サービスの提供終了その他の理由により、第三者に対して、出品され又はフレームが売却された本作品を売却することがあります。当社は、出品され又はフレームが売却された本作品の売却の検討を始めた場合、本作品のフレームコレクターに対して速やかにお知らせします。",
  tncA19l2p1:
    "会員は、前項に規定する本作品の売却時点において、フレームを保有していた場合、当社所定の手続と条件に従って、フレームの買戻しを受けることができます。フレーム数当たりの買戻しの金額は、次の計算式を目安とし、本作品毎に当社が定めます。",
  tncA19l2p2:
    "（売却価格－当社手数料－為替手数料等の諸費用）÷発行済のフレームの総数",
  tncA19l3:
    "当社所定の手続と条件に従って買戻しを受けなかったフレームは、一定期間経過後に失効します。当社は、当該フレームの失効につき、一切の責任を負いません。",
  tncA20: "第20条（会員の退会手続）",
  tncA20l1:
    "会員は、本サービス上の「会員手続」から手続することにより、また、当社が特に承認した場合にはその方法により、本サービスから退会できます。",
  tncA20l2:
    "フレームコレクターは、本サービスからの退会前に、フレームを本サービス内で売却する場合に限り、本サービスから退会することができます。",
  tncA20l3:
    "フレームコレクターによる優待は、自己の裁量で行使してください。会員が退会の時点又は優待を提供するフレームの会員間取引までにこれらの措置をとらなかった場合、優待を受ける権利は無効となります。",
  tncA21: "第21条（個人情報の取扱い）",
  tncA21l1:
    "当社は、会員から提供された個人情報を、本サービスの提供に必要な範囲及び当社が別途定めるプライバシーポリシーで定められた目的の範囲で使用できます。また、会員は、上記に従って、当社が会員から提供された個人情報を使用することに同意します。",
  tncA21l2:
    "当社は、会員間で行われる個人情報の授受、個人情報の利用に関して発生したトラブル、損害、不利益等について一切の責任を負いません。",
  tncA21l3:
    "会員は、個人情報を他の会員に提供する場合、本条の内容を十分理解し同意した上で行うものとします。",
  tncA21l4:
    "当社は、次の場合を除き、第三者に対して、会員の個人情報を開示しません。",
  "tncA21l4-1": "(1)法令に基づき開示が必要であるとき",
  "tncA21l4-2":
    "(2)人の生命、身体、財産又は当社、当社が提供する本サービスの保護等のために必要があるとき",
  "tncA21l4-3":
    "(3)公衆衛生の向上又は児童の健全な育成のために特に必要があるとき",
  "tncA21l4-4":
    "(4)国の機関、地方公共団体又はそれらの委託を受けた者が法令の定める事務を遂行することに対して協力をする必要があるとき",
  "tncA21l4-5":
    "(5)合併その他の事由により本サービスの提供主体が変更され、本サービスの継続のために個人情報等を移管する必要があると当社が判断したとき",
  "tncA21l4-6":
    "(6)本サービスの質の向上、当社の関連事業の開発、当社の提携企業のマーケティング等の目的で個人情報を集計、分析等するとき",
  tncA22: "第22条（第三者の提供するサービス）",
  tncA22l1:
    "本サービスには、第三者のアプリケーションやウェブサイト（以下「第三者提供サービス」という。）を含むことがあります。これらは当社の管理下にないため、当社はそれらについて責任を負いません。会員は、第三者提供サービスが定める規約やポリシーを確認し、自己の責任で使用するものとします。",
  tncA22l2:
    "第1項にかかわらず、当社は、第三者提供サービスを契約する前に、慎重に検討します。",
  tncA22l3p1:
    "本人確認のためのサービスを提供している会社は、Sum and Substance Ltd (UK) Data Protection Registration Number: ZA222205、会社登録番号：09688671、住所: 30 St.Mary Axe, London, Englandとなります。会員として本サービスの利用を希望する者は、第三者が提供するサービスの利用規約、クッキーポリシー及びプライバシー通知を確認し、承諾するものとします。",
  tncA22l3p2: "利用規約",
  tncA22l3p3: "https://sumsub.com/terms-of-use/",
  tncA22l3p4: "クッキーポリシー",
  tncA22l3p5: "https://sumsub.com/cookie-policy/",
  tncA22l3p6: "プライバシー通知",
  tncA22l3p7: "https://sumsub.com/privacy-notice/",
  tncA22l4:
    "会員が本サービスの利用を開始した場合、本規約を承諾するとともに、第三者の規約及びポリシーを承諾したとみなされます。",
  tncA23: "第23条（禁止事項）",
  tncA23l1:
    "会員は、本サービスの利用に関連して、次の各号に定める行為又はそのおそれのある行為をしないものとします。",
  "tncA23l1-1": "(1)法令、本規約、取引ガイド等に違反する行為",
  "tncA23l1-2": "(2)公序良俗に反する行為",
  "tncA23l1-3": "(3)当社による本サービスの運営を妨げる行為",
  "tncA23l1-4": "(4)詐欺等の犯罪に結びつく行為",
  "tncA23l1-5": "(5)当社への申告事項に関し虚偽又は不正確な情報を入力する行為",
  "tncA23l1-6":
    "(6)フレームの値上りの保証、共同購入によるフレームの購入を呼び掛ける行為、また、共同売却によるフレームの売却を呼び掛ける行為",
  "tncA23l1-7":
    "(7)フレームの値上り又は値下りについて、他の会員に予断を抱かせる行為",
  "tncA23l1-8":
    "(8)他の会員にフレームの相場、価格等について誤解を抱かせる行為、また、当該誤解に基づきフレームの売買を誘引する行為",
  "tncA23l1-9":
    "(9)フレームの相場、価格等について、取引の実勢とかけ離れた状況を作出する行為",
  "tncA23l1-10":
    "(10)フレームの売買の条件又は本サービス外での特定のサービス又は物品の提供の条件として、会員によるフレームの売買を誘引する行為",
  "tncA23l1-11":
    "(11)当社、他の会員、その他の第三者の権利又は利益を侵害する行為",
  "tncA23l1-12": "(12)当社、他の会員、その他の第三者の名誉棄損、誹謗中傷等",
  "tncA23l1-13":
    "(13)他人へのなりすまし又は他人のユーザーIDを利用して本サービスを利用する行為",
  "tncA23l1-14":
    "(14)倫理的に問題がある低俗、有害、下品な行為、他人に嫌悪感を与える内容、ポルノ、売春、風俗営業、大麻その他のいわゆるドラッグ類、これらに関連する内容の情報を掲載する行為",
  "tncA23l1-15": "(15)マネーロンダリングを目的とした行為",
  "tncA23l1-16": "(16)売買春、性的サービス等をもちかけたり、要求したりする行為",
  "tncA23l1-17": "(17)賭博に本サービスを利用する行為",
  "tncA23l1-18": "(18)当社、他の会員、その他の第三者に誤解や混乱を与える行為",
  "tncA23l1-19": "(19)当社、他の会員、その他の第三者に迷惑や不快感を与える行為",
  "tncA23l1-20":
    "(20)民族差別、人種差別その他の差別を意図する又は誘発するおそれのある行為",
  "tncA23l1-21":
    "(21)勧誘行為（マルチビジネス、情報商材等の販売やメンバーを募集する行為を含む。）",
  "tncA23l1-22":
    "(22)選挙活動に関する行為又は公職選挙法、政治資金規正法その他の政治活動・選挙に関連する法令に違反する行為",
  "tncA23l1-23":
    "(23)コンピューターウィルスの送信等、コンピュータ機器、通信回線、ソフトウェア等の機能に悪影響を及ぼす行為",
  "tncA23l1-24": "(24)本サービスに関連するシステムへの不正アクセス",
  "tncA23l1-25": "(25)他人を代行したフレームの売買等",
  "tncA23l1-26": "(26)第28条の確約に反する行為",
  "tncA23l1-27":
    "(27)上記各号のいずれかに該当する行為又はそのおそれのある行為を、他の会員その他の第三者と、共同で実行し、その実行について協議、検討し、又は実行の準備をする行為（本サービス上だけでなく、他社の運営するSNS上でのコミュニケーション等も含む。）",
  "tncA23l1-28": "(28)その他当社が不適切であると判断する行為",
  tncA23l2:
    "前項に定める当社の措置は、本サービス利用者保護のために必要であると当社が判断する一切の措置を指し、次の全部又は一部の措置をとることができます。なお、当社は、会員保護の観点から必要な範囲で、違反行為をした会員に限らず、全会員に対して必要な措置をとることができます。",
  "tncA23l2-1": "(1)会員資格の制限、停止、取消し",
  "tncA23l2-2": "(2)本サービスの利用停止",
  "tncA23l2-3":
    "(3)フレームの売買の制限、停止、取消し（売買予約の取消しを含む。）、フレームの無効化",
  "tncA23l2-4": "(4)本サービス上での違反行為及びその疑いに関する公表",
  "tncA23l2-5": "(5)第7条に定める措置",
  "tncA23l2-6":
    "(6)その他当社が本サービスの会員保護のために必要又は有益と判断する一切の措置",
  tncA24: "第24条（税務上の取り扱い）",
  "tncA24l1":
    "会員は、本サービスの利用に関連して課税される場合があること（将来の法改正により本サービス上での取引が課税対象となることを含む。）を理解し、各種法令を遵守の上で本サービスを利用してください。会員は、課税の有無について自ら法令を確認の上で対応するものとします。 また、当社は、会員が課税対象となるかを含む税務事項に関する助言等の行為を一切行いません。",
  "tncA24l2":
    "出品コレクターは、本作品やそのフレームの取引に関して消費税等が課税される場合、自己の責任と費用負担で、法令に従って納税その他の手続をするものとします。",
  "tncA24l3":
    "税務について疑義が生じた場合、会員は自己の責任で判断するか、専門家に相談することが求められます。",
  tncA25: "第25条（当社の責任）",
  "tncA25l1":
    "当社は、当社の故意又は重過失に基づく場合を除き、会員が本サービスを利用すること、又は利用できなかったことによって、会員に損害が発生したときも、いかなる責任も負いません。",
  "tncA25l2":
    "当社は、出品希望コレクターによる出品、フレームの購入及びフレームの会員間取引の場を提供、運営するものであり、各会員は、自己の判断でフレームを購入してください。会員間のフレームの取引の成立について当社は何らの保証をしません。",
  "tncA25l3":
    "本サービスに関連して、出品コレクターと会員、又はフレームの取引当事者等、会員間で生じたトラブルに関しては、会員の責任で処理、解決するものとし、当社は、このようなトラブルについて、一切関与せず、責任を負いません。",
  "tncA25l4":
    "当社は、いかなる理由でも、会員によるフレームの売買の実行、不実行若しくは取引の不成立又はフレームの会員間取引から生じた損害については、当社の故意又は重過失に基づく場合を除き、一切の責任を負いません。",
  "tncA25l5":
    "その他当社は、会員が事業者の場合、当社の故意又は重過失に基づくときを除き、一切の責任を負いません。",
  tncA26: "第26条（消費者に対する責任）",
  tncA26p1:
    "当社は、会員が消費者契約法に定める「消費者」に該当し、かつ、会員に発生した損害が当社の債務不履行又は不法行為に基づく場合、当社は、会員が直接被った通常損害の範囲で責任を負い、また、当社の会員に対する損害の賠償額は、当該会員に発生した損害の原因が生じた時点から遡って過去３か月の期間に会員から当社が現実に受領した各手数料（フレームの対価を除く。）及び決済代行手数料の合計金額相当額を上限とします。ただし、当社の故意又は重過失によるときを除きます。",
  tncA27: "第27条（会員の責任）",
  tncA27l1:
    "会員は、本規約等に違反し、又は故意若しくは過失により、当社又は第三者に損害を与えた場合、損害を賠償するものとします。",
  tncA27l2:
    "会員は、他の会員との間に、トラブル、紛争等が生じた場合、自己の責任と費用負担でこれを解決するものとします。当社は、会員間のトラブル、紛争等に関与、解決する等の義務を負わず、会員は、当社に対して、トラブル、紛争等への関与、解決等を要求しないものとします。ただし、当社は、本サービスの円滑な運営のために必要であると当社が判断した場合、会員同士の紛争に介入することができます。",
  tncA28: "第28条（本サービスの停止又は中断）",
  tncA28p1:
    "当社は、次の各号のいずれかに該当する場合、会員に事前にお知らせすることなく、本サービスの全部又は一部を停止したり、中断したりすることができます。",
  tncA28l1:
    "(1)サーバー、通信回線その他の設備の故障、障害その他の理由により、本サービスの提供が不可能又は困難となったとき",
  tncA28l2:
    "(2)システム（サーバー、通信回線、電源、収容する建築物等を含む。）の保守、点検、修理、変更等を定期的又は緊急に行うとき",
  tncA28l3: "(3)火災、停電等により、本サービスの提供ができなくなったとき",
  tncA28l4:
    "(4)地震、噴火、洪水、津波等の天災により、本サービスの提供が不可能又は困難となったとき",
  tncA28l5:
    "(5)戦争、変乱、暴動、テロ行為、騒乱、労働争議その他の不可抗力により、本サービスの提供ができなくなったとき",
  tncA28l6:
    "(6)法令又はこれに基づく措置により、本サービスの提供が不可能又は困難となったとき",
  tncA28l7:
    "(7)本サービスと連携している外部SNSサービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じたとき",
  tncA28l8:
    "(8)その他運用上又は技術上当社が本サービスの一時的な中断を必要と判断したとき",
  tncA29: "第29条（本サービスの提供終了時の取扱い）",
  tncA29l1:
    "当社は、本サービスの運営状況、前条（本サービスの停止又は中断）の事由、その他理由を問わず、本サービスを終了できます。",
  tncA29l2:
    "当社は、本サービスの提供を終了する場合、会員に対して、本サービスの提供の終了をお知らせします。",
  tncA29l3:
    "当社が本サービスの提供を終了した場合、全ての会員と出品コレクターの情報は、当社が適当と判断する一定の猶予期間後に消去されます。ただし、当社は、必要な限度で上記の情報を保持することがあります。",
  tncA29l4:
    "会員が保有するフレームは、本サービスの提供の終了後６か月の経過をもって消滅します。",
  tncA29l5:
    "前項の規定にかかわらず、当社は、本サービスの提供の終了後６か月が経過するまで、本サービスにおいて会員がフレームを保有中の各本作品について、第18条に規定する本作品の売却とフレームの買戻しに努めます。",
  tncA30: "第30条（反社会的勢力の排除）",
  tncA30p1: "会員は、本サービスの利用期間中、次の事項を確約します。",
  tncA30l1: "(1)反社会的勢力に該当しないこと、かつ、将来にわたり該当しないこと",
  tncA30l2:
    "(2)自ら又は第三者を利用して、当社に暴力的な要求行為、法的な責任を超えた不当な要求行為をしないこと",
  tncA30l3:
    "(3)自ら又は第三者を利用して、風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損したり、当社の業務を妨害したりする行為をしないこと",
  tncA30l4:
    "(4)自ら又は第三者を利用して、取引に関して脅迫的な言動をしたり、暴力を用いたりしないこと",
  tncA30l5: "(5)その他前各号に準じる行為をしないこと",
  tncA31: "第31条（知的財産権等）",
  tncA31l1:
    "本サービス上のコンテンツに関する著作権（著作権法第27条及び第28条に定める権利を含む。以下同じ。）、特許権、実用新案権、商標権、意匠権その他の一切の知的財産権（それらの権利を取得する又はそれらの権利につき登録等を出願する権利を含む。）は、当社又は当社が指定する第三者に帰属します。会員は当社及び当社に対して使用許諾をしている第三者の許諾を得た場合又は著作権法に定める私的使用の範囲で使用する場合を除き、当社の知的財産権を自ら使用若しくは開示し、又は第三者に使用させたりすることはできません。",
  tncA31l2:
    "当社は、会員が本サービス上に掲載したコンテンツ及び優待の提供の際に当社が撮影又は録画したコンテンツを、本サービスの宣伝、運営等を目的として、無償かつ自由に利用できます。",
  tncA31l3:
    "会員が本サービスに掲載したコンテンツに関する一切の責任は、当該会員が負い、当社は、その内容、品質、真実性、正確性、妥当性、適法性、第三者の権利侵害の有無等について、確認いたしません。また、当社は、それらに関して、一切保証せず、一切責任を負いません。",
  tncA31l4:
    "会員は、他の会員が発信又は掲載するコンテンツの内容、品質、真実性、正確性、妥当性、適法性等を、会員自身で判断するものとします。また、会員は、当該コンテンツについて、著作権その他の権利を侵害する行為又はそのおそれのある行為をしないものとします。",
  tncA31l5:
    "当社は、会員が本規約の違反その他の不適切な行為を行ったと当社が判断した場合、当該会員が本サービス上に掲載したコンテンツを、事前のお知らせなく直ちに変更したり、削除したりすることができます。",
  tncA32: "第32条（会員へのご連絡）",
  tncA32p1:
    "当社は、本サービスに関連して、個々の会員にご連絡する場合、会員登録情報の電子メールアドレス、住所又は電話番号に対し、ご連絡するものとします。",
  tncA33: "第33条（分離可能性）",
  tncA33p1:
    "本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合も、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して効力を有します。",
  tncA34: "第34条（事業譲渡）",
  tncA34p1:
    "当社は、本サービスに関する事業を第三者に譲渡した場合、その事業譲渡に伴い本規約上の当社の地位、本規約に基づく権利及び義務、その他の個人情報を事業譲渡の譲受人に譲渡することができ、会員はこのような譲渡について同意します。",
  tncA35: "第35条（本サービス及び本規約の変更）",
  tncA35l1:
    "当社は、いつでも本サービス及び本規約の内容を変更することができます。",
  tncA35l2:
    "当社は、会員の一般の利益に適合する本規約の変更の場合、会員に対して、効力発生時期、変更内容をお知らせすることにより、本規約等を変更することができます。",
  tncA35l3:
    "当社は、会員に不利益を含む本規約の変更をする場合、会員に対して、事前に合理的な事前周知期間を設け、効力発生時期、変更内容をお知らせするものとします。",
  tncA35l4:
    "会員は、本サービス又は本規約の変更等の以降に本サービスを利用した場合、本サービス又は本規約の変更等に同意したとみなされます。",
  tncA36: "第36条（言語）",
  tncA36p1:
    "当社ウェブサイト及び本規約は、日本語以外に2つの言語（英語、スペイン語）で提供されています。異なる言語のバージョン間で矛盾が生じる場合、本規約の言語にかかわらず、日本語版が優先します。",
  tncA37: "第37条（準拠法）",
  tncA37l1:
    "本規約は、抵触法の原則にかかわらず、日本法に準拠し、日本法に従って解釈します。",
  tncA37l2:
    "本規約については、国際物品売買契約に関する国際連合条約の各条項の適用をしません。",
  tncA38: "第38条（合意管轄）",
  tncA38p1:
    "本サービスに起因又は関連して当社と会員との間で生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。",
  tncA39: "第39条（お問い合わせ）",
  tncA39p1:
    "会員は、当社に対してお問い合わせがある場合、当社ウェブサイト又はアプリケーション内の適宜の場所への掲載、その他当社所定の方法によりお問い合わせください。",
  tncDate: "制定日：2022年5月25日",
};

module.exports = tnc;

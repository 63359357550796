const alert = {
  areYouSure: 'Are you sure?',
  ok: 'Ok',
  cancel: 'Cancel',
  serverBusy: 'Our system is busy, please try again', 
  max: 'Max',
  characters: 'characters',
  fieldRequired: 'Required field',
  verifyEmailMsg: 'Please verify your email',
  resendEmail: 'resend email',
  valueGT0: 'You must enter a value greater than 0',
  contactThanks:'Thank you for contacting us!',
  resetPwdEmail:'Password reset email sent',
  successfulRegister:'Successfully registered!',
  verificationEmailSent:'We have sent you an email. Please verify your registration by clicking on the confirmation link.',
  contactConfirmation:'We have received your message. You will receive an answer shortly. ',
  contactPrerelease:'We will send you an email once we go live',
  maintenanceHeader: 'We are under maintenance',
  underMaintenance: 'We are improving a few things in our platform, please try again later'
};

module.exports = alert;

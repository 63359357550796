const summary = {
  invitations: 'Invitaciones',
  invitationsLeft: 'invitaciones disponibles',
  invitationMessage: 'Envía invitaciones por correo electrónico',
  invitationSent: 'Tu invitacion se ha enviado con éxito',
  invitationError: 'Hubo un error mandando la invitación',
  myAccount: 'Mi Cuenta',
  verify: 'Verificar',
  usernameTaken: "Nomber de usuario no disponible",
  identityVerificationNotice: '<p>¡Se ha completado el registro en NOA! Ya puedes comprar frames.</p><p>Los pasos siguientes no son obligatorios, sin embargo necesitarás verificar tu cuenta para acceder al “Mercado de Frames” (disponible próximamente).</p>',
  certificate: 'Certificado',
  verified: 'Verificado',
  notVerified: 'No verificado',
  notVerifiedNotice: 'Para tener acceso a todos nuestros servicios debes terminar la verificación de identidad',
  verificationPendingNotice: 'Tu identidad esta siendo verificada. Puedes empezar a comprar, pero para tener acceso a todos nuestros servicios la verificación debe finalizar.',
  identityVerification: 'Verificación de identidad',
  identity: 'Identidad',
  personal: 'Personal',
  address: 'Dirección',
  verification: 'Verificación',
  subscribeMailingList: 'Avisadme de nuevas obras, eventos NOA y noticias',
  eventsNotAvailable: 'No hay eventos organizados',
  profile: 'Perfil',
  collection: 'Colección',
  transactions: 'Transacciones',
  editInfo: 'Editar',
  myInfo: 'Mis datos',
  firstName: 'Nombre',
  lastName: 'Apellido',
  nameKana: 'Nombre (kana)',
  surnameKana: 'Apellido (kana)',
  phoneNumber: 'Teléfono',
  birthdate: 'Fecha de Nacimiento',
  addressLine1: 'Dirección 1',
  addressLine2: 'Dirección 2',
  city: 'Ciudad',
  region: 'Area',
  postcode: 'Código Postal',
  country: 'País',
  sameAddressBilling: 'Usar esta dirección para facturación',
  proofOfIdentity: 'Prueba de Identidad',
  profilePhoto: 'Foto de Perfil',
  myPM: 'Mis Métodos de Pago',
  addPM: 'Añadir Método de Pago',
  addCard: 'Añadir Tarjeta',
  noPM: 'No hay métodos de pago registrados',
  choosePM: 'Elija un método de pago',
  myShippingAddress: 'Direcciones de Envío',
  noAddresses: 'No hay direcciones registradas',
  addNew: 'Añadir',
  cancel: 'Cancelar',
  submit: 'Enviar',
  myCollection: 'Mi Colección',
  marketPrice: 'Precio de Mercado',
  totalWork: 'Total de Obra',
  change: 'Cambio',
  ownership: 'Propiedad',
  balance: 'Balance',
  evolutionPrice: 'Evolución del Precio',
  download: 'Descargar',
  seeFrames: 'Ver Frames',
  sell: 'Vender',
  paid: 'Pago',
  noSellNotice1: 'La venta sera activada una vez',
  noSellNotice2: 'se haya vendido toda la obra',
  notFound404: 'La URL solicitada no se encontró en este servidor ',
  anError: '404. Hay un error',
  ownedFrames:'En Propiedad',
  ownedPct:'Porcentaje',
  workFrames:'Total Frames',
 };
  
 module.exports = summary; 
const certificate = {
    certificateOfOwnership: 'Certificado de Propiedad',
    author: 'Autor',
    title: 'Obra',
    certifyThat: 'Este documento certifica que el coleccionista de frames',
    proudOwner: 'es el propietario de ',
    equating: 'equivalente a ',
    ofTotalWork: 'del total de la obra',
    tokyo: 'Tokio',
    japan: 'Japón',
    date: 'Fecha',
    close: 'Cerrar',
}

module.exports = certificate;
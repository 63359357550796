const customerService = {
  support: 'Soporte',
  customerService: "Atención al Cliente",
  ticketList: "Lista de casos",
  sureDeleteTicket: "¿Seguro que quieres borrar el ticket?",
  enquiryType: "Tipo de caso",
  selectTicketType: "Selecciona un tipo",
  issue: "Caso",
  description: "Descripción",
  pending: "Pendiente",
  resolved: "Resuelto",
  noTicketsPending: "No hay casos pendientes",
  noPreviousTickets: "No hay casos",
  generalEnquiry: 'General',
  accountAndBilling: 'Mi cuenta y facturación',
};

module.exports = customerService;

<template>
  <header class="mainheader">
    <div class="mainheader__content">
      <!--TODO: deshabilitar cart-header--disabled cuando haya algo en el carrito -->
      <router-link
        v-if="$route.name ? $route.name.includes('shop') : false"
        to="/shop/cart"
        class="cart-header mr-4"
        :class="{
          'cart-header--disabled': $store.state.cartItems.length === 0,
        }"
      >
        <v-icon color="primary" size="40"> mdi-cart-outline </v-icon>
        <div
          v-if="$store.state.cartItems.length > 0"
          class="cart-header__quantity"
          :class="{ itemAdded: itemAdded }"
        >
          {{ $store.state.cartItems.length }}
        </div>
      </router-link>

      <!--button-burguer-->
      <div
        v-if="!$store.state.isLoggedIn"
        role="button"
        class="button-burguer"
        :class="{ open: mobileMenuOpen }"
        @click="toggleMobileMenu"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <!--fin button-burguer-->

      <button
        v-else
        class="user-menu__buddyLoginMobile"
        type="button"
        @click="toggleMobileMenu"
      >
        <v-icon
          class="u-color-primary"
          size="48"
        >
          mdi-account-circle
        </v-icon>
      </button>

      <router-link
        :to="{ name: 'home-how', params: { lang: $store.state.lang } }"
        class="mainheader__logo"
      >
        <!-- <h1>NEO ONE ART</h1> -->
        <img src="../../assets/main-logo.svg" alt="NEO ONE ART" />
      </router-link>
      <div
        class="mainheader__menus"
        :class="{ 'mainheader__menus--open': mobileMenuOpen }"
      >
        <nav class="main-menu">
          <ul>
            <li v-for="(item, index) in items" :key="index">
              <a v-if="item.title === 'store'" :href="item.link" target="_blank">
                {{ $t('store') }}
              </a>
              <router-link
                v-else
                :to="{ params: { lang: $store.state.lang }, name: item.link }"
                @click.native="closeAllMenus"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="user-menu" v-click-outside="onClickOutside">
          <button
            class="user-menu__buddy"
            type="button"
            @click="toggleUserMenu"
          >
            <v-icon
              :class="{
                'u-color-primary': $store.state.isLoggedIn,
                'u-color-grey': !$store.state.isLoggedIn,
              }"
              size="48"
            >
              mdi-account-circle
            </v-icon>
          </button>
          <div
            class="user-menu__lists"
            :class="{ 'user-menu__lists--open': userMenu }"
          >
            <ul class="user-menu__languages">
              <li v-for="(language, index) in languages" :key="index">
                <button
                  v-if="language.iso !== $store.state.lang"
                  @click="changeLang(language.iso)"
                  type="button"
                >
                  {{ language.name }}
                </button>
              </li>
            </ul>
            <ul v-if="$store.state.isLoggedIn" class="user-menu__list">
              <li v-for="(item, index) in menuItemsLoggedIn" :key="index">
                <router-link
                  class="user-menu__link"
                  :to="{
                    params: { lang: $store.state.lang },
                    name: item.link,
                    params: item.params,
                  }"
                  @click.native="closeAllMenus"
                >
                  {{ item.title }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="user-menu__link"
                  :to="{
                    name: 'artwork',
                    params: { lang: $store.state.lang },
                  }"
                  @click.native="logout"
                >
                  {{ $t("logOut") }}
                </router-link>
              </li>
            </ul>
            <ul v-else class="user-menu__list">
              <li v-for="(item, index) in menuItemsLoggedOut" :key="index">
                <router-link
                  class="user-menu__link"
                  :to="{ params: { lang: $store.state.lang }, name: item.link }"
                  @click.native="closeAllMenus"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import firebase from "firebase/compat/app";
// import { i18n } from "@/plugins/i18n";

export default {
  name: "MainHeader",
  data: () => ({
    bodyBlocked: false,
    menuItemsNotVerified: [
      { title: "Summary", link: "summary", params: { tabId: "profile" } },
    ],
    userMenu: false,
    mobileMenuOpen: false,
    languages: [
      { name: "EN", iso: "en" },
      { name: "日本", iso: "ja" },
      { name: "ES", iso: "es" },
    ],
    itemAdded: false,
  }),
  computed: {
    count() {
      return this.$store.state.cartItems.length;
    },
    items() {
      return [
        {
          title: this.$t("workOfArt"),
          link: `artwork`,
        },
        // {
        //   title: this.$t("emergingArtists"),
        //   link: `emerging`,
        // },
        // { title: 'Collectors', link: '/collectors' },
        {
          title: this.$t("aboutUs"),
          link: `company`,
        },
        {
          title: this.$t("contactUs"),
          link: `contact`,
        },
        {
          title: this.$t("events"),
          link: "account.events.public",
          params: { lang: this.$store.state.lang },
        },
        // {
        //   title: 'store',
        //   link: 'https://store.neoone.art'
        // },
      ];
    },
    menuItemsLoggedIn() {
      return [
        {
          title: this.$t("myAccount"),
          link: `summary`,
          params: { lang: this.$store.state.lang, tabId: "profile" },
        },
        {
          title: this.$t("privateEvents"),
          link: "account.events.private",
          params: { lang: this.$store.state.lang },
        },
        {
          title: this.$t("support"),
          link: "account.tickets",
          params: { lang: this.$store.state.lang },
        },
      ];
    },
    menuItemsLoggedOut() {
      return [
        {
          title: this.$t("register"),
          link: `invite`,
        },
        { title: this.$t("logIn"), link: `login` },
      ];
    },
  },
  watch: {
    count() {
      this.itemAdded = true;
    },
    $route() {
      this.itemAdded = false;
    },
  },
  methods: {
    logout() {
      try {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$store.commit("loggedOut");
          })
          .catch((error) => {
            alert(error.message);
          });
        this.closeAllMenus();
      } catch (e) {
        console.log("logging out error ", e);
      }
    },
    toggleUserMenu() {
      this.userMenu = !this.userMenu;
    },
    closeAllMenus() {
      this.userMenu = false;
      this.mobileMenuOpen = false;
    },
    toggleMobileMenu() {
      // Changing language triggers a route change which switches the blocking status
      // Using component state as the reference.
      if (!this.mobileMenuOpen) this.$store.dispatch("blockBody");
      else this.$store.dispatch("releaseBody");    
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    changeLang(locale) {
      document.querySelector("html").setAttribute("lang", locale);
      // i18n.locale = locale;
      // this.$vuetify.lang.current = locale;
      this.$store.commit("CHANGE_LANG", locale);
      this.$router
        .replace({ params: { lang: this.$store.state.lang } })
        .catch(() => {});
    },
    onClickOutside() {
      this.userMenu = false;
    },
  },
};
</script>
<style lang="scss">
.mainheader ~ main {
  margin-top: var(--main-header-height);
}
</style>

<style lang="scss" scoped>
.mainheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: var(--main-header-height);
  background: var(--color-white);
  display: flex;
  z-index: 10;
  box-shadow: var(--shadow1);

  &__content {
    flex-grow: 1;
    max-width: var(--main-width-container);
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      justify-content: space-between;
    }
    @media only screen and (min-width: 1024px) {
      justify-content: flex-end;
    }
  }
  &__logo {
    order: -1;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    @media only screen and (min-width: 768px) {
      flex-grow: 0;
      margin-right: auto;
    }
    img {
      width: 180px;
      height: 28.81px;
      @media only screen and (min-width: 1200px) {
        width: 238px;
        height: 38.1px;
      }
      flex-grow: 0;
    }
  }
  &__menus {
    display: none;
    &--open {
      @media only screen and (max-width: 767px) {
        display: block;
        position: fixed;
        left: 0;
        top: var(--main-header-height);
        background: var(--color-white);
        width: 100%;
        height: calc(100vh - var(--main-header-height));
        overflow: auto;
      }
    }
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      flex-grow: 1;
    }
  }
}
.main-menu {
  flex-grow: 1;
  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  ul {
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      margin: 0 -32 px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  li {
    margin: 0 16px;
    font-weight: 600;
    a {
      display: block;
      padding: 12px 12px;
      color: var(--color-black);
      &:hover,
      &.router-link-exact-active {
        color: var(--color-primary);
      }
      @media only screen and (min-width: 768px) {
        padding: 0;
      }
    }
  }
}
.user-menu {
  position: relative;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
  &__buddy {
    display: none;
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }
  &__buddyLoginMobile {
    @media only screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  &__lists {
    @media only screen and (min-width: 768px) {
      display: none;
      &--open {
        position: absolute;
        right: 0;
        top: 100%;
        display: flex;
        flex-direction: column;
        width: 205px;
        background-color: var(--color-white);
        box-shadow: var(--shadow1);
        border-radius: 0 0 4px 4px;
      }
    }
  }
  &__languages {
    display: flex;
    padding: 12px 24px 16px;

    border-bottom: 1px solid var(--color-grey);
    @media only screen and (max-width: 767px) {
      padding-bottom: 16px;
      border-top: 1px solid var(--color-grey);
    }
    @media only screen and (min-width: 768px) {
      justify-content: center;
      li:empty {
        display: none;
      }
    }
    button {
      margin-right: 8px;
      padding-right: 8px;
    }
  }
  &__link {
    display: block;
    height: 100%;
    padding: 12px 24px;
    color: var(--color-black);
    font-weight: 600;
    &:hover {
      background-color: var(--color-grey-light);
      color: var(--color-primary);
    }

    &.router-link-exact-active {
      color: var(--color-primary);
    }
  }
}
.cart-header {
  position: relative;
  &--disabled {
    pointer-events: none;
  }
  &__quantity {
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    transform: scale3d(1, 1, 1);
  }
  .itemAdded {
    animation: itemAdded 0.5s linear;
  }
  @keyframes itemAdded {
    0% {
      transform: scale3d(0, 0, 0);
    }
    50% {
      transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
}

// BURGER ANIMATION

.button-burguer {
  width: 38px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
.button-burguer span {
  display: block;
  position: absolute;
  height: var(--height-burguer-item);
  width: 50%;
  background: var(--color-black);
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.button-burguer span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.button-burguer span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

.button-burguer span:nth-child(1),
.button-burguer span:nth-child(2) {
  top: 0;
}

.button-burguer span:nth-child(3),
.button-burguer span:nth-child(4) {
  top: calc(50% - calc(var(--height-burguer-item) / 2));
}

.button-burguer span:nth-child(5),
.button-burguer span:nth-child(6) {
  top: calc(100% - var(--height-burguer-item));
}

// open
.button-burguer.open span:nth-child(1),
.button-burguer.open span:nth-child(6) {
  transform: rotate(45deg);
}

.button-burguer.open span:nth-child(2),
.button-burguer.open span:nth-child(5) {
  transform: rotate(-45deg);
}

.button-burguer.open span:nth-child(1),
.button-burguer.open span:nth-child(2) {
  top: calc(var(--height-burguer-item) + 6px);
}

.button-burguer.open span:nth-child(1),
.button-burguer.open span:nth-child(5) {
  left: 6px;
}

.button-burguer.open span:nth-child(2),
.button-burguer.open span:nth-child(6) {
  left: calc(50% - 5px);
}

.button-burguer.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.button-burguer.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.button-burguer.open span:nth-child(5),
.button-burguer.open span:nth-child(6) {
  top: calc(100% - var(--height-burguer-item) - 6px);
  bottom: calc(100% - var(--height-burguer-item) + 6px);
}
</style>

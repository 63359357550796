const events = {
    privateEvents: 'Eventos Privados',
    upcomingEvents: 'Próximos Eventos',
    pastEvents: 'Eventos Anteriores',
    organizer: 'organiza',
    fee: 'Entrada',
    drinks: 'Consumición',
    theme: 'Tipo de Evento',
    doorsOpen: 'Puertas abren',
    reserve: 'Reservar',
    reserved: 'Reservado',
    onlyNoaUsers: 'Solo para miembros de NOA',
    reserveEventModal: '¿Añadir tu nombre a la lista de invitados?',
    registerEventModal: '¿Añadir tu nombre a la lista de asistentes?',
    unregisterEventModal: '¿Borrar tu nombre de la lista de asistentes?',
    atTheNameOf: 'A nombre de',
    attend: 'Asistir',
    venue: '¿Dónde?',
    date: '¿Cuándo?',
    unregister: 'Borrar',
    guestlist: 'Guestlist',
}

module.exports = events;
const checkout = {
  timeFinishPurchase: 'Tiempo para finalizar su compra',
  abandonCartWarning: 'Si abandona el área de pago puede perder sus frames',
  abandonCartModal: 'Si abandona el área de pago puede perder sus frames',
  summary: 'Sumario',
  tnc: 'TyC',
  payment: 'Pago',
  confirmation: 'Confirmado',
  coordinates: 'Coordenadas',
  notice: 'Aviso',
  paymentSuccess: 'Su pago se ha realizado con éxito',
  backToArtwork: 'Volver a las Obras',
  checkTransactions: 'Ver Transacciones',
  availableSoon: 'Todavia no disponible',
  loseFrames: 'Perder los frames',
  keepBuying: 'Seguir comprando',
  price: 'Precio',
  rowsPerPage: 'Frames por pagina',
  back: 'Atrás',
  next: 'Siguiente',
  pay: 'Pagar',
  frame: 'Frame',
  agreeTC: 'Acepto los',
  termsAndConditions: 'Términos y condiciones',
  frameLimitMsg:
    'Contáctenos si desea realizar una gran adquisición. Pulse "Contacto" para ir al formulario de contacto o "Cancelar" para comprar',
  frameLimitButton: 'Comprar 250 Frames',
  frameOwnershipLimitMsg:
    'frames. En la primera ronda de venta no puede ser propietario de más de un 35% de la obra.',
  youCanOnlyBuy: 'Solo puede comprar',
  processingPayment: 'Procesando pago...',
  generatingFrames: 'Generando frames...',
  selectQuadrant: 'Seleccionar cuadrante',
  previous: 'Anterior',
  tncNotice: 'Al acceder o usar este servicio, está de acuerdo con nuestros terminos y condiciones. Si no está de acuerdo, no tiene permiso para acceder o usar nuestros servicios. Por favor lea los terminos y condiciones.',
  all: 'Todos',
  noDataAvailable: 'No hay datos',
  noFramesAvailable: 'No hay frames disponibles',
  notEnoughFrames: 'No se encontraron suficientes frames, solo se muestra lo que hay disponible',
  thereAreOnly: 'Solo hay',
  available: 'disponibles',
  cantBuyMore: 'Ya no puedes comprar más frames.  En la primera ronda de venta no puede ser propietario de más de un 35% de la obra.'
 };
  
 module.exports = checkout;
  
 
 
 
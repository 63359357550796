<template>
  <div class="main-footer__wrap">
    <div class="main-footer">
      <div class="main-footer__logo">
        <img
          width="85"
          src="@/assets/NOA-logo-square-alpha.png"
          alt="Neo Art"
          style="border: 1px solid; border-color: #eeeeee; padding: 0px 2px"
        />
      </div>
      <div>
        <div class="main-footer__title">{{ $t("aboutNOA") }}</div>
        <ul class="main-footer__links">
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'company' }"
              >{{ $t("operatingCompany") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'how' }"
              >{{ $t("howWeWork") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'employment' }"
              >{{ $t("employment") }}</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <div class="main-footer__title">{{ $t("ourService") }}</div>
        <ul class="main-footer__links">
          <li>
            <a href="https://www.artsy.net/partner/noa-gallery" target="_blank"
              >NOA Gallery
            </a>
          </li>
          <li>
            <router-link
              :to="{
                params: { lang: $store.state.lang },
                name: 'privacy-policy',
              }"
              >{{ $t("privacyPolicy") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                params: { lang: $store.state.lang },
                name: 'terms-and-conditions',
              }"
              >{{ $t("termsAndConditions") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                params: { lang: $store.state.lang },
                name: 'terms-and-conditions-gallery',
              }"
              >{{ $t("termsAndConditionsGallery") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'notation' }"
              >{{ $t("transactionLaw") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                params: { lang: $store.state.lang },
                name: 'notation-old',
              }"
              >{{ $t("antiqueLaw") }}</router-link
            >
          </li>
        </ul>
      </div>
      <div>
        <div class="main-footer__title">{{ $t("support") }}</div>
        <ul class="main-footer__links">
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'faqs' }"
              >{{ $t("faq") }}</router-link
            >
          </li>
          <!-- <li>
            <router-link to="/advices">役立つヒント</router-link>
          </li> -->
          <li>
            <router-link
              :to="{ params: { lang: $store.state.lang }, name: 'contact' }"
              >{{ $t("contactUs") }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="main-footer__social">
        <v-btn
          v-for="item in items"
          :key="item.icon"
          icon
          :href="item.url"
          target="_blank"
        >
          <v-icon color="base" size="24px">
            {{ item.icon }}
          </v-icon>
        </v-btn>
        <!-- <v-btn
        
          icon
          target="_blank"
        >
          <v-icon color="base" size="24px">
            <ArtsyIcon />
          </v-icon>
        </v-btn> -->
        <v-btn
          icon
          href="https://www.artsy.net/partner/noa-gallery?utm_campaign=artsy-embedded-widget&utm_source=volt&utm_medium=embedded-widget"
          target="_blank"
        >
          <img
            src="@/assets/sns/artsy-follow-us-32x32-a.png"
            width="20"
            height="20"
            alt="Follow NOA Gallery on Artsy"
          />
        </v-btn>
      </div>
    </div>
    <div class="footer-text-div">
      <p class="footer-text">{{ $t("footerText1") }}</p>
      <p class="footer-text">{{ $t("footerText2") }}</p>
      <p class="footer-text">{{ $t("footerText3") }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "MainFooter",
  data: () => ({
    items: [
      { icon: "mdi-twitter", url: "https://twitter.com/NeoOneArt" },
      {
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/company/neooneart",
      },
      { icon: "mdi-instagram", url: "https://www.instagram.com/neoone.art/" },
    ],
  }),
};
</script>

<style scoped lang="scss">
.footer-text-div {
  font-size: var(--font-size-super-small);
  text-align: center;
  padding-bottom: 40px;
}
.footer-text {
  margin-bottom: 0px;
}
.main-footer {
  &__wrap {
    border-top: 2px solid var(--color-black);
    background-color: var(--color-creme);
  }

  max-width: 800px;
  margin: 0 auto;
  padding: 40px 8px;
  display: grid;
  grid-gap: 8px;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 120px repeat(3, 1fr);
    grid-gap: 24px;
  }

  &__social {
    grid-column: 1 / -1;
    text-align: center;
  }

  &__title {
    font-weight: 600;
    font-size: var(--font-size-small);
    color: var(--color-white);
  }

  &__links li {
    margin-top: 12px;
    font-size: var(--font-size-extra-small);
  }
}
</style>

const events = {
    privateEvents: 'プライベートイベント',
    upcomingEvents: '次回イベント',
    pastEvents: '以前イベント',
    organizer: '主催者',
    fee: '入場料',
    drinks: 'ドリンク',
    theme: 'テーマ',
    doorsOpen: '開店',
    reserve: '予約する',
    reserved: 'Reserved',
    onlyNoaUsers: 'NOAのメンバーのみです',
    reserveEventModal: '招待者のリストに名前を追加しますか？',
    atTheNameOf: '名前',
    address: '住所',
    attend: '参加',
    venue: '会場場所',
    date: '日付',
    unregister: '削除',
    registerEventModal: '参加名簿のリストへお客様のお名前を追加しますか？',
    unregisterEventModal: '参加名簿のリストからお客様のお名前を削除しますか？',
    guestlist: 'Guestlist',
}

module.exports = events;
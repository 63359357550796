/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from "vue";
import VueRouter from "vue-router";
import routes_lang from "@/routes";

const languages = ['en', 'es', 'ja']

const getValidLanguage  = function () {
  const storage = localStorage.getItem('noa-lang')
  const nav = navigator.language.split('-')[0]
  let lang = storage || nav
  console.log('VALID LANGUAGE ', storage, nav, lang)
  lang = languages.includes(lang) ? lang : 'en'
  
  return lang
}

Vue.use(VueRouter);



const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter(to, from, next) {      
      next(getValidLanguage())
    }
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: routes_lang,
    beforeEnter(to, from, next) {
      let lang = to.params.lang
      if (languages.includes(lang)) {
        return next()
      }
      return next({ path: getValidLanguage() + '/' })
    },
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

Vue.router = router;

export default {
  router,
};

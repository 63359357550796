const certificate = {
    certificateOfOwnership: 'Certificate of Ownership',
    author: 'Author',
    title: 'Title',
    certifyThat: 'This is to certify that the frame collector',
    proudOwner: 'is the proud owner of',
    equating: 'equating to ',
    ofTotalWork: 'of the total work',
    tokyo: 'Tokyo',
    japan: 'Japan',
    date: 'Date',
    close: 'Close',
}

module.exports = certificate;
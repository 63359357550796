const faq = {
  title1: "¿What involces NEO ONE ART's services?",
  body1:
    'NEO ONE ART is a platform aiming to democratise art collection. Anyone registered in our website may buy a "frame" and enjoy the benefits of our community, including:<ol><li>Art Promoter Worldwide: this applies to all our members, since they help us make this project happen, where our final goal is look after an important collection of work of art and historical items, democratizing their ownership between thousands of users.</li><li>Art Collector: all NEO ONE ART\'s community may be a collector, mainly in two different ways: first globally, being part of the art collection managed by NEO ONE ART; and then individually, in which users may exchange between each other using our unique frame system "SCF" (Single Coordinate Frame), where each frame is a unique collectable</li><li>Art Investors: owning a frame from NEO ONE ART entitles to a series of benefits and represents the right of shared-ownership of the work of art it belongs to. In addition, with the SCF system, there may be frames more valuable than others for those whose main goal is creting a collection. This mid-term will become a two-way investment, where the frame\'s price will be set by the work of art managed by NEO ONE ART and by the value that each collector will set depending on its uniqueness</li?</ol>',
  title2: "Is there a membership fee?",
  body2:
    "There is no cost involved in registering with us, and we aim to keep it this way.",
  title3: "How much is a NEO ONE ART frame?",
  body3:
    "In NOA we are committed to democratize art, this is why we believe a frame's price must be affordable to anyone. Our concept is \"one frame, one note\", meaning our starting frame price will never be above ¥1,000. With time, frames may go up in price based on individual attributes, transactions between members or each work of art's revalorization.",

  title4: "Is there a limit on how many frames I can buy?",
  body4:
    "As a NEO ONE ART member, you may buy as many frames as you like out of all frames made available by NOA. When someone owns more than 75% of the total number of frames from the same artwork (paintings, books, sculptures,...), the user will be able to request the full ownership of this object",
  title5: "May I transfer the ownership of my frames to someone else?",
  body5:
    'Of course, your frames belong to you and if you wish you may give them to someone else. The only requirement is that the person receiving the frames is an adult and meets all the requirements to be a NOA registered user. Once both users are in our platform you shall contact our customer service team and they will perform the transaction. There will be a fee applicable to the part giving the frames away, which will be a 7.5% of the latest buying price for each frame. As an example: <ul><li>User "A" buys 20 frames at ¥1,000 each, totalling ¥20,000.</li><li>User "A" wants to give 10 frames away to user "B" and contacts our customer service team.</li><li>NOA\'s customer service will accept the request and user "A" will have to pay 7.5% of those 10 frames, being the fee to pay a total of ¥750',
  title6:
    "Are there additional charges to the purchase price, such as membership fees, maintenance or sales commissions?",
  body6:
    "The sale price of a frame will always be ¥1,000, this amount includes consumption tax equivalent to 10% of the sale price. When users transact with each other, NOA will charge 7.5% of the total value of the transaction. Additionally, the user must pay a 10% consumption tax only on the surcharge. There are no membership fees or charges for maintaining your account.",
  title7: "How many steps are required to buy a frame in NEO ONE ART?",
  body7:
    "<p>To buy a frame at NOA, you must have a user account registered and validated by our support team. Once you have your account created, the following steps are required to purchase a frame:</p><ol><li>Login to our website</li><li>Choose a work of art among those available.</li><li>Select buy and mark the number of frames you want.</li><li>Accept the terms and conditions.</li><li>Enter your payment method (if you have not done so before).</li><li>Confirm your data and accept.</li></ol><br /><p>The purchased frames will appear immediately in your account once the payment is made. In your user profile, you can check which coordinates of the work have been sent by our system, which works randomly thanks to our coordinate algorithm. In addition, you will receive a certificate of co-ownership which confirms your status as a collector.</p>",
};

module.exports = faq;

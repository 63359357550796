<template>
  <div class="static-template">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'StaticTemplate',
};
</script>

<style lang="scss" scoped>
.static-template {
  max-width: 800px;
  margin: 24px auto 24px;
  padding: 0 16px;
  min-height: 450px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 600;
}

p,
ul,
ol {
  margin-top: 16px;
}

ul {
  list-style-type: disc;
  padding-left: 24px;
}

li {
  margin-bottom: 24px;
}

a {
  color: var(--color-primary);
  font-weight: bold;
}
</style>

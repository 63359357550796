const workDetails = {
  description: 'Description',
  year: 'Year',
  material: 'Material',
  size: 'Size',
  provenance: 'Provenance',
  collectors: 'Collectors',
  newsfeed: 'Newsfeed',
  currentPrice: 'Current Price',
  originalPrice: 'Original Price',
  availableUnits: 'Available Units',
  unitPrice: 'Unit Price',
  buy: 'Buy',
  share: 'Share (SNS)',
  frameValue: 'Frame Value',
  total: 'Total',
  japan: 'Japan',
  location: 'Location',
};

module.exports = workDetails;

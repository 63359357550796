const notation = {
    notHeader: 'Notation based on the Law of Specific Commercial Transactions',
    operatingCompany: 'Operating company',
    ocName: 'NEO ONE ART INC.',
    director: 'Director',
    dirName: 'SIMON IGNACIO MONTERO BACHUR',
    address: 'Address',
    addressText: 'Minami-Aoyama 2-2-15, Minato-ku, Tokyo 〒107-0062',
    telephone: 'Telephone',
    noaPhone: '050-5806-5990',
    emailAddress: 'Email address',
    noaEmail: 'contact@neoone.art',
    businessHours: 'Business hours',
    bizHoursText: 'Weekdays 10:00-18:30 Regular holidays Saturdays, Sundays and holidays',
    howToOrder: 'How to order',
    internet: 'Internet',
    aboutSalePrice: 'About the sale price',
    aboutSalePriceText: 'Amount displayed on the transaction screen. If the corporation is the seller, all prices, including taxes, are displayed.',
    necessaryExpenses: 'Necessary expenses other than the consideration Trading commission',
    necessaryExpensesText: '7.5% of the trading value (including taxes)',
    notPaymentMethod: 'Payment method',
    notPaymentMethodText: 'You can pay by credit card and bank transfer.',
    paymentTime: 'Payment time',
    paymentTimeText: 'When the purchase conditions are accepted and the sale is completed.',
    productAvailability: 'Product availability',
    productAvailabilityText: 'Immediately after payment.',
    returnCancellation: 'Return / Cancellation',
    returnCancellationText: 'We do not accept returns or cancellations of successful transactions.'
}

module.exports = notation
const checkout = {
  timeFinishPurchase: '購入時間終了',
  abandonCartWarning:
    'チェックアウト項目を完了しなければ、フレームが失効する可能性があります。',
  abandonCartModal: 'このページから離れると、フレームは自動で失効します。',
  summary: 'サマリー',
  tnc: '利用規約',
  payment: '支払い',
  confirmation: '確認',
  coordinates: 'コーディネート',
  notice: '注意事項',
  paymentSuccess: '支払が完了しました。',
  backToArtwork: 'Work of Artページへ戻る',
  checkTransactions: 'トランザクションを確認する',
  availableSoon: 'Available soon',
  loseFrames: 'フレームを失う',
  keepBuying: '購入を続ける',
  price: '価格',
  rowsPerPage: '１ページに表示するフレーム数',
  back: '戻る',
  next: '次へ',
  pay: '支払う',
  frame: 'フレーム',
  agreeTC: '利用規約に同意する',
  termsAndConditions: '利用規約',
  frameLimitMsg:
    '２５０枠以上をご購入の場合は、お問い合わせください。そのまま問合せ画面に進む場合は、ＯＫをクリックしてください。そうでない場合は、キャンセルをクリックしてください。',
  frameLimitButton: '２５０枠を購入する',
  frameOwnershipLimitMsg:
    '枠のみ購入可能です。ファーストラウンドでは、作品の３５％以上を所有することはできません。',
  youCanOnlyBuy: 'のみ購入可能です',
  processingPayment: '支払い処理中',
  generatingFrames: 'フレーム精査中',
  selectQuadrant: '枠を選択する',
  previous: '前',
  tncNotice: '本サービスにアクセスし、または何らかの方法で使用することにより、ユーザーは本規約に同意したとみなされます。\nユーザーが本規約に同意しない場合、ユーザーは本サービスへのアクセスまたは本サービスのご利用をすることはできません。本規約を注意深くお読みください。',
  all: '全て',
  noDataAvailable: '有効データがありません',
  noFramesAvailable: 'No frames available',
  notEnoughFrames: 'Not enough frames found, showing only what is available',
  thereAreOnly: 'There are only',
  available: 'available',
  cantBuyMore: 'You cannot buy any more frames. In the first round you cannot own more than 35% of the work'
};

module.exports = checkout;

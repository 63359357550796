var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"mainheader"},[_c('div',{staticClass:"mainheader__content"},[(_vm.$route.name ? _vm.$route.name.includes('shop') : false)?_c('router-link',{staticClass:"cart-header mr-4",class:{
        'cart-header--disabled': _vm.$store.state.cartItems.length === 0,
      },attrs:{"to":"/shop/cart"}},[_c('v-icon',{attrs:{"color":"primary","size":"40"}},[_vm._v(" mdi-cart-outline ")]),(_vm.$store.state.cartItems.length > 0)?_c('div',{staticClass:"cart-header__quantity",class:{ itemAdded: _vm.itemAdded }},[_vm._v(" "+_vm._s(_vm.$store.state.cartItems.length)+" ")]):_vm._e()],1):_vm._e(),(!_vm.$store.state.isLoggedIn)?_c('div',{staticClass:"button-burguer",class:{ open: _vm.mobileMenuOpen },attrs:{"role":"button"},on:{"click":_vm.toggleMobileMenu}},[_c('span'),_c('span'),_c('span'),_c('span'),_c('span'),_c('span')]):_c('button',{staticClass:"user-menu__buddyLoginMobile",attrs:{"type":"button"},on:{"click":_vm.toggleMobileMenu}},[_c('v-icon',{staticClass:"u-color-primary",attrs:{"size":"48"}},[_vm._v(" mdi-account-circle ")])],1),_c('router-link',{staticClass:"mainheader__logo",attrs:{"to":{ name: 'home-how', params: { lang: _vm.$store.state.lang } }}},[_c('img',{attrs:{"src":require("../../assets/main-logo.svg"),"alt":"NEO ONE ART"}})]),_c('div',{staticClass:"mainheader__menus",class:{ 'mainheader__menus--open': _vm.mobileMenuOpen }},[_c('nav',{staticClass:"main-menu"},[_c('ul',_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[(item.title === 'store')?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('store'))+" ")]):_c('router-link',{attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: item.link }},nativeOn:{"click":function($event){return _vm.closeAllMenus.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"user-menu"},[_c('button',{staticClass:"user-menu__buddy",attrs:{"type":"button"},on:{"click":_vm.toggleUserMenu}},[_c('v-icon',{class:{
              'u-color-primary': _vm.$store.state.isLoggedIn,
              'u-color-grey': !_vm.$store.state.isLoggedIn,
            },attrs:{"size":"48"}},[_vm._v(" mdi-account-circle ")])],1),_c('div',{staticClass:"user-menu__lists",class:{ 'user-menu__lists--open': _vm.userMenu }},[_c('ul',{staticClass:"user-menu__languages"},_vm._l((_vm.languages),function(language,index){return _c('li',{key:index},[(language.iso !== _vm.$store.state.lang)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.changeLang(language.iso)}}},[_vm._v(" "+_vm._s(language.name)+" ")]):_vm._e()])}),0),(_vm.$store.state.isLoggedIn)?_c('ul',{staticClass:"user-menu__list"},[_vm._l((_vm.menuItemsLoggedIn),function(item,index){return _c('li',{key:index},[_c('router-link',{staticClass:"user-menu__link",attrs:{"to":{
                  params: { lang: _vm.$store.state.lang },
                  name: item.link,
                  params: item.params,
                }},nativeOn:{"click":function($event){return _vm.closeAllMenus.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('li',[_c('router-link',{staticClass:"user-menu__link",attrs:{"to":{
                  name: 'artwork',
                  params: { lang: _vm.$store.state.lang },
                }},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("logOut"))+" ")])],1)],2):_c('ul',{staticClass:"user-menu__list"},_vm._l((_vm.menuItemsLoggedOut),function(item,index){return _c('li',{key:index},[_c('router-link',{staticClass:"user-menu__link",attrs:{"to":{ params: { lang: _vm.$store.state.lang }, name: item.link }},nativeOn:{"click":function($event){return _vm.closeAllMenus.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
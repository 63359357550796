const home = require("@/translations/en/home");
const contactUs = require("@/translations/en/contactUs");
const footer = require("@/translations/en/footer");
const header = require("@/translations/en/header");
const summary = require("@/translations/en/summary");
const workDetails = require("@/translations/en/workDetails");
const howWeWork = require("@/translations/en/howWeWork");
const buyFrames = require("@/translations/en/buyFrames");
const checkout = require("@/translations/en/checkout");
const login = require("@/translations/en/login");
const frameBrowser = require("@/translations/en/frameBrowser");
const company = require("@/translations/en/company");
const alert = require("@/translations/en/alert");
const employment = require("@/translations/en/employment");
const privacypolicy = require("@/translations/en/privacypolicy");
const faq = require("@/translations/en/faq");
const customerservice = require("@/translations/en/customerservice");
const tnc = require("@/translations/en/tnc");
const tncg = require("@/translations/en/tncg");
const notation = require("@/translations/en/notation");
const certificate = require("@/translations/en/certificate");
const emerging = require("@/translations/en/emerging");
const events = require("@/translations/en/events");

const en = {
  ...home,
  ...contactUs,
  ...footer,
  ...header,
  ...summary,
  ...workDetails,
  ...howWeWork,
  ...buyFrames,
  ...checkout,
  ...login,
  ...frameBrowser,
  ...company,
  ...alert,
  ...employment,
  ...privacypolicy,
  ...faq,
  ...customerservice,
  ...tnc,
  ...tncg,
  ...notation,
  ...certificate,
  ...emerging,
  ...events
};

module.exports = en;

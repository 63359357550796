<template>
  <v-app>
    <alert-message
      v-show="$store.state.alertMessage !== ''"
      :text="$store.state.alertMessage"
      @close="closeAlertMessage"
    >
    </alert-message>
    <main-header />
    <v-main
      :class="{ 'u-bgcolor-grey': !hasBackground, 'pattern-bg': hasBackground }"
    >
      <router-view />
    </v-main>
    <main-footer />
  </v-app>
</template>

<script>
import AlertMessage from "@/components/Molecules/AlertMessage";
import MainHeader from "@/components/Organisms/MainHeader";
import MainFooter from "@/components/Organisms/MainFooter";


export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter,
    AlertMessage,
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$store.state.lang,
      },
      meta: [
        { vmid: "description", name: 'description', content: this.$t('hwwSummary1') },
        {
          vmid: "ogurl",
          property: "og:url",
          content: `https://neoone.art`,
        },
        { vmid: "ogtype", property: "og:type", content: "website" },
        {
          vmid: "ogtitle",
          property: "og:title",
          content: `NEO ONE ART - Art without limits, always`,
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: "https://neoone.art/img/neoone-art-logo-square.jpg",
        },
        {
          vmid: "ogdescription",
          property: "og:description",
          content: this.$t("hwwSummary1"),
        },
        {
          vmid: "ogimagesecure_url",
          property: "og:image:secure_url",
          content: "https://neoone.art/img/neoone-art-logo-square.jpg",
        },
        {
          vmid: "ogimagetype",
          property: "og:image:type",
          content: "image/jpeg",
        },

        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: `NEO ONE ART - Art without limits, always`,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.$t("hwwSummary1"),
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: "http://neoone.art/img/neoone-art-logo-square.jpg",
        },
        { vmid: "twitter:card", name: "twitter:card", content: "summary" },
        {
          vmid: "twitter:image:alt",
          name: "twitter:image:alt",
          content: `NEO ONE ART - Art without limits, always`,
        },
      ],
    };
  },

  data: () => ({
    viewWithBackgrounds: [
      "contact",
      "prerelease",
      "about",
      "faqs",
      "how",
      "privacy-policy",
      "notation",
      "notation-old",
      "advices",
      "Login",
      "Register",
      "employment",
      "account.events",
    ],
  }),
  computed: {
    bodyBlocked: {
      get() {
        return this.$store.state.bodyBlocked;
      },
    },
    hasBackground() {
      if (this.viewWithBackgrounds.includes(this.$route.name)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route() {
      if (this.bodyBlocked) {
        this.$store.dispatch("releaseBody");
      }
    },
    bodyBlocked: function () {
      if (this.bodyBlocked) {
        document.querySelector("html").classList.add("html--blocked");
      } else {
        document.querySelector("html").classList.remove("html--blocked");
      }
    },
  },
  methods: {
    closeAlertMessage() {
      this.$store.commit("ALERT_MESSAGE", "");
    },
  },
};
</script>
<style lang="scss">
@import "./sass/main.scss";
// [lang='ja'] .v-application {
//   font-family: 'Noto Serif JP', serif;
// }
</style>

const contactUs = {
  // Contact form
  contactUs: 'お問い合わせ',
  contactTitle:
    'ご不明な点がある場合や詳細については、こちらのフォームからお問い合わせください。',
  name: '氏名',
  email: 'メールアドレス',
  phoneNumber: '電話番号',
  message: 'メッセージ',
  send: '送信',
};

module.exports = contactUs;

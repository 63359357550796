const home = {
  homeTitle1: 'Transforming the way you see',
  requestInvite: 'Request Invite',
  fineArt: 'fine arts',
  everyone: 'everyone',
  benefitsTitle: 'Benefits',
  benefitsIntro: 'Our goal is to promote a community that supports the arts and culture.',
  benefit1Title: 'Collect',
  benefit1Text: 'Own and collect specific areas of paintings (frames) from great artists like Goya, Picasso or Miró. At the same time you will help to preserve the cultural heritage.',
  benefit2Title: 'Socialise',
  benefit2Text: 'Being a frame collector gives you access to private events organised by NOA. See your artwork exhibited while enjoying music and a drink with other collectors.',
  benefit3Title: 'Experience',
  frameBrowserImage: '../assets/home/frame-browser-foujita-en.jpg',
  benefit3Text: 'Gathering different arts in the same place creates a synergy between all our senses. Take the opportunity to experience collaborative arts first hand.',
  investTitle: 'Invest',
  investIntro: 'Even though our main goal is promoting art and culture, it is well known that the value of works of art from famous artists increase over the years. A good example is the work “Portrait of a young man holding a roundel” by Sandro Botticelli, which at the beginning of 2021 was sold in an auction by Shotheby’s for more than 92 million dollars. This same work was sold in 1982 by the inheritors of a private collector for £810.000 (around $975.000, exchange rate as of July 2022). In less than 30 years, it was an impressive revaluation of  “Portrait of a young man holding a roundel” (1). There is guarantee this could happen with all the artwork, however it is something to take into account.',
  investIntro2: 'We enumerate three of the economic benefits related to the purchase of frames:',
  invest1Title:  'Trade',
  invest1Text: 'As a frame collector, you will be able to sell or buy frames from other collectors, and in that way benefit from the revaluation in time of the works of art from our collection.',
  invest2Title: 'Uniqueness',
  invest2Text: 'Since a frame is a unique object, the frame collector may as well benefit from its rarity. Frames that are more appealing to some collectors may be traded at higher prices than in the standar frame market.',
  invest3Title: 'Gateway',
  invest3Text: 'In addition to being a digital representation of a physical asset, a frame may work in other ways within our community. For instance, as a passport to events that we organise during the year and get discounts on products and NOA services or commercial partners. We strive to transform frame collection into a unique experience.',
  investCertificate: '../assets/home/certificate-en.png',
  investRef1: '[1] To know more about the story of this artwork you can visit ',
  and: 'and',
  investRefLink2: 'https://www.theguardian.com/artanddesign/2020/sep/24/true-beauty-for-the-ages-80m-botticelli-to-appear-at-auction',
  investRefLink3: 'https://www.sothebys.com/en/buy/auction/2021/master-paintings-sculpture-part-i/portrait-of-a-young-man-holding-a-roundel',
  investRef2: 'The Guardian',
  investRef3: 'Sotheby\'s',
  from1000: 'from ¥1,000',
  homeTitle2: '',
  homeStep1Title: 'Register and choose a work of art',
  homeStep1Text:
    'This work of art is exclusive to NEO ONE ART users. In our collection we have masterpieces from the best artists in history.',
  homeStep2Title: 'Select how many frames you would like to own',
  homeStep2Text:
    'Frames are assigned randomly. If you purchase 10 or more you may choose an area depending on amount of frames.',
  homeStep3Title: 'Pay by credit card, Apple or Google Pay',
  homeStep3Text:
    'Pay securely using our integration with the Stripe payment gateway.',
  homeStep4Title: 'Become part of a community',
  homeStep4Text:
    'Owning frames gives you access to exclusive events intended to promote art, from concerts to museums.',
  whatIsNext: 'What is Next',
  homeNextTitle:
    'Once all frames from a work of art are sold, you may trade frames with other collectors.',
  homeNextStep1Title: 'Make an offer to someone else’s frames',
  homeNextStep1Text:
    'Buy other frames you may find interesting, collect nearby frames and own whole portions.',
  homeNextStep2Title: 'Put your frames for sales',
  homeNextStep2Text:
    'Sell some of your frames or trade them for frames from other fellow collector.',
  homeBlockchainTitle: 'Secure proof of ownership with distributed ledger',
  weUse: 'We use',
  blockchain: 'blockchain',
  homeBlockchainText:
    'to safely record all our artwork and any transactions between collectors and users.',
  blockchainCard1Title: 'Incorruptible uniqueness of each work of art',
  blockchainCard1Text:
    'Each artwork is recorded as a unique entry in the blockchain network.',
  blockchainCard2Title: 'Unequivocally ownership of every frame',
  blockchainCard2Text:
    'Each fraction is characterised with a smart contract in the blockchain that says what area is owned and by whom.',
  blockchainCard3Title: 'Provenance as an intrinsic property of blockchain',
  blockchainCard3Text:
    'Each of our works of art in our collection has a provenance. The provenance of each frame starts with you and follows all our community of collectors.',
};

module.exports = home;

<template>
  <div class="text-center">
    <div class="login-separator">{{ $t('chooseOption') }}</div>

    <div class="login-thirds">
      <div v-for="(loginThird, index) in loginThirds" :key="index">
        <button
          class="login-thirds__item"
          type="button"
          @click="loginThirdClick(loginThird)"
        >
          <template v-if="loginThird.id === 'google'">
            <!-- prettier-ignore -->
            <svg width="18px" height="18px" viewBox="0 0 48 48"><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></svg>
            <div>{{ $t('continueGoogle') }}</div>
          </template>
          <template v-if="loginThird.id === 'Twitter'">
            <!-- prettier-ignore -->
            <svg height="24" viewBox="0 0 24 24" width="24"><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z" fill="#1D9BF0"></path></svg>
            <div>{{ $t('continueTwitter') }}</div>
          </template>
          <template v-if="loginThird.id === 'Facebook'">
            <!-- prettier-ignore -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"><linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"/><stop offset="1" stop-color="#007ad9"/></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"/><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"/></svg>            <div>{{ $t('continueFacebook') }}</div>
          </template>
        </button>
      </div>
    </div>

    <div class="login-separator">{{ interTitle }}</div>

    <div class="text-center">
      <router-link class="login-thirds-link" :to="path">{{
        linkTitle
      }}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoginThirds',
  props: {
    loginThirds: {
      type: Array,
      required: true,
    },
    interTitle: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    linkTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    loginThirdClick(loginThird) {
      // this.$router.push('/prerelease');
      // loginThird;
      this.$emit('loginThirdClick', loginThird);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-separator {
  position: relative;
  display: inline-flex;
  text-align: center;
  margin: 24px auto 16px;
  padding: 0 16px;
  &:after,
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    height: 1px;
    width: 20px;
    background-color: var(--color-grey);
  }
  &:after {
    left: 100%;
  }
  &:before {
    right: 100%;
  }
}
.login-thirds {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__item {
    border: 1px solid var(--color-grey-dark);
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    box-shadow: var(--shadow1);
    transition: box-shadow 300ms linear;
    &:hover {
      box-shadow: var(--shadow1-over);
    }
    svg {
      width: 24px;
      margin-right: 5px;
    }
  }
}

.login-thirds-link {
  font-weight: bold;
  &:hover {
    color: var(--color-primary);
  }
}
</style>

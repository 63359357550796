const notation = {
    notHeader: 'Notación basada en la Ley de Transacciones Comerciales Específicas',
    operatingCompany: 'Empresa operadora',
    ocName: 'NEO ONE ART INC.',
    director: 'Director',
    dirName: 'SIMÓN IGNACIO MONTERO BACHUR',
    address: 'Dirección',
    addressText: 'Minami-Aoyama 2-2-15, Minato-ku, Tokio 〒107-0062',
    telephone: 'Teléfono',
    noaPhone: '050-5806-5990',
    emailAddress: 'Dirección de correo electrónico',
    noaEmail: 'contact@neoone.art',
    businessHours: 'Horario de atención',
    bizHoursText: 'Laborables 10:00-18:30 Festivos habituales Sábados, domingos y festivos',
    howToOrder: 'Cómo hacer un pedido',
    internet: 'Internet',
    aboutSalePrice: 'Acerca del precio de venta',
    aboutSalePriceText: 'Monto que se muestra en la pantalla de transacción. Si la corporación es el vendedor, se muestran todos los precios, incluidos los impuestos.',
    necessaryExpenses: 'Gastos necesarios distintos de la contraprestación',
    necessaryExpensesText: 'Comisión de negociación: 7,5% del valor de negociación (impuestos incluidos)',
    notPaymentMethod: 'Forma de pago',
    notPaymentMethodText: 'Puede pagar con tarjeta de crédito y transferencia bancaria.',
    paymentTime: 'Tiempo de pago',
    paymentTimeText: 'Cuando se aceptan las condiciones de compra y se completa la venta.',
    productAvailability: 'Disponibilidad del producto',
    productAvailabilityText: 'Inmediatamente después del pago.',
    returnCancellation: 'Devolución / Cancelación',
    returnCancellationText: 'No aceptamos devoluciones o cancelaciones de transacciones exitosas.'
}

module.exports = notation
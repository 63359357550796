import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify, {
  VTooltip,
  VToolbar,
  VCard,
  VIcon,
  VBtn,
  VCheckbox,
  VTextField,
  VSelect,
  VDataTable,
  VDataIterator
} from "vuetify/lib/index.js";

import LineIcon from "@/components/Atoms/LineIcon.vue";

Vue.use(Vuetify, {
  components: {
    VDataIterator,
    VDataTable,
    VTooltip,
    VToolbar,
    VCard,
    VIcon,
    VBtn,
    VCheckbox,
    VTextField,
    VSelect
  },
});

// VBtn Custom
VBtn.options.props.ripple.default = false;
VBtn.options.props.depressed.default = true;

// VCheckbox Custom
VCheckbox.options.props.ripple.default = false;

// VTextField Custom
VTextField.options.props.flat.default = true;
VTextField.options.props.solo.default = true;
VTextField.options.props.dense.default = true;
VTextField.options.props.outlined.default = true;

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      line: {
        // name of our custom icon
        component: LineIcon, // our custom component
      },
    },
  },
  theme: {
    themes: {
      light: {
        base: "#202324",
        primary: "#f2c602",
        secondary: "#b0bec5",
        success: "#2b912b",
        error: "#fc6328",
      },
    },
  },
});

const events = {
    privateEvents: 'Private Events',
    upcomingEvents: 'Upcoming Events',
    pastEvents: 'Past Events',
    organizer: 'organizer',
    fee: 'Fee',
    drinks: 'Drinks',
    theme: 'Theme',
    doorsOpen: 'Doors Open',
    reserve: 'Reserve',
    reserved: 'Reserved',
    onlyNoaUsers: 'Only for NOA members',
    reserveEventModal: 'Add your name to the guestlist?',
    registerEventModal: 'Add your name to the atendee list?',
    unregisterEventModal: 'Remove your name from the atendee list?',
    atTheNameOf: 'At the name of',
    attend: 'Attend',
    venue: 'Where?',
    date: 'When?',
    unregister: 'Unregister',
    guestlist: 'Guestlist',
}

module.exports = events;
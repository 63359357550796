import Vue from 'vue';
import VueI18n from 'vue-i18n';
const en = require('@/translations/en');
const ja = require('@/translations/ja');
const es = require('@/translations/es');

Vue.use(VueI18n);

const messages = {
  ja: ja,
  en: en,
  es: es,
};

const availableLanguages = ['en', 'es', 'ja'];

let defaultLang = localStorage.getItem('noa-lang') || navigator.language.split('-')[0];
defaultLang = availableLanguages.includes(defaultLang) ? defaultLang : 'en';
export const i18n = new VueI18n({
  locale: defaultLang, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

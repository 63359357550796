import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check'; 
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import { config } from '@/helpers/firebaseConfig';
import { configCollectors } from '@/helpers/firebaseConfigCollectors';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';

import 'firebase/compat/database';

const fbapp = firebase.initializeApp(config);




const dbCollectors = firebase.initializeApp(configCollectors,
  'collectors'
);

// const appCheckDefault = firebase.appCheck();
// const appCheckCollectors = firebase.appCheck(dbCollectors);
// // // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // // key is the counterpart to the secret key you set in the Firebase console.
// appCheckDefault.activate(
//   process.env.VUE_APP_APPCHECK_KEY,
//   true
// );

// appCheckCollectors.activate(
//   process.env.VUE_APP_APPCHECK_KEY,
//   true
// );


initializeAppCheck(fbapp, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_APPCHECK_KEY),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

initializeAppCheck(dbCollectors, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_APPCHECK_KEY),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


// utils
const db = firebase.firestore(fbapp)

const rtdb = firebase.database(fbapp);
const rtdbC = firebase.database(dbCollectors);
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
auth.useDeviceLanguage();
// export utils/refs
export { fbapp, db, auth, storage, rtdb, analytics, firebase, rtdbC };

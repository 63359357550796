const notation = {
    notHeader: '特定商取引法に基づく表記',
    operatingCompany: '運営会社',
    ocName: 'NEO ONE ART株式会社（NEO ONE ART INC.）',
    director: '運営責任者',
    dirName: 'モンテロ  バーチャー　サイモン イグナシオ',
    address: '所在地',
    addressText: '〒107-0062 東京都港区南青山２丁目２−15',
    telephone: '電話番号',
    noaPhone: '050-5806-5990',
    emailAddress: 'メールアドレス',
    noaEmail: 'contact@neoone.art',
    businessHours: '営業時間',
    bizHoursText: '平日 10:00〜18:30 定休日 土・日・祝日',
    howToOrder: '注文方法',
    internet: 'インターネット',
    aboutSalePrice: '販売価格について',
    aboutSalePriceText: '取引画面上に表示された金額。法人が売主の場合は、全て税込の価格表示',
    necessaryExpenses: '対価以外に必要な費用 売買手数料',
    necessaryExpensesText: '売買代金の7,5%(税込）',
    notPaymentMethod: '支払方法',
    notPaymentMethodText: '銀行振込、クレジットカード',
    paymentTime: '支払時期',
    paymentTimeText: '規約が受け入れられ、売買成立時 。',
    productAvailability: '商品の提供時期',
    productAvailabilityText: 'お支払後速やかに行われます。',
    returnCancellation: '返品・キャンセルに関する事項',
    returnCancellationText: '成立した取引の返品及びキャンセルには応じません。'
}

module.exports = notation
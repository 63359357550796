const customerService = {
  support: 'Support',
  customerService: "Customer Service",
  ticketList: "List of tickets",
  sureDeleteTicket: "Are you sure you want to delete this ticket?",
  enquiryType: "Enquiry type",
  selectTicketType: "Select a ticket type",
  issue: "Issue",
  description: "Description",
  pending: "Pending",
  resolved: "Resolved",
  noTicketsPending: "No tickets pending",
  noPreviousTickets: "No previous tickets",
  generalEnquiry: 'General',
  accountAndBilling: 'Account and Billing',
};

module.exports = customerService;

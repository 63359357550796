const privacypolicy = {
  ppMainHeader: "Política de Privacidad",
  ppIntro1:
    'NEO ONE ART Co., Ltd. (en lo sucesivo, "nuestra empresa") brinda servicios exclusivos y diversos a través del sitio web operado por nuestra empresa (en lo sucesivo, "nuestro sitio web") y otros canales operados por nuestra empresa.',
  ppIntro2:
    "Siempre ponemos al usuario en el centro y reconocemos la importancia de la seguridad del usuario. Por lo tanto, como comunidad de usuarios que cumple con la “Ley de Protección de Datos Personales” (Ley N° 57 de 2003, incluidas las revisiones posteriores) y puede utilizarlo con confianza en nuestro sitio web, la siguiente política de privacidad (en adelante “la presente política”)” .) Está estipulado.",
  ppIntro3:
    "El enlace oficial para acceder a nuestro sitio web es el siguiente.",
  ppIntro4: "https: //neoone.art y https://neooneart.jp",
  ppHeader1: "1. Nuestra dirección, nombre del representante",
  ppP11: "2-2-15 Minami Aoyama, Minato-ku, Tokio",
  ppP12: "NEO ONE ART Co., Ltd.",
  ppP13: "CEO Monte Roberta Simón Ignacio",
  ppHeader2: "2. Adquisición adecuada de información personal",
  ppP21:
    "Adquiriremos correctamente la información personal sin ningún tipo de engaño o cualquier otro medio inapropiado.",
  ppHeader3: "3. Propósito de uso",
  ppP31:
    "La información de usuario adquirida se utilizará para los siguientes propósitos. Además, cuando utilice la información del usuario para fines distintos a los siguientes fines, obtenga previamente el consentimiento del usuario.",
  ppP32:
    "Para proporcionar, proteger, mantener y operar diversos servicios proporcionados por nosotros a través de nuestro sitio web",
  ppP33:
    "Para la aceptación de registros relacionados con el servicio, verificación de identidad, autenticación de usuarios, pago de tarifas de uso, realización de copias de seguridad, etc.",
  ppP34: "Para la venta y envío de productos que maneja nuestra empresa",
  ppP35:
    "Para consultar el estado de las remesas y pagos que realizan los usuarios a través de las entidades financieras",
  ppP36:
    "Para mantenimiento y soporte, incluidas sugerencias de usuarios y respuestas a preguntas frecuentes",
  ppP37: "Para todas las acciones que nos permitan mejorar nuestros servicios",
  ppP38:
    "Para cambios en los términos de uso, explicación de riesgos y precauciones, notificación de acciones que pueden conducir a violaciones de reglas, etc.",
  ppP39:
    "Para la comodidad de nuestro sitio web, como la investigación, el análisis de acceso y uso real, las actividades de marketing, la efectividad de la publicidad, etc., para el análisis de tendencias y el historial de compras de los usuarios con respecto a nuestros servicios.",
  ppP310:
    "Para responder a divulgaciones y otras solicitudes de agencias gubernamentales, tribunales y otras agencias gubernamentales",
  ppP311: "Para tasación de obras gestionadas por nuestra empresa",
  ppP312:
    "Para verificar la información de la cuenta de la institución financiera, el estado de la transferencia, etc. para una gestión adecuada, como el crédito del usuario en el momento de transacciones nuevas/continuas",
  ppP313: "Para realizar otras tareas encomendadas por los usuarios",
  ppP314: "Para actividades de reclutamiento.",
  ppP315: "Para responder a las auditorías",
  ppHeader4: "4. Contenido multimedia",
  ppP41:
    "Al cargar una imagen en un sitio web utilizando un perfil de usuario, etc., es necesario evitar cargar una imagen con datos de posición incrustados (GPS EXIF). Los usuarios de nuestro sitio web pueden descargar y extraer datos de ubicación de las imágenes del sitio web.",
  ppHeader5: "5. Medidas de gestión de la seguridad",
  ppP51:
    'Tomaremos las medidas necesarias y apropiadas para evitar la fuga, pérdida y daño de la información personal que manejamos, y tomaremos todas las medidas de seguridad posibles para administrar de manera óptima la información personal. Para obtener más información sobre las medidas de gestión de seguridad que tomamos, comuníquese con nosotros utilizando la información de contacto proporcionada en "Consultas".',
  ppHeader6: "6. Supervisión subcontratada",
  ppP61:
    "Al confiar todo o parte del manejo de la información personal a un tercero, seleccionaremos estrictamente al tercero, celebraremos un contrato de encomienda y supervisaremos a la parte encomendada para que la información personal encomendada se maneje de manera segura. Garantizaremos la necesaria y adecuada supervisión de terceros.",
  ppHeader7: "7. Uso compartido",
  ppP71:
    "Bajo ciertas circunstancias, podemos compartir la información personal del usuario de la siguiente manera para mejorar el servicio.",
  ppP72: "(1) Elementos de información personal que se compartirán",
  ppP73: "Nombre, dirección de correo electrónico",
  ppP74: "(2) Finalidad del uso de la información personal compartida",
  ppP75:
    "Para compartir el propósito de uso de los usuarios en los servicios operados por nuestra empresa",
  ppP76:
    "Para compartir el público objetivo de los usuarios en los servicios que operamos",
  ppP77:
    "Para compartir las preferencias de los usuarios con respecto a los servicios que operamos",
  ppP78: "(3) Alcance del uso compartido",
  ppP79:
    "Artista de la obra de arte comprada por el usuario, artista que maneja la galería y otro manejador de obras de arte",
  ppP710: "(4) Administrador de uso compartido",
  ppP711: "2-2-15 Minami Aoyama, Minato-ku, Tokio",
  ppP712: "NEO ONE ART Co., Ltd.",
  ppP713: "CEO Monte Roberta Simón Ignacio",
  ppHeader8: "8. Divulgación/corrección de información personal",
  ppP81:
    "Si un usuario solicita la divulgación de información personal con base en las disposiciones de la Ley de Protección de Datos Personales, la divulgaremos dentro de un período razonable después de confirmar la identidad. Si no podemos verificar la identidad del usuario, no responderemos a la divulgación.",
  ppP82:
    "Si hay un error en el contenido de la información personal y hay una solicitud de corrección, adición o eliminación de la persona, investigaremos de inmediato y responderemos dentro del rango en el que se pueda confirmar la persona. No responderemos a solicitudes en otros casos.",
  ppP83:
    "Como tarifa por divulgación, corrección, etc. de información personal, podemos cobrarle al usuario 1000 yenes por caso como tarifa.",
  ppHeader9: "9. Cambios a esta política",
  ppP91:
    "El contenido de esta política está sujeto a cambios o modificaciones parciales o totales. La Política revisada entrará en vigencia a partir del momento en que se publique en el Servicio.",
  ppHeader10: "10. Si no está de acuerdo con esta política",
  ppP101:
    'Si el usuario no está de acuerdo parcial o totalmente con esta política y, por lo tanto, no puede proporcionar toda la información necesaria para utilizar nuestro sitio web, no podremos brindar un excelente nivel de servicio. Por lo tanto, es posible que no podamos hacer negocios con este usuario, en contra de nuestra filosofía de "siempre poner al usuario en el centro".',
  ppHeader11: "11. Uso de COOKIES",
  ppP111:
    "Utilizamos cookies para obtener información específica relacionada con la navegación, las páginas visitadas y el número de visitas a nuestro sitio web. Esta información recopilada se utiliza para mejorar la experiencia del usuario y la comunidad.",
  ppP112:
    "La información personal no se puede identificar a partir de la información de las cookies. Los usuarios también pueden deshabilitar las cookies en cualquier momento configurando su navegador. En ese caso, algunos de los servicios pueden no estar disponibles.",
  ppP113:
    "Si tiene una cuenta e inicia sesión en nuestro sitio web, se aplicará una cookie temporalmente para determinar si su navegador habilita las cookies, pero esta cookie no contiene ninguna información personal. Se destruirá cuando cierre el navegador.",
  ppP114:
    "Cuando inicie sesión, también se aplicarán cookies para almacenar información de inicio de sesión y opciones de visualización de pantalla.",
  ppHeader12: "12. Contenido incrustado de otros sitios web",
  ppP121:
    "Los contenidos de estos sitios pueden contener materiales incrustados como videos, imágenes, artículos y noticias. El contenido incrustado en otros sitios web se comporta como si el usuario visitara el otro sitio web.",
  ppP122:
    "Estos sitios web utilizan cookies para incorporar un seguimiento adicional de terceros y monitorear su interacción con el contenido, por lo que si se registra en ese sitio web, se recopilará su información personal.",
  ppHeader13: "13. Tratamiento de los datos de los usuarios registrados",
  ppP131:
    "Guardaremos la información personal proporcionada en el perfil de usuario de los usuarios que se registren en nuestro sitio web. Todos los usuarios pueden ver su información personal en cualquier momento. También puede editar o eliminar su información personal bajo ciertas condiciones a través de nuestro equipo de soporte. El nombre de usuario o apodo solo puede ser cambiado por el usuario por una buena razón y se analizará caso por caso.",
  ppHeader14: "14. Contáctenos",
  ppP141:
    "Si tiene alguna solicitud con respecto al manejo de información personal, divulgaciones, preguntas u opiniones sobre los temas cubiertos por esta política, comuníquese con nosotros a la siguiente dirección de correo electrónico.",
  ppP142: "contacto [at] neoone.art",
  ppP143: "NEO ONE ART Co., Ltd.",
  ppDate: "Día de Publicación: 25 de julio de 2022",
};

module.exports = privacypolicy;

const customerService = {
  support: 'サポート',
  customerService: "カスタマーサービス",
  ticketList: "場合一覧",
  sureDeleteTicket: "Are you sure you want to delete this ticket?",
  enquiryType: "お問い合わせタイプ",
  selectTicketType: "Select a ticket type",
  issue: "事件",
  description: "事件の説明",
  pending: "保留中",
  resolved: "Resolved",
  noTicketsPending: "保留中の場合はありません",
  noPreviousTickets: "保留中の場合はありません",
  generalEnquiry: '般的なお問い合わせ',
  accountAndBilling: 'アカウントと請求',

};

module.exports = customerService;

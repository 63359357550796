/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */
import Register from "@/views/Register";

export default [
  {
    path: "login",
    name: "login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login"),
    meta: {
      auth: false,
      nouser: true,
    },
  },
  {
    path: "frame/:id/:x/:y/:code",
    name: "frame",
    props: true,
    component: () => import(/* webpackChunkName: "FrameViewer" */  "@/views/FrameViewer"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
    },
  },
  {
    path: "register/:invitationId",
    name: "register",
    props: true,
    // component: () => import(/* webpackChunkName: "Register" */ "@/views/Register"),
    component: Register,
    meta: {
      auth: false,
      nouser: true,
    },
  },
  {
    path: "invite",
    name: "invite",
    component: () => import(/* webpackChunkName: "InvitationRequest" */  "@/views/InvitationRequest"),
    meta: {
      auth: false,
      nouser: true,
    },
  },

  // Home
  {
    path: "artwork",
    name: "artwork",
    component: () => import(/* webpackChunkName: "ArtWorks" */  "@/views/ArtWorks"),
  },
  {
    path: "emerging",
    name: "emerging",
    component: () => import(/* webpackChunkName: "EmergingArtists" */  "@/views/EmergingArtists"),
  },
  {
    path: "emerging/collection/:pArtist/:id",
    name: "emerging.collection",
    props: true,
    component: () => import(/* webpackChunkName: "EmergingArtistCollection" */  "@/views/EmergingArtistCollection"),
  },
  {
    path: "emerging/workdetails/:pArtist/:pTitle/:id",
    name: "emerging.workdetails",
    props: true,
    component: () => import(/* webpackChunkName: "EmergingWorkDetails" */  "@/views/EmergingWorkDetails"),
  },

  {
    path: "collector/:id",
    name: "collector",
    props: true,
    component: () => import(/* webpackChunkName: "Collector" */  "@/views/Collector"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
    },
  },
  {
    path: "events",
    name: "account.events.public",
    component: () => import(/* webpackChunkName: "AccountEventsPublic" */  "@/views/AccountEventsPublic"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: false,
      verified: false,
    },
  },
  {
    path: "noaevents",
    name: "account.events.private",
    component: () => import(/* webpackChunkName: "AccountEventsPrivate" */  "@/views/AccountEventsPrivate"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
    },
  },
  {
    path: "tickets",
    name: "account.tickets",
    component: () => import(/* webpackChunkName: "CustomerService" */  "@/views/CustomerService"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: false,
    },
  },

  {
    path: "summary/:tabId",
    name: "summary",
    props: true,
    component: () => import(/* webpackChunkName: "Summary" */  "@/views/Summary"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
    },
  },

  // Account

  {
    path: "details/:pArtist/:pTitle/:id",
    name: "work.details",
    props: true,
    component: () => import(/* webpackChunkName: "WorkDetails" */  "@/views/WorkDetails"),
  },

  {
    path: "",
    name: "none.details",
    component: () => import(/* webpackChunkName: "HomeHow" */  "@/views/HomeHow"),
  },

  {
    path: "howtoclaim",
    name: "none.details",
    component: () => import(/* webpackChunkName: "ClaimFrameSteps" */  "@/views/ClaimFrameSteps"),
  },

  {
    path: "buy/:id",
    name: "work.buy",
    props: true,
    component: () => import(/* webpackChunkName: "BuyFrames" */  "@/views/BuyFrames"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
      maintenance: true,
    },
  },

  {
    path: "checkout/:id/",
    name: "checkout",
    props: true,
    component: () => import(/* webpackChunkName: "Checkout" */  "@/views/Checkout"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
      maintenance: true,
    },
  },

  {
    path: "quadrants/:id/",
    name: "work.quadrants",
    props: true,
    component: () => import(/* webpackChunkName: "CheckoutQuadrants" */  "@/components/Molecules/CheckoutQuadrants"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      verified: true,
    },
  },
  {
    path: "image/:id",
    name: "work.coordinates",
    props: true,
    component: () => import("@/components/Molecules/ImageFrameCoordinates"),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      admin: true,
    },
  },

  /********************************
   * STATIC
   *********************************/

  {
    path: "",
    name: "home-how",
    component: () => import(/* webpackChunkName: "HomeHow" */  "@/views/HomeHow"),
  },

  {
    path: "about",
    name: "about.index",
    component: () => import(/* webpackChunkName: "About" */  "@/views/About"),
  },

  {
    path: "company",
    name: "company",
    component: () => import(/* webpackChunkName: "Company" */  "@/views/Company"),
  },
  {
    path: "invitation",
    name: "invitation",
    component: () => import(/* webpackChunkName: "InvitationRequest" */  "@/views/InvitationRequest"),
  },
  {
    path: "how",
    name: "how",
    component: () => import(/* webpackChunkName: "How" */  "@/views/How"),
  },
  {
    path: "employment",
    name: "employment",
    component: () => import(/* webpackChunkName: "Employment" */  "@/views/Employment"),
  },
  {
    path: "privacy-policy",
    name: "privacy-policy",
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */  "@/views/PrivacyPolicy"),
  },
  {
    path: "terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import(/* webpackChunkName: "TermAndCond" */  "@/views/TermAndCond"),
  },
  {
    path: "terms-and-conditions-gallery",
    name: "terms-and-conditions-gallery",
    component: () => import(/* webpackChunkName: "TermAndCondGallery" */  "@/views/TermAndCondGallery"),
  },
  {
    path: "notation",
    name: "notation",
    component: () => import(/* webpackChunkName: "Notation" */  "@/views/Notation"),
  },
  {
    path: "notation-old",
    name: "notation-old",
    component: () => import(/* webpackChunkName: "NotationOld" */  "@/views/NotationOld"),
  },
  {
    path: "faqs",
    name: "faqs",
    component: () => import(/* webpackChunkName: "Faqs" */  "@/views/Faqs"),
  },
  {
    path: "advices",
    name: "advices",
    component: () => import(/* webpackChunkName: "Advices" */  "@/views/Advices"),
  },
  {
    path: "contact",
    name: "contact",
    component: () => import(/* webpackChunkName: "Contact" */  "@/views/Contact"),
  },
  {
    path: "maintenance",
    name: "maintenance",
    component: () => import(/* webpackChunkName: "UnderMaintenance" */  "@/views/UnderMaintenance"),
  },
  {
    path: "*",
    name: "error404",
    component: () => import(/* webpackChunkName: "page-404" */  "@/views/page-404"),
  },
  
];

const checkout = {

  timeFinishPurchase: 'Time for finish your purchase:',
  abandonCartWarning:
    'If you abandon the checkout area you may lose your frames',
  abandonCartModal:
    'You are about to leave this page. You will lose your frames',
  summary: 'Summary',
  tnc: 'T&C',
  payment: 'Payment',
  confirmation: 'Done',
  coordinates: 'Coordinates',
  notice: 'Notice',
  paymentSuccess: 'Your payment has been successfully completed',
  backToArtwork: 'Back to Work of Art',
  checkTransactions: 'Check Transaction',
  loseFrames: 'Lose the frames',
  keepBuying: 'Keep buying',
  availableSoon: 'Available soon',
  price: 'Price',
  rowsPerPage: 'Frames per page',
  back: 'Back',
  next: 'Next',
  agreeTC: 'I agree to the',
  termsandConditions: 'terms and conditions',
  pay: 'Pay',
  frame: 'Frame',
  frameLimitMsg:
    'For large purchases, please contact us. Click OK to take you to our contact form or cancel to buy',
  frameLimitButton: 'Buy 250 frames',
  frameOwnershipLimitMsg:
    "more frames. In the first round you can't own more than 35% of the work",
  youCanOnlyBuy: 'You can only buy',
  processingPayment: 'Processing payment...',
  generatingFrames: 'Generating frames...',
  selectQuadrant: 'Select a Quadrant',
  previous: 'Previous',
  tncNotice:
    'By accessing or using the Service in any way, you agree to these Terms. If you do not agree to these Terms, you are not permitted to access or use the Services. Please read these Terms carefully.',
  all: 'All',
  noDataAvailable: 'No data available',
  noFramesAvailable: 'No frames available',
  notEnoughFrames: 'Not enough frames found, showing only what is available',
  thereAreOnly: 'There are only',
  available: 'available',
  cantBuyMore: 'You cannot buy any more frames. In the first round you cannot own more than 35% of the work'
};

module.exports = checkout;

const company = {
  cTitle: '運営会社',
  cMainHeader: 'NEO ONE ARTのサービス',
  cIntro:
    '　NEO ONE ARTは、アート作品やコレクションを一般に公開するプラットフォームを提供しています。このプラットフォームでは、アート作品やコレクションの「フレーム」または物理的なセクションを所有（購入）し、取引が可能です。ユーザーは一度フレームを所有すると、歴史的および文化的価値の高い商品の共同所有者になります。私たちの使命は、排他的なアートマーケットをコレクターとアート愛好家の両方のための包含的なコミュニティに変えることによって、アートを民主化することです。',
  cHeader1: '作品とコレクション',
  cParagraph1:
    '　世界的に有名な芸術家の作品や、歴史的、文化的に関連性のあるコレクションを所蔵しています。フランシスコ・デ・ゴヤからパブロ・ピカソ、ジョアン・ミロまで、コレクター、アート愛好家、投資家にとって魅力的な作品に焦点を当てています。',
  cHeader2: '「フレーム」システム',
  cParagraph2:
    '　「フレーム」システムとは、共同所有権の考え方を反映しています。私たちのアルゴリズムは、2Dおよび3Dオブジェクトを断片化し、各「フレーム」に一意の識別子を与えます。この「フレーム」はオブジェクトの特定の象限、収集可能な部分を表しており、所有者であるあなたが取引または譲渡することができます！',
  cHeader3: 'デモクラタイゼーションとコミュニティ',
  cParagraph3:
    '　芸術作品やコレクションを小さくユニークな断片に分割することにより、消費者にとって市場価値の高い商品がアクセス可能になり、芸術市場を民主化します。その結果、作品全体を購入する手間をかけずに優れたアートワークを共有し、所有経験を他の人と共有することができます。また、NEO ONE ART主催のイベントにオーナー様として参加し、このシステムを中心としたコミュニティづくりを実現します。',
  cHeader4: 'ABOUT NOA',
  cParagraph41:
    '　NEO ONE ARTは、歴史的および文化的価値の高いオブジェクトの個人コレクションの保存、管理、展示を担当する世界初の企業であり、世界中の共通の関心を持つ何千ものコレクターの所有権を民主化しています。',
  cParagraph42:
    '　個人のコレクター、カルチャーマネージャー、そしてアートマーケットの投資家でさえあなたの指先にあり、1,000円から共同所有権を手に取引を始められます。',
  cHeader5: 'Leadership',
  cParagraph51:
    '　私たちのチームは、アートや歴史的オブジェクトの収集の分野で60年以上の経験を共有しており、さらに、ロンドン、マドリッド、ニューヨークなどの世界の主要首都でさまざまな種類のプロジェクトを主導してきました。',
  cParagraph52: '　現在、東京都港区に拠点を置いています。',
  cTeam: 'Team',
  cTeamName1:
    'Simon Montero Bachur、CEO兼代表取締役。MBA、早稲田大学で日本語と文化を学ぶ。',
  cTeamName2:
    'Jose María Salas、COO兼ディレクター。ローマ大学で人工知能とロボット工学の修士号を取得。',
  cAdvisors: 'Advisors',
  cAdvisor1:
    'Jorge Federico Gras、アドバイザー、第5世代のコレクター、研究と美術収集に45年以上専念。',
  cAdvisor2:
    'Ana María Gallardo Montero、元国連職員、Gras Dinten Foundationの慈善活動のディレクター、国際関係の専門家アドバイザー。',
  cAdvisor3:
    'Sebastián Baeza González、人文地理学の博士号を取得、NEO ONEARTの顧問兼主任研究員。',
    team: {
      team1: {
        name: 'Simón Montero Bachur',
        position: 'CEO兼代表取締役',
        imageUrl: "Simon-Montero-Bachur",
        desc: ' MBA、早稲田大学で日本語と文化を学ぶ。',
        social: {
          linkedin: 'https://www.linkedin.com/in/simón-i-m-11455325/'
        },
        founder: true
      },
      team3: {
        name: 'Jorge Federico Gras',
        position: 'アドバイザー',
        imageUrl: "Jorge-Federico-Gras",
        desc: '第5世代のコレクター、研究と美術収集に45年以上専念。',
      },
      team4: {
        name: 'Ana María Gallardo Montero',
        position: 'アドバイザー',
        imageUrl: "Ana-Maria-Gallardo-Montero",
        desc: '元国連職員、Gras Dinten Foundationの慈善活動のディレクター、国際関係の専門家アドバイザー。',
        female: true
      },
      team5: {
        name: 'Sebastián Baeza González',
        position: 'アドバイザー',
        imageUrl: 'Sebastian-Baeza-Gonzalez',
        desc: '人文地理学の博士号を取得、NEO ONEARTの顧問兼主任研究員。',
      }
    }
 };

module.exports = company;

const faq = {
  title1: "¿En qué consiste el servicio de NEO ONE ART?",
  body1:
    "NEO ONE ART es una plataforma que tiene como finalidad democratizar el coleccionismo de arte. Cualquier persona que se registra en nuestro sitio web podrá comprar un “frame” y disfrutar de todos los beneficios que tiene los miembros de nuestra comunidad, siendo además:<ol><li>Promotor del arte a nivel mundial: Todos los miembros de nuestra comunidad son considerados dentro de este estatus, ya que nos ayudan a empujar este proyecto, cuya finalidad es mantener una importante colección de arte y objetos históricos democratizando su posesión en miles de usuarios.</li><li>Coleccionista de arte: Todos los miembros de la comunidad podrán ser coleccionistas, principalmente, en dos dimisiones: En primer lugar, de manera global, siendo parte de la gran colección que NEO ONE ART administra y, en segundo lugar, formado su colección personal, con la cual podrán generar intercambios con otros usuarios por medio de nuestro sistema único de frames personalizados “SCF” (Single Coordinate Frame) donde cada frame es una pieza de colección única e irrepetible.</li><li>Inversionista en el mercado del arte: La obtención de un frame en NEO ONE ART, entrega una serie de beneficios a su propietario y, además, representa un derecho de copropiedad de la obra de arte a la que este frame pertenece. Por otra parte, con el sistema de SCF, existirán frames que, dentro de nuestro mercado interno, serán más apetecidos que otros, principalmente, por quienes tengan como objetivo el coleccionismo. Esto, al mediano plazo, se transformará en un método de inversión de doble factor, donde el precio del frame estará marcado por la revalorización que tenga la obra de arte en la colección administrada por NEO ONE ART y por el valor que le atribuyen los coleccionista a los diferentes frames dependiendo su rareza. </li></ol>",
  title2: "¿Qué costo tiene la membresía en NEO ONE ART?",
  body2:
    "Registrarse como miembro/usuario de NOA no tiene ningún costo y esperamos que se mantenga así para siempre.",
  
  title3: "¿Cuál es el precio de un frame en NEO ONE ART?",
  body3:
    "En NOA tenemos un compromiso con la democratización del arte, es por ello que creemos firmemente en que el precio de un frame debe ser asequible para todas las personas. Nuestro lema es “un frame, una moneda”, es decir, el precio de salida de un frame en nuestro sitio web nunca será superior a 1.000¥. Con lo anterior, los frames podrían subir de precio en el tiempo debido a la rareza de los mismos, las transacciones realizadas entre usuarios o la revalorización de la obra en el tiempo.",
  title4: "¿Existe un límite en la cantidad de frames que puedo comprar?",
  body4:
    "Como usuario registrado, puedes comprar la cantidad de frames que quieras dentro del total publicado por NOA. Cuando un usuario se hace con más del 75% del total de los frames de un objeto coleccionable (pinturas, libros, esculturas, etc) podría solicitar la posesión total del objeto.",
  title5: "¿Puedo regalar mis frames a quien quiera?",
  body5:
    "Por supuesto, sus frames le pertenecen y por tanto los puede obsequiar si así lo desea. El único requisito para obsequiar los frames, es que la persona que recibirá este obsequio sea mayor de edad y cumpla con todos los requisitos para ser un usuario registrado en nuestro sitio web. Posteriormente, con ambos usuarios registrados, quien desea obsequiar los frames, se debe poner en contacto con nuestro servicio de soporte, quienes ejecutarán la transacción. Dicha transacción tendrá un costo con cargo a quien realiza el obsequio. El costo de la transacción será un 7.5% sobre el precio de compra de cada frame. Ver ejemplo a continuación: <ul><li>Usuario “A” compra 20 frames a un precio de 1.000¥ cada uno. Total 20,000 JPY.</li><li>El usuario “A” desea obsequiar al usuario “B” 10 frames y se pone en contacto con el equipo de soporte.</li><li>El equipo de soporte acepta la transacción y el usuario “A” deberá pagar un cargo del 7.5% sobre el precio de los 10 frames obsequiados, es decir, 750¥. </li></ul>",
  title6:
    "¿Existen cargos adicionales al precio de compra, como cuotas de membresía, mantención o comisiones por ventas?",
  body6:
    "En principio, el precio de venta de un frame siempre será de 1.000¥, este monto incluye el impuesto al consumo de un 10%. Cuando los usuarios realizan transacciones entre ellos, NOA cobrará un 7,5% del valor total de la transacción, además, el usuario deberá pagar un 10% de impuesto al consumo solo sobre dicho recargo. No existen cuotas de membresía ni cargos por mantención de su cuenta.",
  title7: "¿Cuántos pasos se requieren para comprar un frame en NEO ONE ART?",
  body7:
    "Para comprar un frame en NOA, se debe tener una cuenta de usuario registrada y validada por nuestro equipo de soporte. Una vez tenga su cuenta creada, se requieren los siguientes pasos para comprar un frame:<ol><li>Iniciar sesión en NOA</li><li>Elegir una obra entre los trabajos disponibles.</li><li>Seleccionar comprar y marcar la cantidad de frames que desea.</li><li>Aceptar los términos y condiciones.</li><li>Ingresar su medio de pago (si es que no lo ha hecho antes).</li><li>Confirmar sus datos y aceptar.</li></ol>Los frames comprados aparecerán inmediatamente en su cuenta una vez se haga efectivo el pago. En su perfil de usuario, podrá revisar qué coordenadas de la obra le ha enviado de manera totalmente aleatoria nuestro algoritmo de coordenadas. Además, recibirá un certificado de copropiedad el cual confirma su calidad de coleccionista.",
 };
  
 module.exports = faq; 
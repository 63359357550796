export const config = {
  apiKey: 'AIzaSyAWiurMP6pjW5FsyiUmt_lW11ZZFsuQh0Q',
  authDomain: 'neoone.art',
  databaseURL:
    'https://neoone-art-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'neoone-art',
  storageBucket: 'images.neoone.art',
  messagingSenderId: '697174453188',
  appId: '1:697174453188:web:f151ea0d212a6042f01291',
  measurementId: 'G-T6TZXF7LDG',
};

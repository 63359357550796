const summary = {
  invitations: '招待',
  invitationsLeft: '人 招待可能',
  invitationMessage: 'メールで招待をお送りください',
  invitationSent: 'お客様の正体は正常に送られました',
  invitationError: '招待をお送りする上でエラーが生じました',
  myAccount: 'アカウント',
  verify: '確認',
  usernameTaken: "ユーザー名は利用できません。",
  identityVerificationNotice: '<p>NEO ONE ARTの会員登録が完了いたしました!!</p><p>これで、フレームの購入を開始できます。</p><p>次の手順は必須ではありませんが、「フレームマーケット」にアクセスするにはアカウントを確認する必要があります　[まもなく利用可能]。</p><p>「購入」をクリックして、利用可能な芸術作品を確認し、「確認」をクリックして、身元を確認することもできます。</p>',
  certificate: 'Certificate',
  verified: 'Verified',
  notVerified: 'Not Verified',
  notVerifiedNotice: 'You must finish the identity verification process to access all our services.',
  verificationPendingNotice: 'Your identity is being verified. You can now start buying, but to have access to our full services the verification must finish.',
  identityVerification: 'Identity Verification',
  identity: 'Identity',
  personal: 'Personal',
  address: 'Address',
  verification: '確認',
  subscribeMailingList: '新しい作品のリリース、NOAイベント、ニュースを知らせてください。',
  eventsNotAvailable: '現在公開されているイベントはありません',
  profile: 'プロフィール',
  collection: 'コレクション',
  transactions: 'トランザクション',
  editInfo: '編集',
  myInfo: '個人情報',
  firstName: '姓',
  lastName: '名',
  nameKana: '姓（ふりがな）',
  surnameKana: '名（ふりがな）',
  phoneNumber: '電話番号',
  birthdate: '生年月日',
  addressLine1: '住所',
  addressLine2: 'マンション・建物名、部屋番号',
  city: '市',
  region: '都道府県',
  postcode: '郵便番号',
  country: '国',
  sameAddressBilling: '請求先住所と同じ',
  proofOfIdentity: '身分証明書',
  profilePhoto: 'プロフィール画像',
  myPM: '支払い方法',
  addPM: '支払い方法を追加',
  addCard: 'カードを追加',
  noPM: '支払い方法が未登録です',
  choosePM: '支払い方法を選択する',
  myShippingAddress: 'お届け先',
  noAddresses: 'お届け先が未登録です',
  addNew: '新しく追加',
  cancel: 'キャンセル',
  submit: '登録',
  myCollection: 'コレクション',
  marketPrice: '市場価格',
  totalWork: '作品合計数',
  change: '変更する',
  ownership: 'オーナーシップ',
  balance: 'バランス',
  evolutionPrice: 'プライストレンド',
  download: 'ダウンロード',
  seeFrames: 'フレームを見る',
  sell: '売る',
  paid: '支払い済',
  noSellNotice1: '作品の全枠が購入された後、',
  noSellNotice2: '所有枠の売買が開始できます。',
  notFound404: '要求されたURLはこのサーバーで見つかりませんでした。',
  anError: '404。エラーが発生しました。',
  ownedFrames: '所有しているフレーム',
  ownedPct: '所有率',
  workFrames: '作品のフレーム',
};

module.exports = summary;

const footer = {
  aboutNOA: 'Acerca de NOA',
  operatingCompany: 'Compañía operadora',
  howWeWork: 'Cómo operamos',
  employment: 'Trabaja con NOA',
  ourService: 'Nuestro servicio',
  privacyPolicy: 'Política de privacidad',
  termsAndConditions: 'Términos y condiciones',
  termsAndConditionsGallery: 'Términos y condiciones - Galería',
  transactionLaw: 'Ley de de transacciones comerciales',
  antiqueLaw: 'Ley de negocio de anticuarios',
  support: 'Apoyo ',
  faq: 'Preguntas frecuentes',
  footerText1: '© 2022 NEO ONE ART Inc. Todos los derechos reservados.',
  footerText2: 'NEO ONE ART® es una marca comercial registrada con número de registro 6639715',
  footerText3: 'El sistema de procesamiento de venta de frames tiene “patente pendiente”, número de solicitud 2022-83057.',

};
  
 module.exports = footer;